import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AdminLoginRespponsce = {
  __typename?: 'AdminLoginRespponsce';
  accessToken?: Maybe<Scalars['String']['output']>;
  isAuthenticated: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<UserForResponsce>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AggregateCategory = {
  __typename?: 'AggregateCategory';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
};

export type AggregateCategoryPageAd = {
  __typename?: 'AggregateCategoryPageAd';
  _count?: Maybe<CategoryPageAdCountAggregate>;
  _max?: Maybe<CategoryPageAdMaxAggregate>;
  _min?: Maybe<CategoryPageAdMinAggregate>;
};

export type AggregateComment = {
  __typename?: 'AggregateComment';
  _count?: Maybe<CommentCountAggregate>;
  _max?: Maybe<CommentMaxAggregate>;
  _min?: Maybe<CommentMinAggregate>;
};

export type AggregateCommentReaction = {
  __typename?: 'AggregateCommentReaction';
  _count?: Maybe<CommentReactionCountAggregate>;
  _max?: Maybe<CommentReactionMaxAggregate>;
  _min?: Maybe<CommentReactionMinAggregate>;
};

export type AggregateHomePageAd = {
  __typename?: 'AggregateHomePageAd';
  _count?: Maybe<HomePageAdCountAggregate>;
  _max?: Maybe<HomePageAdMaxAggregate>;
  _min?: Maybe<HomePageAdMinAggregate>;
};

export type AggregateLikesByUser = {
  __typename?: 'AggregateLikesByUser';
  _count?: Maybe<LikesByUserCountAggregate>;
  _max?: Maybe<LikesByUserMaxAggregate>;
  _min?: Maybe<LikesByUserMinAggregate>;
};

export type AggregateMedia = {
  __typename?: 'AggregateMedia';
  _count?: Maybe<MediaCountAggregate>;
  _max?: Maybe<MediaMaxAggregate>;
  _min?: Maybe<MediaMinAggregate>;
};

export type AggregateMediaCategory = {
  __typename?: 'AggregateMediaCategory';
  _count?: Maybe<MediaCategoryCountAggregate>;
  _max?: Maybe<MediaCategoryMaxAggregate>;
  _min?: Maybe<MediaCategoryMinAggregate>;
};

export type AggregateNotification = {
  __typename?: 'AggregateNotification';
  _count?: Maybe<NotificationCountAggregate>;
  _max?: Maybe<NotificationMaxAggregate>;
  _min?: Maybe<NotificationMinAggregate>;
};

export type AggregatePost = {
  __typename?: 'AggregatePost';
  _count?: Maybe<PostCountAggregate>;
  _max?: Maybe<PostMaxAggregate>;
  _min?: Maybe<PostMinAggregate>;
};

export type AggregatePostPageAd = {
  __typename?: 'AggregatePostPageAd';
  _count?: Maybe<PostPageAdCountAggregate>;
  _max?: Maybe<PostPageAdMaxAggregate>;
  _min?: Maybe<PostPageAdMinAggregate>;
};

export type AggregateRss = {
  __typename?: 'AggregateRss';
  _count?: Maybe<RssCountAggregate>;
  _max?: Maybe<RssMaxAggregate>;
  _min?: Maybe<RssMinAggregate>;
};

export type AggregateSiteinfo = {
  __typename?: 'AggregateSiteinfo';
  _count?: Maybe<SiteinfoCountAggregate>;
  _max?: Maybe<SiteinfoMaxAggregate>;
  _min?: Maybe<SiteinfoMinAggregate>;
};

export type AggregateSubCategory = {
  __typename?: 'AggregateSubCategory';
  _count?: Maybe<SubCategoryCountAggregate>;
  _max?: Maybe<SubCategoryMaxAggregate>;
  _min?: Maybe<SubCategoryMinAggregate>;
};

export type AggregateSubCategoryPageAd = {
  __typename?: 'AggregateSubCategoryPageAd';
  _count?: Maybe<SubCategoryPageAdCountAggregate>;
  _max?: Maybe<SubCategoryPageAdMaxAggregate>;
  _min?: Maybe<SubCategoryPageAdMinAggregate>;
};

export type AggregateSubscriber = {
  __typename?: 'AggregateSubscriber';
  _count?: Maybe<SubscriberCountAggregate>;
  _max?: Maybe<SubscriberMaxAggregate>;
  _min?: Maybe<SubscriberMinAggregate>;
};

export type AggregateTag = {
  __typename?: 'AggregateTag';
  _count?: Maybe<TagCountAggregate>;
  _max?: Maybe<TagMaxAggregate>;
  _min?: Maybe<TagMinAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type AggregateUserPermissions = {
  __typename?: 'AggregateUserPermissions';
  _count?: Maybe<UserPermissionsCountAggregate>;
  _max?: Maybe<UserPermissionsMaxAggregate>;
  _min?: Maybe<UserPermissionsMinAggregate>;
};

export type AggregateUserSocilaLinks = {
  __typename?: 'AggregateUserSocilaLinks';
  _count?: Maybe<UserSocilaLinksCountAggregate>;
  _max?: Maybe<UserSocilaLinksMaxAggregate>;
  _min?: Maybe<UserSocilaLinksMinAggregate>;
};

export type AggregateUserTokens = {
  __typename?: 'AggregateUserTokens';
  _count?: Maybe<UserTokensCountAggregate>;
  _max?: Maybe<UserTokensMaxAggregate>;
  _min?: Maybe<UserTokensMinAggregate>;
};

export type AggregateWebNotification = {
  __typename?: 'AggregateWebNotification';
  _count?: Maybe<WebNotificationCountAggregate>;
  _max?: Maybe<WebNotificationMaxAggregate>;
  _min?: Maybe<WebNotificationMinAggregate>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type Category = {
  __typename?: 'Category';
  _count?: Maybe<CategoryCount>;
  addToMenu: Scalars['Boolean']['output'];
  addTop: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  categoryPageKeyWords: Scalars['String']['output'];
  categoryPageTitle: Scalars['String']['output'];
  categoryText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customAd?: Maybe<CategoryPageAd>;
  id: Scalars['String']['output'];
  image?: Maybe<Media>;
  imageId?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  post: Array<Post>;
  postSectionOnHomepage: Scalars['Boolean']['output'];
  postSectionPosition: Scalars['Int']['output'];
  postSectionType: CategorySectionType;
  rsses: Array<Rss>;
  slug: Scalars['String']['output'];
  subcategory: Array<SubCategory>;
  subscribers: Array<Subscriber>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CategoryPostArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type CategoryRssesArgs = {
  cursor?: InputMaybe<RssWhereUniqueInput>;
  distinct?: InputMaybe<Array<RssScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RssOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RssWhereInput>;
};


export type CategorySubcategoryArgs = {
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type CategorySubscribersArgs = {
  cursor?: InputMaybe<SubscriberWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriberScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriberOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriberWhereInput>;
};

export type CategoryAvgAggregate = {
  __typename?: 'CategoryAvgAggregate';
  position?: Maybe<Scalars['Float']['output']>;
  postSectionPosition?: Maybe<Scalars['Float']['output']>;
};

export type CategoryAvgOrderByAggregateInput = {
  position?: InputMaybe<SortOrder>;
  postSectionPosition?: InputMaybe<SortOrder>;
};

export type CategoryCount = {
  __typename?: 'CategoryCount';
  post: Scalars['Int']['output'];
  rsses: Scalars['Int']['output'];
  subcategory: Scalars['Int']['output'];
  subscribers: Scalars['Int']['output'];
};


export type CategoryCountPostArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type CategoryCountRssesArgs = {
  where?: InputMaybe<RssWhereInput>;
};


export type CategoryCountSubcategoryArgs = {
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type CategoryCountSubscribersArgs = {
  where?: InputMaybe<SubscriberWhereInput>;
};

export type CategoryCountAggregate = {
  __typename?: 'CategoryCountAggregate';
  _all: Scalars['Int']['output'];
  addToMenu: Scalars['Int']['output'];
  addTop: Scalars['Int']['output'];
  category: Scalars['Int']['output'];
  categoryPageKeyWords: Scalars['Int']['output'];
  categoryPageTitle: Scalars['Int']['output'];
  categoryText: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageId: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  postSectionOnHomepage: Scalars['Int']['output'];
  postSectionPosition: Scalars['Int']['output'];
  postSectionType: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CategoryCountOrderByAggregateInput = {
  addToMenu?: InputMaybe<SortOrder>;
  addTop?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  categoryPageKeyWords?: InputMaybe<SortOrder>;
  categoryPageTitle?: InputMaybe<SortOrder>;
  categoryText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  postSectionOnHomepage?: InputMaybe<SortOrder>;
  postSectionPosition?: InputMaybe<SortOrder>;
  postSectionType?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryCreateInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<CategoryPageAdCreateNestedOneWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostCategoryInput>;
  position: Scalars['Int']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutCategoryInput>;
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutCategoryInput>;
  slug: Scalars['String']['input'];
  subcategory?: InputMaybe<SubCategoryCreateNestedManyWithoutCategoryInput>;
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutCategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateManyImageInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateManyImageInputEnvelope = {
  data: Array<CategoryCreateManyImageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryCreateManyInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateNestedManyWithoutImageInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutImageInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutImageInput>>;
  createMany?: InputMaybe<CategoryCreateManyImageInputEnvelope>;
};

export type CategoryCreateNestedManyWithoutSubscribersInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutSubscribersInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutSubscribersInput>>;
};

export type CategoryCreateNestedOneWithoutCustomAdInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCustomAdInput>;
  create?: InputMaybe<CategoryCreateWithoutCustomAdInput>;
};

export type CategoryCreateNestedOneWithoutPostInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<CategoryCreateWithoutPostInput>;
};

export type CategoryCreateNestedOneWithoutRssesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutRssesInput>;
  create?: InputMaybe<CategoryCreateWithoutRssesInput>;
};

export type CategoryCreateNestedOneWithoutSubcategoryInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubcategoryInput>;
  create?: InputMaybe<CategoryCreateWithoutSubcategoryInput>;
};

export type CategoryCreateOrConnectWithoutCustomAdInput = {
  create: CategoryCreateWithoutCustomAdInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutImageInput = {
  create: CategoryCreateWithoutImageInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutPostInput = {
  create: CategoryCreateWithoutPostInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutRssesInput = {
  create: CategoryCreateWithoutRssesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutSubcategoryInput = {
  create: CategoryCreateWithoutSubcategoryInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutSubscribersInput = {
  create: CategoryCreateWithoutSubscribersInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateWithoutCustomAdInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostCategoryInput>;
  position: Scalars['Int']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutCategoryInput>;
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutCategoryInput>;
  slug: Scalars['String']['input'];
  subcategory?: InputMaybe<SubCategoryCreateNestedManyWithoutCategoryInput>;
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutCategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutImageInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<CategoryPageAdCreateNestedOneWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutCategoryInput>;
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutCategoryInput>;
  slug: Scalars['String']['input'];
  subcategory?: InputMaybe<SubCategoryCreateNestedManyWithoutCategoryInput>;
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutCategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutPostInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<CategoryPageAdCreateNestedOneWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostCategoryInput>;
  position: Scalars['Int']['input'];
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutCategoryInput>;
  slug: Scalars['String']['input'];
  subcategory?: InputMaybe<SubCategoryCreateNestedManyWithoutCategoryInput>;
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutCategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutRssesInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<CategoryPageAdCreateNestedOneWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostCategoryInput>;
  position: Scalars['Int']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutCategoryInput>;
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  slug: Scalars['String']['input'];
  subcategory?: InputMaybe<SubCategoryCreateNestedManyWithoutCategoryInput>;
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutCategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutSubcategoryInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<CategoryPageAdCreateNestedOneWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostCategoryInput>;
  position: Scalars['Int']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutCategoryInput>;
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutCategoryInput>;
  slug: Scalars['String']['input'];
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutCategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutSubscribersInput = {
  addToMenu: Scalars['Boolean']['input'];
  addTop: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  categoryPageKeyWords: Scalars['String']['input'];
  categoryPageTitle: Scalars['String']['input'];
  categoryText: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<CategoryPageAdCreateNestedOneWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostCategoryInput>;
  position: Scalars['Int']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutCategoryInput>;
  postSectionOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  postSectionPosition?: InputMaybe<Scalars['Int']['input']>;
  postSectionType?: InputMaybe<CategorySectionType>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutCategoryInput>;
  slug: Scalars['String']['input'];
  subcategory?: InputMaybe<SubCategoryCreateNestedManyWithoutCategoryInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryGroupBy = {
  __typename?: 'CategoryGroupBy';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
  addToMenu: Scalars['Boolean']['output'];
  addTop: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  categoryPageKeyWords: Scalars['String']['output'];
  categoryPageTitle: Scalars['String']['output'];
  categoryText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  postSectionOnHomepage: Scalars['Boolean']['output'];
  postSectionPosition: Scalars['Int']['output'];
  postSectionType: CategorySectionType;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryListRelationFilter = {
  every?: InputMaybe<CategoryWhereInput>;
  none?: InputMaybe<CategoryWhereInput>;
  some?: InputMaybe<CategoryWhereInput>;
};

export type CategoryMaxAggregate = {
  __typename?: 'CategoryMaxAggregate';
  addToMenu?: Maybe<Scalars['Boolean']['output']>;
  addTop?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  categoryPageKeyWords?: Maybe<Scalars['String']['output']>;
  categoryPageTitle?: Maybe<Scalars['String']['output']>;
  categoryText?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  postSectionOnHomepage?: Maybe<Scalars['Boolean']['output']>;
  postSectionPosition?: Maybe<Scalars['Int']['output']>;
  postSectionType?: Maybe<CategorySectionType>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CategoryMaxOrderByAggregateInput = {
  addToMenu?: InputMaybe<SortOrder>;
  addTop?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  categoryPageKeyWords?: InputMaybe<SortOrder>;
  categoryPageTitle?: InputMaybe<SortOrder>;
  categoryText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  postSectionOnHomepage?: InputMaybe<SortOrder>;
  postSectionPosition?: InputMaybe<SortOrder>;
  postSectionType?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryMinAggregate = {
  __typename?: 'CategoryMinAggregate';
  addToMenu?: Maybe<Scalars['Boolean']['output']>;
  addTop?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  categoryPageKeyWords?: Maybe<Scalars['String']['output']>;
  categoryPageTitle?: Maybe<Scalars['String']['output']>;
  categoryText?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  postSectionOnHomepage?: Maybe<Scalars['Boolean']['output']>;
  postSectionPosition?: Maybe<Scalars['Int']['output']>;
  postSectionType?: Maybe<CategorySectionType>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CategoryMinOrderByAggregateInput = {
  addToMenu?: InputMaybe<SortOrder>;
  addTop?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  categoryPageKeyWords?: InputMaybe<SortOrder>;
  categoryPageTitle?: InputMaybe<SortOrder>;
  categoryText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  postSectionOnHomepage?: InputMaybe<SortOrder>;
  postSectionPosition?: InputMaybe<SortOrder>;
  postSectionType?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CategoryOrderByRelevanceFieldEnum {
  Category = 'category',
  CategoryPageKeyWords = 'categoryPageKeyWords',
  CategoryPageTitle = 'categoryPageTitle',
  CategoryText = 'categoryText',
  Id = 'id',
  ImageId = 'imageId',
  Slug = 'slug'
}

export type CategoryOrderByRelevanceInput = {
  fields: Array<CategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type CategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<CategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<CategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<CategorySumOrderByAggregateInput>;
  addToMenu?: InputMaybe<SortOrder>;
  addTop?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  categoryPageKeyWords?: InputMaybe<SortOrder>;
  categoryPageTitle?: InputMaybe<SortOrder>;
  categoryText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrderInput>;
  position?: InputMaybe<SortOrder>;
  postSectionOnHomepage?: InputMaybe<SortOrder>;
  postSectionPosition?: InputMaybe<SortOrder>;
  postSectionType?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CategoryOrderByRelevanceInput>;
  addToMenu?: InputMaybe<SortOrder>;
  addTop?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  categoryPageKeyWords?: InputMaybe<SortOrder>;
  categoryPageTitle?: InputMaybe<SortOrder>;
  categoryText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customAd?: InputMaybe<CategoryPageAdOrderByWithRelationAndSearchRelevanceInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  imageId?: InputMaybe<SortOrderInput>;
  position?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByRelationAggregateInput>;
  postSectionOnHomepage?: InputMaybe<SortOrder>;
  postSectionPosition?: InputMaybe<SortOrder>;
  postSectionType?: InputMaybe<SortOrder>;
  rsses?: InputMaybe<RssOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  subcategory?: InputMaybe<SubCategoryOrderByRelationAggregateInput>;
  subscribers?: InputMaybe<SubscriberOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryPageAd = {
  __typename?: 'CategoryPageAd';
  category?: Maybe<Category>;
  categoryId: Scalars['String']['output'];
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
};

export type CategoryPageAdCountAggregate = {
  __typename?: 'CategoryPageAdCountAggregate';
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  customHtml: Scalars['Int']['output'];
  html: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['Int']['output'];
  redirectUrl: Scalars['Int']['output'];
};

export type CategoryPageAdCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type CategoryPageAdCreateInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutCustomAdInput>;
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type CategoryPageAdCreateManyInput = {
  categoryId: Scalars['String']['input'];
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type CategoryPageAdCreateNestedOneWithoutCategoryInput = {
  connect?: InputMaybe<CategoryPageAdWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryPageAdCreateOrConnectWithoutCategoryInput>;
  create?: InputMaybe<CategoryPageAdCreateWithoutCategoryInput>;
};

export type CategoryPageAdCreateOrConnectWithoutCategoryInput = {
  create: CategoryPageAdCreateWithoutCategoryInput;
  where: CategoryPageAdWhereUniqueInput;
};

export type CategoryPageAdCreateWithoutCategoryInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type CategoryPageAdGroupBy = {
  __typename?: 'CategoryPageAdGroupBy';
  _count?: Maybe<CategoryPageAdCountAggregate>;
  _max?: Maybe<CategoryPageAdMaxAggregate>;
  _min?: Maybe<CategoryPageAdMinAggregate>;
  categoryId: Scalars['String']['output'];
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
};

export type CategoryPageAdMaxAggregate = {
  __typename?: 'CategoryPageAdMaxAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type CategoryPageAdMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type CategoryPageAdMinAggregate = {
  __typename?: 'CategoryPageAdMinAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type CategoryPageAdMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export enum CategoryPageAdOrderByRelevanceFieldEnum {
  CategoryId = 'categoryId',
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  RedirectUrl = 'redirectUrl'
}

export type CategoryPageAdOrderByRelevanceInput = {
  fields: Array<CategoryPageAdOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type CategoryPageAdOrderByWithAggregationInput = {
  _count?: InputMaybe<CategoryPageAdCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryPageAdMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryPageAdMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type CategoryPageAdOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CategoryPageAdOrderByRelevanceInput>;
  category?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  categoryId?: InputMaybe<SortOrder>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type CategoryPageAdRelationFilter = {
  is?: InputMaybe<CategoryPageAdWhereInput>;
  isNot?: InputMaybe<CategoryPageAdWhereInput>;
};

export enum CategoryPageAdScalarFieldEnum {
  CategoryId = 'categoryId',
  CustomHtml = 'customHtml',
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  RedirectUrl = 'redirectUrl'
}

export type CategoryPageAdScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryPageAdScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryPageAdScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryPageAdScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  customHtml?: InputMaybe<BoolWithAggregatesFilter>;
  html?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  imageUrl?: InputMaybe<StringWithAggregatesFilter>;
  redirectUrl?: InputMaybe<StringWithAggregatesFilter>;
};

export type CategoryPageAdUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutCustomAdNestedInput>;
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CategoryPageAdUpdateManyMutationInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CategoryPageAdUpdateOneWithoutCategoryNestedInput = {
  connect?: InputMaybe<CategoryPageAdWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryPageAdCreateOrConnectWithoutCategoryInput>;
  create?: InputMaybe<CategoryPageAdCreateWithoutCategoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CategoryPageAdUpdateWithoutCategoryInput>;
  upsert?: InputMaybe<CategoryPageAdUpsertWithoutCategoryInput>;
};

export type CategoryPageAdUpdateWithoutCategoryInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CategoryPageAdUpsertWithoutCategoryInput = {
  create: CategoryPageAdCreateWithoutCategoryInput;
  update: CategoryPageAdUpdateWithoutCategoryInput;
};

export type CategoryPageAdWhereInput = {
  AND?: InputMaybe<Array<CategoryPageAdWhereInput>>;
  NOT?: InputMaybe<Array<CategoryPageAdWhereInput>>;
  OR?: InputMaybe<Array<CategoryPageAdWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  customHtml?: InputMaybe<BoolFilter>;
  html?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  redirectUrl?: InputMaybe<StringFilter>;
};

export type CategoryPageAdWhereUniqueInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export enum CategoryScalarFieldEnum {
  AddToMenu = 'addToMenu',
  AddTop = 'addTop',
  Category = 'category',
  CategoryPageKeyWords = 'categoryPageKeyWords',
  CategoryPageTitle = 'categoryPageTitle',
  CategoryText = 'categoryText',
  CreatedAt = 'createdAt',
  Id = 'id',
  ImageId = 'imageId',
  Position = 'position',
  PostSectionOnHomepage = 'postSectionOnHomepage',
  PostSectionPosition = 'postSectionPosition',
  PostSectionType = 'postSectionType',
  Slug = 'slug',
  UpdatedAt = 'updatedAt'
}

export type CategoryScalarWhereInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereInput>>;
  addToMenu?: InputMaybe<BoolFilter>;
  addTop?: InputMaybe<BoolFilter>;
  category?: InputMaybe<StringFilter>;
  categoryPageKeyWords?: InputMaybe<StringFilter>;
  categoryPageTitle?: InputMaybe<StringFilter>;
  categoryText?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  imageId?: InputMaybe<StringNullableFilter>;
  position?: InputMaybe<IntFilter>;
  postSectionOnHomepage?: InputMaybe<BoolFilter>;
  postSectionPosition?: InputMaybe<IntFilter>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  addToMenu?: InputMaybe<BoolWithAggregatesFilter>;
  addTop?: InputMaybe<BoolWithAggregatesFilter>;
  category?: InputMaybe<StringWithAggregatesFilter>;
  categoryPageKeyWords?: InputMaybe<StringWithAggregatesFilter>;
  categoryPageTitle?: InputMaybe<StringWithAggregatesFilter>;
  categoryText?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  imageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  position?: InputMaybe<IntWithAggregatesFilter>;
  postSectionOnHomepage?: InputMaybe<BoolWithAggregatesFilter>;
  postSectionPosition?: InputMaybe<IntWithAggregatesFilter>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export enum CategorySectionType {
  AddToCommonSection = 'addToCommonSection',
  ColoumnWithWizard = 'coloumnWithWizard',
  GridWithWizard = 'gridWithWizard',
  IsGridSection = 'isGridSection',
  IsPlainSection = 'isPlainSection',
  IsVideoSection = 'isVideoSection',
  IsblueSection = 'isblueSection'
}

export type CategorySumAggregate = {
  __typename?: 'CategorySumAggregate';
  position?: Maybe<Scalars['Int']['output']>;
  postSectionPosition?: Maybe<Scalars['Int']['output']>;
};

export type CategorySumOrderByAggregateInput = {
  position?: InputMaybe<SortOrder>;
  postSectionPosition?: InputMaybe<SortOrder>;
};

export type CategoryUpdateInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<CategoryPageAdUpdateOneWithoutCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostCategoryNestedInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutCategoryNestedInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutCategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateManyWithoutCategoryNestedInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutCategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyMutationInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyWithWhereWithoutImageInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithWhereWithoutSubscribersInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithoutImageNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutImageInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutImageInput>>;
  createMany?: InputMaybe<CategoryCreateManyImageInputEnvelope>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutImageInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutImageInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutImageInput>>;
};

export type CategoryUpdateManyWithoutSubscribersNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutSubscribersInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutSubscribersInput>>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutSubscribersInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutSubscribersInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutSubscribersInput>>;
};

export type CategoryUpdateOneRequiredWithoutRssesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutRssesInput>;
  create?: InputMaybe<CategoryCreateWithoutRssesInput>;
  update?: InputMaybe<CategoryUpdateWithoutRssesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutRssesInput>;
};

export type CategoryUpdateOneRequiredWithoutSubcategoryNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubcategoryInput>;
  create?: InputMaybe<CategoryCreateWithoutSubcategoryInput>;
  update?: InputMaybe<CategoryUpdateWithoutSubcategoryInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutSubcategoryInput>;
};

export type CategoryUpdateOneWithoutCustomAdNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutCustomAdInput>;
  create?: InputMaybe<CategoryCreateWithoutCustomAdInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CategoryUpdateWithoutCustomAdInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutCustomAdInput>;
};

export type CategoryUpdateOneWithoutPostNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<CategoryCreateWithoutPostInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CategoryUpdateWithoutPostInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutPostInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutImageInput = {
  data: CategoryUpdateWithoutImageInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithWhereUniqueWithoutSubscribersInput = {
  data: CategoryUpdateWithoutSubscribersInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithoutCustomAdInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostCategoryNestedInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutCategoryNestedInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutCategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateManyWithoutCategoryNestedInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutCategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutImageInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<CategoryPageAdUpdateOneWithoutCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutCategoryNestedInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutCategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateManyWithoutCategoryNestedInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutCategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutPostInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<CategoryPageAdUpdateOneWithoutCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostCategoryNestedInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutCategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateManyWithoutCategoryNestedInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutCategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutRssesInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<CategoryPageAdUpdateOneWithoutCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostCategoryNestedInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutCategoryNestedInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateManyWithoutCategoryNestedInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutCategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutSubcategoryInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<CategoryPageAdUpdateOneWithoutCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostCategoryNestedInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutCategoryNestedInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutCategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutCategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutSubscribersInput = {
  addToMenu?: InputMaybe<BoolFieldUpdateOperationsInput>;
  addTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  categoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<CategoryPageAdUpdateOneWithoutCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostCategoryNestedInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutCategoryNestedInput>;
  postSectionOnHomepage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postSectionPosition?: InputMaybe<IntFieldUpdateOperationsInput>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFieldUpdateOperationsInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutCategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateManyWithoutCategoryNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpsertWithWhereUniqueWithoutImageInput = {
  create: CategoryCreateWithoutImageInput;
  update: CategoryUpdateWithoutImageInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithWhereUniqueWithoutSubscribersInput = {
  create: CategoryCreateWithoutSubscribersInput;
  update: CategoryUpdateWithoutSubscribersInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithoutCustomAdInput = {
  create: CategoryCreateWithoutCustomAdInput;
  update: CategoryUpdateWithoutCustomAdInput;
};

export type CategoryUpsertWithoutPostInput = {
  create: CategoryCreateWithoutPostInput;
  update: CategoryUpdateWithoutPostInput;
};

export type CategoryUpsertWithoutRssesInput = {
  create: CategoryCreateWithoutRssesInput;
  update: CategoryUpdateWithoutRssesInput;
};

export type CategoryUpsertWithoutSubcategoryInput = {
  create: CategoryCreateWithoutSubcategoryInput;
  update: CategoryUpdateWithoutSubcategoryInput;
};

export type CategoryWhereInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  addToMenu?: InputMaybe<BoolFilter>;
  addTop?: InputMaybe<BoolFilter>;
  category?: InputMaybe<StringFilter>;
  categoryPageKeyWords?: InputMaybe<StringFilter>;
  categoryPageTitle?: InputMaybe<StringFilter>;
  categoryText?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customAd?: InputMaybe<CategoryPageAdRelationFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<MediaRelationFilter>;
  imageId?: InputMaybe<StringNullableFilter>;
  position?: InputMaybe<IntFilter>;
  post?: InputMaybe<PostListRelationFilter>;
  postSectionOnHomepage?: InputMaybe<BoolFilter>;
  postSectionPosition?: InputMaybe<IntFilter>;
  postSectionType?: InputMaybe<EnumCategorySectionTypeFilter>;
  rsses?: InputMaybe<RssListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  subcategory?: InputMaybe<SubCategoryListRelationFilter>;
  subscribers?: InputMaybe<SubscriberListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  _count?: Maybe<CommentCount>;
  commentReactions: Array<CommentReaction>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  mainComment: Scalars['Boolean']['output'];
  parent?: Maybe<Comment>;
  parentId?: Maybe<Scalars['String']['output']>;
  post: Post;
  postId: Scalars['String']['output'];
  repliedComments: Array<Comment>;
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};


export type CommentCommentReactionsArgs = {
  cursor?: InputMaybe<CommentReactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentReactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type CommentRepliedCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};

export type CommentCount = {
  __typename?: 'CommentCount';
  commentReactions: Scalars['Int']['output'];
  repliedComments: Scalars['Int']['output'];
};


export type CommentCountCommentReactionsArgs = {
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type CommentCountRepliedCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};

export type CommentCountAggregate = {
  __typename?: 'CommentCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainComment: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  postId: Scalars['Int']['output'];
  text: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type CommentCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainComment?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentCreateInput = {
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutCommentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<CommentCreateNestedOneWithoutRepliedCommentsInput>;
  post: PostCreateNestedOneWithoutCommentsInput;
  repliedComments?: InputMaybe<CommentCreateNestedManyWithoutParentInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type CommentCreateManyParentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  postId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type CommentCreateManyParentInputEnvelope = {
  data: Array<CommentCreateManyParentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateManyPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type CommentCreateManyPostInputEnvelope = {
  data: Array<CommentCreateManyPostInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateManyUserInputEnvelope = {
  data: Array<CommentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateNestedManyWithoutParentInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutParentInput>>;
  createMany?: InputMaybe<CommentCreateManyParentInputEnvelope>;
};

export type CommentCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutPostInput>>;
  createMany?: InputMaybe<CommentCreateManyPostInputEnvelope>;
};

export type CommentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentCreateManyUserInputEnvelope>;
};

export type CommentCreateNestedOneWithoutCommentReactionsInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutCommentReactionsInput>;
  create?: InputMaybe<CommentCreateWithoutCommentReactionsInput>;
};

export type CommentCreateNestedOneWithoutRepliedCommentsInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutRepliedCommentsInput>;
  create?: InputMaybe<CommentCreateWithoutRepliedCommentsInput>;
};

export type CommentCreateOrConnectWithoutCommentReactionsInput = {
  create: CommentCreateWithoutCommentReactionsInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutParentInput = {
  create: CommentCreateWithoutParentInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutPostInput = {
  create: CommentCreateWithoutPostInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutRepliedCommentsInput = {
  create: CommentCreateWithoutRepliedCommentsInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutUserInput = {
  create: CommentCreateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateWithoutCommentReactionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<CommentCreateNestedOneWithoutRepliedCommentsInput>;
  post: PostCreateNestedOneWithoutCommentsInput;
  repliedComments?: InputMaybe<CommentCreateNestedManyWithoutParentInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutParentInput = {
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutCommentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  post: PostCreateNestedOneWithoutCommentsInput;
  repliedComments?: InputMaybe<CommentCreateNestedManyWithoutParentInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutPostInput = {
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutCommentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<CommentCreateNestedOneWithoutRepliedCommentsInput>;
  repliedComments?: InputMaybe<CommentCreateNestedManyWithoutParentInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutRepliedCommentsInput = {
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutCommentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<CommentCreateNestedOneWithoutRepliedCommentsInput>;
  post: PostCreateNestedOneWithoutCommentsInput;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutUserInput = {
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutCommentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainComment?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<CommentCreateNestedOneWithoutRepliedCommentsInput>;
  post: PostCreateNestedOneWithoutCommentsInput;
  repliedComments?: InputMaybe<CommentCreateNestedManyWithoutParentInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentGroupBy = {
  __typename?: 'CommentGroupBy';
  _count?: Maybe<CommentCountAggregate>;
  _max?: Maybe<CommentMaxAggregate>;
  _min?: Maybe<CommentMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  mainComment: Scalars['Boolean']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  postId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type CommentListRelationFilter = {
  every?: InputMaybe<CommentWhereInput>;
  none?: InputMaybe<CommentWhereInput>;
  some?: InputMaybe<CommentWhereInput>;
};

export type CommentMaxAggregate = {
  __typename?: 'CommentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mainComment?: Maybe<Scalars['Boolean']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CommentMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainComment?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentMinAggregate = {
  __typename?: 'CommentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mainComment?: Maybe<Scalars['Boolean']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CommentMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainComment?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CommentOrderByRelevanceFieldEnum {
  Id = 'id',
  ParentId = 'parentId',
  PostId = 'postId',
  Text = 'text',
  UserId = 'userId'
}

export type CommentOrderByRelevanceInput = {
  fields: Array<CommentOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type CommentOrderByWithAggregationInput = {
  _count?: InputMaybe<CommentCountOrderByAggregateInput>;
  _max?: InputMaybe<CommentMaxOrderByAggregateInput>;
  _min?: InputMaybe<CommentMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainComment?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrderInput>;
  postId?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CommentOrderByRelevanceInput>;
  commentReactions?: InputMaybe<CommentReactionOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainComment?: InputMaybe<SortOrder>;
  parent?: InputMaybe<CommentOrderByWithRelationAndSearchRelevanceInput>;
  parentId?: InputMaybe<SortOrderInput>;
  post?: InputMaybe<PostOrderByWithRelationAndSearchRelevanceInput>;
  postId?: InputMaybe<SortOrder>;
  repliedComments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentReaction = {
  __typename?: 'CommentReaction';
  User: User;
  comment: Comment;
  commentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CommentReactionCountAggregate = {
  __typename?: 'CommentReactionCountAggregate';
  _all: Scalars['Int']['output'];
  commentId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type CommentReactionCountOrderByAggregateInput = {
  commentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentReactionCreateInput = {
  User: UserCreateNestedOneWithoutCommentReactionsInput;
  comment: CommentCreateNestedOneWithoutCommentReactionsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CommentReactionCreateManyCommentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CommentReactionCreateManyCommentInputEnvelope = {
  data: Array<CommentReactionCreateManyCommentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentReactionCreateManyInput = {
  commentId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CommentReactionCreateManyUserInput = {
  commentId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CommentReactionCreateManyUserInputEnvelope = {
  data: Array<CommentReactionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentReactionCreateNestedManyWithoutCommentInput = {
  connect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentReactionCreateOrConnectWithoutCommentInput>>;
  create?: InputMaybe<Array<CommentReactionCreateWithoutCommentInput>>;
  createMany?: InputMaybe<CommentReactionCreateManyCommentInputEnvelope>;
};

export type CommentReactionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentReactionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentReactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentReactionCreateManyUserInputEnvelope>;
};

export type CommentReactionCreateOrConnectWithoutCommentInput = {
  create: CommentReactionCreateWithoutCommentInput;
  where: CommentReactionWhereUniqueInput;
};

export type CommentReactionCreateOrConnectWithoutUserInput = {
  create: CommentReactionCreateWithoutUserInput;
  where: CommentReactionWhereUniqueInput;
};

export type CommentReactionCreateWithoutCommentInput = {
  User: UserCreateNestedOneWithoutCommentReactionsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CommentReactionCreateWithoutUserInput = {
  comment: CommentCreateNestedOneWithoutCommentReactionsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CommentReactionGroupBy = {
  __typename?: 'CommentReactionGroupBy';
  _count?: Maybe<CommentReactionCountAggregate>;
  _max?: Maybe<CommentReactionMaxAggregate>;
  _min?: Maybe<CommentReactionMinAggregate>;
  commentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CommentReactionListRelationFilter = {
  every?: InputMaybe<CommentReactionWhereInput>;
  none?: InputMaybe<CommentReactionWhereInput>;
  some?: InputMaybe<CommentReactionWhereInput>;
};

export type CommentReactionMaxAggregate = {
  __typename?: 'CommentReactionMaxAggregate';
  commentId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CommentReactionMaxOrderByAggregateInput = {
  commentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentReactionMinAggregate = {
  __typename?: 'CommentReactionMinAggregate';
  commentId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CommentReactionMinOrderByAggregateInput = {
  commentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentReactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CommentReactionOrderByRelevanceFieldEnum {
  CommentId = 'commentId',
  Id = 'id',
  UserId = 'userId'
}

export type CommentReactionOrderByRelevanceInput = {
  fields: Array<CommentReactionOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type CommentReactionOrderByWithAggregationInput = {
  _count?: InputMaybe<CommentReactionCountOrderByAggregateInput>;
  _max?: InputMaybe<CommentReactionMaxOrderByAggregateInput>;
  _min?: InputMaybe<CommentReactionMinOrderByAggregateInput>;
  commentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentReactionOrderByWithRelationAndSearchRelevanceInput = {
  User?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: InputMaybe<CommentReactionOrderByRelevanceInput>;
  comment?: InputMaybe<CommentOrderByWithRelationAndSearchRelevanceInput>;
  commentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum CommentReactionScalarFieldEnum {
  CommentId = 'commentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UserId = 'userId'
}

export type CommentReactionScalarWhereInput = {
  AND?: InputMaybe<Array<CommentReactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommentReactionScalarWhereInput>>;
  OR?: InputMaybe<Array<CommentReactionScalarWhereInput>>;
  commentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CommentReactionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CommentReactionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CommentReactionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CommentReactionScalarWhereWithAggregatesInput>>;
  commentId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CommentReactionUpdateInput = {
  User?: InputMaybe<UserUpdateOneRequiredWithoutCommentReactionsNestedInput>;
  comment?: InputMaybe<CommentUpdateOneRequiredWithoutCommentReactionsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CommentReactionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CommentReactionUpdateManyWithWhereWithoutCommentInput = {
  data: CommentReactionUpdateManyMutationInput;
  where: CommentReactionScalarWhereInput;
};

export type CommentReactionUpdateManyWithWhereWithoutUserInput = {
  data: CommentReactionUpdateManyMutationInput;
  where: CommentReactionScalarWhereInput;
};

export type CommentReactionUpdateManyWithoutCommentNestedInput = {
  connect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentReactionCreateOrConnectWithoutCommentInput>>;
  create?: InputMaybe<Array<CommentReactionCreateWithoutCommentInput>>;
  createMany?: InputMaybe<CommentReactionCreateManyCommentInputEnvelope>;
  delete?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentReactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentReactionUpdateWithWhereUniqueWithoutCommentInput>>;
  updateMany?: InputMaybe<Array<CommentReactionUpdateManyWithWhereWithoutCommentInput>>;
  upsert?: InputMaybe<Array<CommentReactionUpsertWithWhereUniqueWithoutCommentInput>>;
};

export type CommentReactionUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentReactionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentReactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentReactionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentReactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentReactionWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentReactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CommentReactionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CommentReactionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CommentReactionUpdateWithWhereUniqueWithoutCommentInput = {
  data: CommentReactionUpdateWithoutCommentInput;
  where: CommentReactionWhereUniqueInput;
};

export type CommentReactionUpdateWithWhereUniqueWithoutUserInput = {
  data: CommentReactionUpdateWithoutUserInput;
  where: CommentReactionWhereUniqueInput;
};

export type CommentReactionUpdateWithoutCommentInput = {
  User?: InputMaybe<UserUpdateOneRequiredWithoutCommentReactionsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CommentReactionUpdateWithoutUserInput = {
  comment?: InputMaybe<CommentUpdateOneRequiredWithoutCommentReactionsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CommentReactionUpsertWithWhereUniqueWithoutCommentInput = {
  create: CommentReactionCreateWithoutCommentInput;
  update: CommentReactionUpdateWithoutCommentInput;
  where: CommentReactionWhereUniqueInput;
};

export type CommentReactionUpsertWithWhereUniqueWithoutUserInput = {
  create: CommentReactionCreateWithoutUserInput;
  update: CommentReactionUpdateWithoutUserInput;
  where: CommentReactionWhereUniqueInput;
};

export type CommentReactionUserIdCommentIdCompoundUniqueInput = {
  commentId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CommentReactionWhereInput = {
  AND?: InputMaybe<Array<CommentReactionWhereInput>>;
  NOT?: InputMaybe<Array<CommentReactionWhereInput>>;
  OR?: InputMaybe<Array<CommentReactionWhereInput>>;
  User?: InputMaybe<UserRelationFilter>;
  comment?: InputMaybe<CommentRelationFilter>;
  commentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CommentReactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_commentId?: InputMaybe<CommentReactionUserIdCommentIdCompoundUniqueInput>;
};

export type CommentRelationFilter = {
  is?: InputMaybe<CommentWhereInput>;
  isNot?: InputMaybe<CommentWhereInput>;
};

export enum CommentScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainComment = 'mainComment',
  ParentId = 'parentId',
  PostId = 'postId',
  Text = 'text',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type CommentScalarWhereInput = {
  AND?: InputMaybe<Array<CommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  mainComment?: InputMaybe<BoolFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  postId?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CommentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CommentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  mainComment?: InputMaybe<BoolWithAggregatesFilter>;
  parentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  text?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CommentUpdateInput = {
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainComment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parent?: InputMaybe<CommentUpdateOneWithoutRepliedCommentsNestedInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutCommentsNestedInput>;
  repliedComments?: InputMaybe<CommentUpdateManyWithoutParentNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainComment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyWithWhereWithoutParentInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutPostInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutUserInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithoutParentNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutParentInput>>;
  createMany?: InputMaybe<CommentCreateManyParentInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutParentInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutParentInput>>;
};

export type CommentUpdateManyWithoutPostNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutPostInput>>;
  createMany?: InputMaybe<CommentCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutPostInput>>;
};

export type CommentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CommentUpdateOneRequiredWithoutCommentReactionsNestedInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutCommentReactionsInput>;
  create?: InputMaybe<CommentCreateWithoutCommentReactionsInput>;
  update?: InputMaybe<CommentUpdateWithoutCommentReactionsInput>;
  upsert?: InputMaybe<CommentUpsertWithoutCommentReactionsInput>;
};

export type CommentUpdateOneWithoutRepliedCommentsNestedInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutRepliedCommentsInput>;
  create?: InputMaybe<CommentCreateWithoutRepliedCommentsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CommentUpdateWithoutRepliedCommentsInput>;
  upsert?: InputMaybe<CommentUpsertWithoutRepliedCommentsInput>;
};

export type CommentUpdateWithWhereUniqueWithoutParentInput = {
  data: CommentUpdateWithoutParentInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutPostInput = {
  data: CommentUpdateWithoutPostInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutUserInput = {
  data: CommentUpdateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithoutCommentReactionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainComment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parent?: InputMaybe<CommentUpdateOneWithoutRepliedCommentsNestedInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutCommentsNestedInput>;
  repliedComments?: InputMaybe<CommentUpdateManyWithoutParentNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateWithoutParentInput = {
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainComment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutCommentsNestedInput>;
  repliedComments?: InputMaybe<CommentUpdateManyWithoutParentNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateWithoutPostInput = {
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainComment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parent?: InputMaybe<CommentUpdateOneWithoutRepliedCommentsNestedInput>;
  repliedComments?: InputMaybe<CommentUpdateManyWithoutParentNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateWithoutRepliedCommentsInput = {
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainComment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parent?: InputMaybe<CommentUpdateOneWithoutRepliedCommentsNestedInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateWithoutUserInput = {
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainComment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parent?: InputMaybe<CommentUpdateOneWithoutRepliedCommentsNestedInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutCommentsNestedInput>;
  repliedComments?: InputMaybe<CommentUpdateManyWithoutParentNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpsertWithWhereUniqueWithoutParentInput = {
  create: CommentCreateWithoutParentInput;
  update: CommentUpdateWithoutParentInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutPostInput = {
  create: CommentCreateWithoutPostInput;
  update: CommentUpdateWithoutPostInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutUserInput = {
  create: CommentCreateWithoutUserInput;
  update: CommentUpdateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithoutCommentReactionsInput = {
  create: CommentCreateWithoutCommentReactionsInput;
  update: CommentUpdateWithoutCommentReactionsInput;
};

export type CommentUpsertWithoutRepliedCommentsInput = {
  create: CommentCreateWithoutRepliedCommentsInput;
  update: CommentUpdateWithoutRepliedCommentsInput;
};

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>;
  NOT?: InputMaybe<Array<CommentWhereInput>>;
  OR?: InputMaybe<Array<CommentWhereInput>>;
  commentReactions?: InputMaybe<CommentReactionListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  mainComment?: InputMaybe<BoolFilter>;
  parent?: InputMaybe<CommentRelationFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  repliedComments?: InputMaybe<CommentListRelationFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ContactUsInput = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ContributorPostCreateInput = {
  description: Scalars['String']['input'];
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  pageDescription: Scalars['String']['input'];
  pageTitle: Scalars['String']['input'];
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  subHeading?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

/** New user data */
export type CreateOneUserArgsCustom = {
  avater?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  professionalName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EnumCategorySectionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CategorySectionType>;
};

export type EnumCategorySectionTypeFilter = {
  equals?: InputMaybe<CategorySectionType>;
  in?: InputMaybe<Array<CategorySectionType>>;
  not?: InputMaybe<NestedEnumCategorySectionTypeFilter>;
  notIn?: InputMaybe<Array<CategorySectionType>>;
};

export type EnumCategorySectionTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCategorySectionTypeFilter>;
  _min?: InputMaybe<NestedEnumCategorySectionTypeFilter>;
  equals?: InputMaybe<CategorySectionType>;
  in?: InputMaybe<Array<CategorySectionType>>;
  not?: InputMaybe<NestedEnumCategorySectionTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CategorySectionType>>;
};

export type EnumNotificationStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationStatus>;
};

export type EnumNotificationStatusFilter = {
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type EnumNotificationStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationStatusFilter>;
  _min?: InputMaybe<NestedEnumNotificationStatusFilter>;
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type EnumNotificationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationType>;
};

export type EnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumNotificationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationTypeFilter>;
  _min?: InputMaybe<NestedEnumNotificationTypeFilter>;
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumPostStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PostStatus>;
};

export type EnumPostStatusFilter = {
  equals?: InputMaybe<PostStatus>;
  in?: InputMaybe<Array<PostStatus>>;
  not?: InputMaybe<NestedEnumPostStatusFilter>;
  notIn?: InputMaybe<Array<PostStatus>>;
};

export type EnumPostStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPostStatusFilter>;
  _min?: InputMaybe<NestedEnumPostStatusFilter>;
  equals?: InputMaybe<PostStatus>;
  in?: InputMaybe<Array<PostStatus>>;
  not?: InputMaybe<NestedEnumPostStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PostStatus>>;
};

export type EnumUserAccountStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<UserAccountStatus>;
};

export type EnumUserAccountStatusFilter = {
  equals?: InputMaybe<UserAccountStatus>;
  in?: InputMaybe<Array<UserAccountStatus>>;
  not?: InputMaybe<NestedEnumUserAccountStatusFilter>;
  notIn?: InputMaybe<Array<UserAccountStatus>>;
};

export type EnumUserAccountStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserAccountStatusFilter>;
  _min?: InputMaybe<NestedEnumUserAccountStatusFilter>;
  equals?: InputMaybe<UserAccountStatus>;
  in?: InputMaybe<Array<UserAccountStatus>>;
  not?: InputMaybe<NestedEnumUserAccountStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserAccountStatus>>;
};

export type EnumUserRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<UserRole>;
};

export type EnumUserRoleFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type EnumUserRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserRoleFilter>;
  _min?: InputMaybe<NestedEnumUserRoleFilter>;
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type HomePageAd = {
  __typename?: 'HomePageAd';
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
};

export type HomePageAdCountAggregate = {
  __typename?: 'HomePageAdCountAggregate';
  _all: Scalars['Int']['output'];
  customHtml: Scalars['Int']['output'];
  html: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['Int']['output'];
  redirectUrl: Scalars['Int']['output'];
};

export type HomePageAdCountOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type HomePageAdCreateInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type HomePageAdCreateManyInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type HomePageAdGroupBy = {
  __typename?: 'HomePageAdGroupBy';
  _count?: Maybe<HomePageAdCountAggregate>;
  _max?: Maybe<HomePageAdMaxAggregate>;
  _min?: Maybe<HomePageAdMinAggregate>;
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
};

export type HomePageAdMaxAggregate = {
  __typename?: 'HomePageAdMaxAggregate';
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type HomePageAdMaxOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type HomePageAdMinAggregate = {
  __typename?: 'HomePageAdMinAggregate';
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type HomePageAdMinOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export enum HomePageAdOrderByRelevanceFieldEnum {
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  RedirectUrl = 'redirectUrl'
}

export type HomePageAdOrderByRelevanceInput = {
  fields: Array<HomePageAdOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type HomePageAdOrderByWithAggregationInput = {
  _count?: InputMaybe<HomePageAdCountOrderByAggregateInput>;
  _max?: InputMaybe<HomePageAdMaxOrderByAggregateInput>;
  _min?: InputMaybe<HomePageAdMinOrderByAggregateInput>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type HomePageAdOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<HomePageAdOrderByRelevanceInput>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export enum HomePageAdScalarFieldEnum {
  CustomHtml = 'customHtml',
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  RedirectUrl = 'redirectUrl'
}

export type HomePageAdScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<HomePageAdScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<HomePageAdScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<HomePageAdScalarWhereWithAggregatesInput>>;
  customHtml?: InputMaybe<BoolWithAggregatesFilter>;
  html?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  imageUrl?: InputMaybe<StringWithAggregatesFilter>;
  redirectUrl?: InputMaybe<StringWithAggregatesFilter>;
};

export type HomePageAdUpdateInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HomePageAdUpdateManyMutationInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HomePageAdWhereInput = {
  AND?: InputMaybe<Array<HomePageAdWhereInput>>;
  NOT?: InputMaybe<Array<HomePageAdWhereInput>>;
  OR?: InputMaybe<Array<HomePageAdWhereInput>>;
  customHtml?: InputMaybe<BoolFilter>;
  html?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  redirectUrl?: InputMaybe<StringFilter>;
};

export type HomePageAdWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LikesByUser = {
  __typename?: 'LikesByUser';
  User: User;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  post: Post;
  postId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type LikesByUserCountAggregate = {
  __typename?: 'LikesByUserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  postId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type LikesByUserCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type LikesByUserCreateInput = {
  User: UserCreateNestedOneWithoutLikesByUserInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  post: PostCreateNestedOneWithoutLikesByUserInput;
};

export type LikesByUserCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type LikesByUserCreateManyPostInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type LikesByUserCreateManyPostInputEnvelope = {
  data: Array<LikesByUserCreateManyPostInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LikesByUserCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
};

export type LikesByUserCreateManyUserInputEnvelope = {
  data: Array<LikesByUserCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LikesByUserCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikesByUserCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<LikesByUserCreateWithoutPostInput>>;
  createMany?: InputMaybe<LikesByUserCreateManyPostInputEnvelope>;
};

export type LikesByUserCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikesByUserCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LikesByUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<LikesByUserCreateManyUserInputEnvelope>;
};

export type LikesByUserCreateOrConnectWithoutPostInput = {
  create: LikesByUserCreateWithoutPostInput;
  where: LikesByUserWhereUniqueInput;
};

export type LikesByUserCreateOrConnectWithoutUserInput = {
  create: LikesByUserCreateWithoutUserInput;
  where: LikesByUserWhereUniqueInput;
};

export type LikesByUserCreateWithoutPostInput = {
  User: UserCreateNestedOneWithoutLikesByUserInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type LikesByUserCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  post: PostCreateNestedOneWithoutLikesByUserInput;
};

export type LikesByUserGroupBy = {
  __typename?: 'LikesByUserGroupBy';
  _count?: Maybe<LikesByUserCountAggregate>;
  _max?: Maybe<LikesByUserMaxAggregate>;
  _min?: Maybe<LikesByUserMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  postId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type LikesByUserListRelationFilter = {
  every?: InputMaybe<LikesByUserWhereInput>;
  none?: InputMaybe<LikesByUserWhereInput>;
  some?: InputMaybe<LikesByUserWhereInput>;
};

export type LikesByUserMaxAggregate = {
  __typename?: 'LikesByUserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type LikesByUserMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type LikesByUserMinAggregate = {
  __typename?: 'LikesByUserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type LikesByUserMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type LikesByUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum LikesByUserOrderByRelevanceFieldEnum {
  Id = 'id',
  PostId = 'postId',
  UserId = 'userId'
}

export type LikesByUserOrderByRelevanceInput = {
  fields: Array<LikesByUserOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type LikesByUserOrderByWithAggregationInput = {
  _count?: InputMaybe<LikesByUserCountOrderByAggregateInput>;
  _max?: InputMaybe<LikesByUserMaxOrderByAggregateInput>;
  _min?: InputMaybe<LikesByUserMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type LikesByUserOrderByWithRelationAndSearchRelevanceInput = {
  User?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: InputMaybe<LikesByUserOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationAndSearchRelevanceInput>;
  postId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum LikesByUserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PostId = 'postId',
  UserId = 'userId'
}

export type LikesByUserScalarWhereInput = {
  AND?: InputMaybe<Array<LikesByUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<LikesByUserScalarWhereInput>>;
  OR?: InputMaybe<Array<LikesByUserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  postId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type LikesByUserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LikesByUserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LikesByUserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LikesByUserScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type LikesByUserUpdateInput = {
  User?: InputMaybe<UserUpdateOneRequiredWithoutLikesByUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutLikesByUserNestedInput>;
};

export type LikesByUserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LikesByUserUpdateManyWithWhereWithoutPostInput = {
  data: LikesByUserUpdateManyMutationInput;
  where: LikesByUserScalarWhereInput;
};

export type LikesByUserUpdateManyWithWhereWithoutUserInput = {
  data: LikesByUserUpdateManyMutationInput;
  where: LikesByUserScalarWhereInput;
};

export type LikesByUserUpdateManyWithoutPostNestedInput = {
  connect?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikesByUserCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<LikesByUserCreateWithoutPostInput>>;
  createMany?: InputMaybe<LikesByUserCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LikesByUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  set?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  update?: InputMaybe<Array<LikesByUserUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<LikesByUserUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<LikesByUserUpsertWithWhereUniqueWithoutPostInput>>;
};

export type LikesByUserUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikesByUserCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LikesByUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<LikesByUserCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LikesByUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  set?: InputMaybe<Array<LikesByUserWhereUniqueInput>>;
  update?: InputMaybe<Array<LikesByUserUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LikesByUserUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LikesByUserUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LikesByUserUpdateWithWhereUniqueWithoutPostInput = {
  data: LikesByUserUpdateWithoutPostInput;
  where: LikesByUserWhereUniqueInput;
};

export type LikesByUserUpdateWithWhereUniqueWithoutUserInput = {
  data: LikesByUserUpdateWithoutUserInput;
  where: LikesByUserWhereUniqueInput;
};

export type LikesByUserUpdateWithoutPostInput = {
  User?: InputMaybe<UserUpdateOneRequiredWithoutLikesByUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LikesByUserUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneRequiredWithoutLikesByUserNestedInput>;
};

export type LikesByUserUpsertWithWhereUniqueWithoutPostInput = {
  create: LikesByUserCreateWithoutPostInput;
  update: LikesByUserUpdateWithoutPostInput;
  where: LikesByUserWhereUniqueInput;
};

export type LikesByUserUpsertWithWhereUniqueWithoutUserInput = {
  create: LikesByUserCreateWithoutUserInput;
  update: LikesByUserUpdateWithoutUserInput;
  where: LikesByUserWhereUniqueInput;
};

export type LikesByUserUserIdPostIdCompoundUniqueInput = {
  postId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type LikesByUserWhereInput = {
  AND?: InputMaybe<Array<LikesByUserWhereInput>>;
  NOT?: InputMaybe<Array<LikesByUserWhereInput>>;
  OR?: InputMaybe<Array<LikesByUserWhereInput>>;
  User?: InputMaybe<UserRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type LikesByUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_postId?: InputMaybe<LikesByUserUserIdPostIdCompoundUniqueInput>;
};

export type LoginResponsce = {
  __typename?: 'LoginResponsce';
  accessToken?: Maybe<Scalars['String']['output']>;
  expiresIn: Scalars['Float']['output'];
  isAuthenticated: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<UserForResponsce>;
};

export type Media = {
  __typename?: 'Media';
  _count?: Maybe<MediaCount>;
  category: Array<MediaCategory>;
  filename: Scalars['String']['output'];
  fromRss?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  postCategory: Array<Category>;
  postsAsImage: Array<Post>;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type MediaCategoryArgs = {
  cursor?: InputMaybe<MediaCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type MediaPostCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type MediaPostsAsImageArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};

export type MediaCategory = {
  __typename?: 'MediaCategory';
  _count?: Maybe<MediaCategoryCount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  media: Array<Media>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type MediaCategoryMediaArgs = {
  cursor?: InputMaybe<MediaWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaWhereInput>;
};

export type MediaCategoryCount = {
  __typename?: 'MediaCategoryCount';
  media: Scalars['Int']['output'];
};


export type MediaCategoryCountMediaArgs = {
  where?: InputMaybe<MediaWhereInput>;
};

export type MediaCategoryCountAggregate = {
  __typename?: 'MediaCategoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type MediaCategoryCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MediaCategoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<MediaCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MediaCategoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MediaCategoryCreateNestedManyWithoutMediaInput = {
  connect?: InputMaybe<Array<MediaCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MediaCategoryCreateOrConnectWithoutMediaInput>>;
  create?: InputMaybe<Array<MediaCategoryCreateWithoutMediaInput>>;
};

export type MediaCategoryCreateOrConnectWithoutMediaInput = {
  create: MediaCategoryCreateWithoutMediaInput;
  where: MediaCategoryWhereUniqueInput;
};

export type MediaCategoryCreateWithoutMediaInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MediaCategoryGroupBy = {
  __typename?: 'MediaCategoryGroupBy';
  _count?: Maybe<MediaCategoryCountAggregate>;
  _max?: Maybe<MediaCategoryMaxAggregate>;
  _min?: Maybe<MediaCategoryMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MediaCategoryListRelationFilter = {
  every?: InputMaybe<MediaCategoryWhereInput>;
  none?: InputMaybe<MediaCategoryWhereInput>;
  some?: InputMaybe<MediaCategoryWhereInput>;
};

export type MediaCategoryMaxAggregate = {
  __typename?: 'MediaCategoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MediaCategoryMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MediaCategoryMinAggregate = {
  __typename?: 'MediaCategoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MediaCategoryMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MediaCategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum MediaCategoryOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type MediaCategoryOrderByRelevanceInput = {
  fields: Array<MediaCategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type MediaCategoryOrderByWithAggregationInput = {
  _count?: InputMaybe<MediaCategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<MediaCategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<MediaCategoryMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MediaCategoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<MediaCategoryOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  media?: InputMaybe<MediaOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum MediaCategoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type MediaCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<MediaCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<MediaCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<MediaCategoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MediaCategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MediaCategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MediaCategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MediaCategoryScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MediaCategoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  media?: InputMaybe<MediaUpdateManyWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MediaCategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MediaCategoryUpdateManyWithWhereWithoutMediaInput = {
  data: MediaCategoryUpdateManyMutationInput;
  where: MediaCategoryScalarWhereInput;
};

export type MediaCategoryUpdateManyWithoutMediaNestedInput = {
  connect?: InputMaybe<Array<MediaCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MediaCategoryCreateOrConnectWithoutMediaInput>>;
  create?: InputMaybe<Array<MediaCategoryCreateWithoutMediaInput>>;
  delete?: InputMaybe<Array<MediaCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MediaCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MediaCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<MediaCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<MediaCategoryUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: InputMaybe<Array<MediaCategoryUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: InputMaybe<Array<MediaCategoryUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type MediaCategoryUpdateWithWhereUniqueWithoutMediaInput = {
  data: MediaCategoryUpdateWithoutMediaInput;
  where: MediaCategoryWhereUniqueInput;
};

export type MediaCategoryUpdateWithoutMediaInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MediaCategoryUpsertWithWhereUniqueWithoutMediaInput = {
  create: MediaCategoryCreateWithoutMediaInput;
  update: MediaCategoryUpdateWithoutMediaInput;
  where: MediaCategoryWhereUniqueInput;
};

export type MediaCategoryWhereInput = {
  AND?: InputMaybe<Array<MediaCategoryWhereInput>>;
  NOT?: InputMaybe<Array<MediaCategoryWhereInput>>;
  OR?: InputMaybe<Array<MediaCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  media?: InputMaybe<MediaListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MediaCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MediaCount = {
  __typename?: 'MediaCount';
  category: Scalars['Int']['output'];
  postCategory: Scalars['Int']['output'];
  postsAsImage: Scalars['Int']['output'];
};


export type MediaCountCategoryArgs = {
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type MediaCountPostCategoryArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type MediaCountPostsAsImageArgs = {
  where?: InputMaybe<PostWhereInput>;
};

export type MediaCountAggregate = {
  __typename?: 'MediaCountAggregate';
  _all: Scalars['Int']['output'];
  filename: Scalars['Int']['output'];
  fromRss: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type MediaCountOrderByAggregateInput = {
  filename?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type MediaCreateInput = {
  category?: InputMaybe<MediaCategoryCreateNestedManyWithoutMediaInput>;
  filename: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  postCategory?: InputMaybe<CategoryCreateNestedManyWithoutImageInput>;
  postsAsImage?: InputMaybe<PostCreateNestedManyWithoutImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MediaCreateManyInput = {
  filename: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MediaCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MediaCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<MediaCreateWithoutCategoryInput>>;
};

export type MediaCreateNestedOneWithoutPostCategoryInput = {
  connect?: InputMaybe<MediaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MediaCreateOrConnectWithoutPostCategoryInput>;
  create?: InputMaybe<MediaCreateWithoutPostCategoryInput>;
};

export type MediaCreateNestedOneWithoutPostsAsImageInput = {
  connect?: InputMaybe<MediaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MediaCreateOrConnectWithoutPostsAsImageInput>;
  create?: InputMaybe<MediaCreateWithoutPostsAsImageInput>;
};

export type MediaCreateOrConnectWithoutCategoryInput = {
  create: MediaCreateWithoutCategoryInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutPostCategoryInput = {
  create: MediaCreateWithoutPostCategoryInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutPostsAsImageInput = {
  create: MediaCreateWithoutPostsAsImageInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateWithoutCategoryInput = {
  filename: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  postCategory?: InputMaybe<CategoryCreateNestedManyWithoutImageInput>;
  postsAsImage?: InputMaybe<PostCreateNestedManyWithoutImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MediaCreateWithoutPostCategoryInput = {
  category?: InputMaybe<MediaCategoryCreateNestedManyWithoutMediaInput>;
  filename: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  postsAsImage?: InputMaybe<PostCreateNestedManyWithoutImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MediaCreateWithoutPostsAsImageInput = {
  category?: InputMaybe<MediaCategoryCreateNestedManyWithoutMediaInput>;
  filename: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  postCategory?: InputMaybe<CategoryCreateNestedManyWithoutImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MediaGroupBy = {
  __typename?: 'MediaGroupBy';
  _count?: Maybe<MediaCountAggregate>;
  _max?: Maybe<MediaMaxAggregate>;
  _min?: Maybe<MediaMinAggregate>;
  filename: Scalars['String']['output'];
  fromRss?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type MediaListRelationFilter = {
  every?: InputMaybe<MediaWhereInput>;
  none?: InputMaybe<MediaWhereInput>;
  some?: InputMaybe<MediaWhereInput>;
};

export type MediaMaxAggregate = {
  __typename?: 'MediaMaxAggregate';
  filename?: Maybe<Scalars['String']['output']>;
  fromRss?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MediaMaxOrderByAggregateInput = {
  filename?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type MediaMinAggregate = {
  __typename?: 'MediaMinAggregate';
  filename?: Maybe<Scalars['String']['output']>;
  fromRss?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MediaMinOrderByAggregateInput = {
  filename?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type MediaOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum MediaOrderByRelevanceFieldEnum {
  Filename = 'filename',
  Id = 'id',
  Title = 'title',
  Type = 'type',
  Url = 'url'
}

export type MediaOrderByRelevanceInput = {
  fields: Array<MediaOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type MediaOrderByWithAggregationInput = {
  _count?: InputMaybe<MediaCountOrderByAggregateInput>;
  _max?: InputMaybe<MediaMaxOrderByAggregateInput>;
  _min?: InputMaybe<MediaMinOrderByAggregateInput>;
  filename?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type MediaOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<MediaOrderByRelevanceInput>;
  category?: InputMaybe<MediaCategoryOrderByRelationAggregateInput>;
  filename?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  postCategory?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  postsAsImage?: InputMaybe<PostOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type MediaRelationFilter = {
  is?: InputMaybe<MediaWhereInput>;
  isNot?: InputMaybe<MediaWhereInput>;
};

export enum MediaScalarFieldEnum {
  Filename = 'filename',
  FromRss = 'fromRss',
  Id = 'id',
  Title = 'title',
  Type = 'type',
  Url = 'url'
}

export type MediaScalarWhereInput = {
  AND?: InputMaybe<Array<MediaScalarWhereInput>>;
  NOT?: InputMaybe<Array<MediaScalarWhereInput>>;
  OR?: InputMaybe<Array<MediaScalarWhereInput>>;
  filename?: InputMaybe<StringFilter>;
  fromRss?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type MediaScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MediaScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MediaScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MediaScalarWhereWithAggregatesInput>>;
  filename?: InputMaybe<StringWithAggregatesFilter>;
  fromRss?: InputMaybe<BoolNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
};

export type MediaUpdateInput = {
  category?: InputMaybe<MediaCategoryUpdateManyWithoutMediaNestedInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postCategory?: InputMaybe<CategoryUpdateManyWithoutImageNestedInput>;
  postsAsImage?: InputMaybe<PostUpdateManyWithoutImageNestedInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MediaUpdateManyMutationInput = {
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MediaUpdateManyWithWhereWithoutCategoryInput = {
  data: MediaUpdateManyMutationInput;
  where: MediaScalarWhereInput;
};

export type MediaUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MediaCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<MediaCreateWithoutCategoryInput>>;
  delete?: InputMaybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MediaScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MediaWhereUniqueInput>>;
  set?: InputMaybe<Array<MediaWhereUniqueInput>>;
  update?: InputMaybe<Array<MediaUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<MediaUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<MediaUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type MediaUpdateOneWithoutPostCategoryNestedInput = {
  connect?: InputMaybe<MediaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MediaCreateOrConnectWithoutPostCategoryInput>;
  create?: InputMaybe<MediaCreateWithoutPostCategoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<MediaUpdateWithoutPostCategoryInput>;
  upsert?: InputMaybe<MediaUpsertWithoutPostCategoryInput>;
};

export type MediaUpdateOneWithoutPostsAsImageNestedInput = {
  connect?: InputMaybe<MediaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MediaCreateOrConnectWithoutPostsAsImageInput>;
  create?: InputMaybe<MediaCreateWithoutPostsAsImageInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<MediaUpdateWithoutPostsAsImageInput>;
  upsert?: InputMaybe<MediaUpsertWithoutPostsAsImageInput>;
};

export type MediaUpdateWithWhereUniqueWithoutCategoryInput = {
  data: MediaUpdateWithoutCategoryInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpdateWithoutCategoryInput = {
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postCategory?: InputMaybe<CategoryUpdateManyWithoutImageNestedInput>;
  postsAsImage?: InputMaybe<PostUpdateManyWithoutImageNestedInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MediaUpdateWithoutPostCategoryInput = {
  category?: InputMaybe<MediaCategoryUpdateManyWithoutMediaNestedInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postsAsImage?: InputMaybe<PostUpdateManyWithoutImageNestedInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MediaUpdateWithoutPostsAsImageInput = {
  category?: InputMaybe<MediaCategoryUpdateManyWithoutMediaNestedInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postCategory?: InputMaybe<CategoryUpdateManyWithoutImageNestedInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MediaUpsertWithWhereUniqueWithoutCategoryInput = {
  create: MediaCreateWithoutCategoryInput;
  update: MediaUpdateWithoutCategoryInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpsertWithoutPostCategoryInput = {
  create: MediaCreateWithoutPostCategoryInput;
  update: MediaUpdateWithoutPostCategoryInput;
};

export type MediaUpsertWithoutPostsAsImageInput = {
  create: MediaCreateWithoutPostsAsImageInput;
  update: MediaUpdateWithoutPostsAsImageInput;
};

export type MediaWhereInput = {
  AND?: InputMaybe<Array<MediaWhereInput>>;
  NOT?: InputMaybe<Array<MediaWhereInput>>;
  OR?: InputMaybe<Array<MediaWhereInput>>;
  category?: InputMaybe<MediaCategoryListRelationFilter>;
  filename?: InputMaybe<StringFilter>;
  fromRss?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  postCategory?: InputMaybe<CategoryListRelationFilter>;
  postsAsImage?: InputMaybe<PostListRelationFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type MediaWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminLogin?: Maybe<AdminLoginRespponsce>;
  contactUs?: Maybe<DefaultResponsce>;
  createManyCategory: AffectedRowsOutput;
  createManyCategoryPageAd: AffectedRowsOutput;
  createManyComment: AffectedRowsOutput;
  createManyCommentReaction: AffectedRowsOutput;
  createManyHomePageAd: AffectedRowsOutput;
  createManyLikesByUser: AffectedRowsOutput;
  createManyMedia: AffectedRowsOutput;
  createManyMediaCategory: AffectedRowsOutput;
  createManyNotification: AffectedRowsOutput;
  createManyPost: AffectedRowsOutput;
  createManyPostPageAd: AffectedRowsOutput;
  createManyRss: AffectedRowsOutput;
  createManySiteinfo: AffectedRowsOutput;
  createManySubCategory: AffectedRowsOutput;
  createManySubCategoryPageAd: AffectedRowsOutput;
  createManySubscriber: AffectedRowsOutput;
  createManyTag: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyUserPermissions: AffectedRowsOutput;
  createManyUserSocilaLinks: AffectedRowsOutput;
  createManyUserTokens: AffectedRowsOutput;
  createManyWebNotification: AffectedRowsOutput;
  createOneCategory: Category;
  createOneCategoryPageAd: CategoryPageAd;
  createOneComment: Comment;
  createOneCommentReaction: CommentReaction;
  createOneHomePageAd: HomePageAd;
  createOneLikesByUser: LikesByUser;
  createOneMedia: Media;
  createOneMediaCategory: MediaCategory;
  createOneNotification: Notification;
  createOnePost: Post;
  createOnePostPageAd: PostPageAd;
  createOneRss: Rss;
  createOneSiteinfo: Siteinfo;
  createOneSubCategory: SubCategory;
  createOneSubCategoryPageAd: SubCategoryPageAd;
  createOneSubscriber: Subscriber;
  createOneTag: Tag;
  createOneUser: User;
  createOneUserPermissions: UserPermissions;
  createOneUserSocilaLinks: UserSocilaLinks;
  createOneUserTokens: UserTokens;
  createOneWebNotification: WebNotification;
  createPostByContributor?: Maybe<DefaultResponsce>;
  deleteManyCategory: AffectedRowsOutput;
  deleteManyCategoryPageAd: AffectedRowsOutput;
  deleteManyComment: AffectedRowsOutput;
  deleteManyCommentReaction: AffectedRowsOutput;
  deleteManyHomePageAd: AffectedRowsOutput;
  deleteManyLikesByUser: AffectedRowsOutput;
  deleteManyMedia: AffectedRowsOutput;
  deleteManyMediaCategory: AffectedRowsOutput;
  deleteManyNotification: AffectedRowsOutput;
  deleteManyPost: AffectedRowsOutput;
  deleteManyPostPageAd: AffectedRowsOutput;
  deleteManyRss: AffectedRowsOutput;
  deleteManySiteinfo: AffectedRowsOutput;
  deleteManySubCategory: AffectedRowsOutput;
  deleteManySubCategoryPageAd: AffectedRowsOutput;
  deleteManySubscriber: AffectedRowsOutput;
  deleteManyTag: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyUserPermissions: AffectedRowsOutput;
  deleteManyUserSocilaLinks: AffectedRowsOutput;
  deleteManyUserTokens: AffectedRowsOutput;
  deleteManyWebNotification: AffectedRowsOutput;
  deleteOneCategory?: Maybe<Category>;
  deleteOneCategoryPageAd?: Maybe<CategoryPageAd>;
  deleteOneComment?: Maybe<Comment>;
  deleteOneCommentReaction?: Maybe<CommentReaction>;
  deleteOneHomePageAd?: Maybe<HomePageAd>;
  deleteOneLikesByUser?: Maybe<LikesByUser>;
  deleteOneMedia?: Maybe<Media>;
  deleteOneMediaCategory?: Maybe<MediaCategory>;
  deleteOneNotification?: Maybe<Notification>;
  deleteOnePost?: Maybe<Post>;
  deleteOnePostPageAd?: Maybe<PostPageAd>;
  deleteOneRss?: Maybe<Rss>;
  deleteOneSiteinfo?: Maybe<Siteinfo>;
  deleteOneSubCategory?: Maybe<SubCategory>;
  deleteOneSubCategoryPageAd?: Maybe<SubCategoryPageAd>;
  deleteOneSubscriber?: Maybe<Subscriber>;
  deleteOneTag?: Maybe<Tag>;
  deleteOneUser?: Maybe<User>;
  deleteOneUserPermissions?: Maybe<UserPermissions>;
  deleteOneUserSocilaLinks?: Maybe<UserSocilaLinks>;
  deleteOneUserTokens?: Maybe<UserTokens>;
  deleteOneWebNotification?: Maybe<WebNotification>;
  forgetPassword?: Maybe<DefaultResponsce>;
  likeOnComment?: Maybe<DefaultResponsce>;
  likeOnPost?: Maybe<DefaultResponsce>;
  login?: Maybe<LoginResponsce>;
  logout?: Maybe<DefaultResponsce>;
  passwordResetTokenVerify?: Maybe<DefaultResponsce>;
  refreshToken?: Maybe<RefreshTokenResponsce>;
  refreshTokenAdmin?: Maybe<Scalars['String']['output']>;
  register?: Maybe<DefaultResponsce>;
  removeLikeFromComment?: Maybe<DefaultResponsce>;
  removeLikeFromPost?: Maybe<DefaultResponsce>;
  resetPassByVerficationLink?: Maybe<DefaultResponsce>;
  revalidatePage?: Maybe<DefaultResponsce>;
  updateManyCategory: AffectedRowsOutput;
  updateManyCategoryPageAd: AffectedRowsOutput;
  updateManyComment: AffectedRowsOutput;
  updateManyCommentReaction: AffectedRowsOutput;
  updateManyHomePageAd: AffectedRowsOutput;
  updateManyLikesByUser: AffectedRowsOutput;
  updateManyMedia: AffectedRowsOutput;
  updateManyMediaCategory: AffectedRowsOutput;
  updateManyNotification: AffectedRowsOutput;
  updateManyPost: AffectedRowsOutput;
  updateManyPostPageAd: AffectedRowsOutput;
  updateManyRss: AffectedRowsOutput;
  updateManySiteinfo: AffectedRowsOutput;
  updateManySubCategory: AffectedRowsOutput;
  updateManySubCategoryPageAd: AffectedRowsOutput;
  updateManySubscriber: AffectedRowsOutput;
  updateManyTag: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyUserPermissions: AffectedRowsOutput;
  updateManyUserSocilaLinks: AffectedRowsOutput;
  updateManyUserTokens: AffectedRowsOutput;
  updateManyWebNotification: AffectedRowsOutput;
  updateOneCategory?: Maybe<Category>;
  updateOneCategoryPageAd?: Maybe<CategoryPageAd>;
  updateOneComment?: Maybe<Comment>;
  updateOneCommentReaction?: Maybe<CommentReaction>;
  updateOneHomePageAd?: Maybe<HomePageAd>;
  updateOneLikesByUser?: Maybe<LikesByUser>;
  updateOneMedia?: Maybe<Media>;
  updateOneMediaCategory?: Maybe<MediaCategory>;
  updateOneNotification?: Maybe<Notification>;
  updateOnePost?: Maybe<Post>;
  updateOnePostPageAd?: Maybe<PostPageAd>;
  updateOneRss?: Maybe<Rss>;
  updateOneSiteinfo?: Maybe<Siteinfo>;
  updateOneSubCategory?: Maybe<SubCategory>;
  updateOneSubCategoryPageAd?: Maybe<SubCategoryPageAd>;
  updateOneSubscriber?: Maybe<Subscriber>;
  updateOneTag?: Maybe<Tag>;
  updateOneUser?: Maybe<User>;
  updateOneUserPermissions?: Maybe<UserPermissions>;
  updateOneUserSocilaLinks?: Maybe<UserSocilaLinks>;
  updateOneUserTokens?: Maybe<UserTokens>;
  updateOneWebNotification?: Maybe<WebNotification>;
  updateProfile?: Maybe<DefaultResponsce>;
  uploadFile?: Maybe<Media>;
  upsertOneCategory: Category;
  upsertOneCategoryPageAd: CategoryPageAd;
  upsertOneComment: Comment;
  upsertOneCommentReaction: CommentReaction;
  upsertOneHomePageAd: HomePageAd;
  upsertOneLikesByUser: LikesByUser;
  upsertOneMedia: Media;
  upsertOneMediaCategory: MediaCategory;
  upsertOneNotification: Notification;
  upsertOnePost: Post;
  upsertOnePostPageAd: PostPageAd;
  upsertOneRss: Rss;
  upsertOneSiteinfo: Siteinfo;
  upsertOneSubCategory: SubCategory;
  upsertOneSubCategoryPageAd: SubCategoryPageAd;
  upsertOneSubscriber: Subscriber;
  upsertOneTag: Tag;
  upsertOneUser: User;
  upsertOneUserPermissions: UserPermissions;
  upsertOneUserSocilaLinks: UserSocilaLinks;
  upsertOneUserTokens: UserTokens;
  upsertOneWebNotification: WebNotification;
  verifyEmail?: Maybe<DefaultResponsce>;
};


export type MutationAdminLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationContactUsArgs = {
  input: ContactUsInput;
};


export type MutationCreateManyCategoryArgs = {
  data: Array<CategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCategoryPageAdArgs = {
  data: Array<CategoryPageAdCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCommentArgs = {
  data: Array<CommentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCommentReactionArgs = {
  data: Array<CommentReactionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyHomePageAdArgs = {
  data: Array<HomePageAdCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyLikesByUserArgs = {
  data: Array<LikesByUserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyMediaArgs = {
  data: Array<MediaCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyMediaCategoryArgs = {
  data: Array<MediaCategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyNotificationArgs = {
  data: Array<NotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyPostArgs = {
  data: Array<PostCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyPostPageAdArgs = {
  data: Array<PostPageAdCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyRssArgs = {
  data: Array<RssCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySiteinfoArgs = {
  data: Array<SiteinfoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySubCategoryArgs = {
  data: Array<SubCategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySubCategoryPageAdArgs = {
  data: Array<SubCategoryPageAdCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySubscriberArgs = {
  data: Array<SubscriberCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyTagArgs = {
  data: Array<TagCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserPermissionsArgs = {
  data: Array<UserPermissionsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserSocilaLinksArgs = {
  data: Array<UserSocilaLinksCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserTokensArgs = {
  data: Array<UserTokensCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyWebNotificationArgs = {
  data: Array<WebNotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateOneCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationCreateOneCategoryPageAdArgs = {
  data: CategoryPageAdCreateInput;
};


export type MutationCreateOneCommentArgs = {
  data: CommentCreateInput;
};


export type MutationCreateOneCommentReactionArgs = {
  data: CommentReactionCreateInput;
};


export type MutationCreateOneHomePageAdArgs = {
  data: HomePageAdCreateInput;
};


export type MutationCreateOneLikesByUserArgs = {
  data: LikesByUserCreateInput;
};


export type MutationCreateOneMediaArgs = {
  data: MediaCreateInput;
};


export type MutationCreateOneMediaCategoryArgs = {
  data: MediaCategoryCreateInput;
};


export type MutationCreateOneNotificationArgs = {
  data: NotificationCreateInput;
};


export type MutationCreateOnePostArgs = {
  data: PostCreateInput;
};


export type MutationCreateOnePostPageAdArgs = {
  data: PostPageAdCreateInput;
};


export type MutationCreateOneRssArgs = {
  data: RssCreateInput;
};


export type MutationCreateOneSiteinfoArgs = {
  data: SiteinfoCreateInput;
};


export type MutationCreateOneSubCategoryArgs = {
  data: SubCategoryCreateInput;
};


export type MutationCreateOneSubCategoryPageAdArgs = {
  data: SubCategoryPageAdCreateInput;
};


export type MutationCreateOneSubscriberArgs = {
  data: SubscriberCreateInput;
};


export type MutationCreateOneTagArgs = {
  data: TagCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateOneUserPermissionsArgs = {
  data: UserPermissionsCreateInput;
};


export type MutationCreateOneUserSocilaLinksArgs = {
  data: UserSocilaLinksCreateInput;
};


export type MutationCreateOneUserTokensArgs = {
  data: UserTokensCreateInput;
};


export type MutationCreateOneWebNotificationArgs = {
  data: WebNotificationCreateInput;
};


export type MutationCreatePostByContributorArgs = {
  input: ContributorPostCreateInput;
  taxonomyAndMedia: TaxonomyAndMedia;
};


export type MutationDeleteManyCategoryArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationDeleteManyCategoryPageAdArgs = {
  where?: InputMaybe<CategoryPageAdWhereInput>;
};


export type MutationDeleteManyCommentArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type MutationDeleteManyCommentReactionArgs = {
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type MutationDeleteManyHomePageAdArgs = {
  where?: InputMaybe<HomePageAdWhereInput>;
};


export type MutationDeleteManyLikesByUserArgs = {
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type MutationDeleteManyMediaArgs = {
  where?: InputMaybe<MediaWhereInput>;
};


export type MutationDeleteManyMediaCategoryArgs = {
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type MutationDeleteManyNotificationArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type MutationDeleteManyPostArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type MutationDeleteManyPostPageAdArgs = {
  where?: InputMaybe<PostPageAdWhereInput>;
};


export type MutationDeleteManyRssArgs = {
  where?: InputMaybe<RssWhereInput>;
};


export type MutationDeleteManySiteinfoArgs = {
  where?: InputMaybe<SiteinfoWhereInput>;
};


export type MutationDeleteManySubCategoryArgs = {
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type MutationDeleteManySubCategoryPageAdArgs = {
  where?: InputMaybe<SubCategoryPageAdWhereInput>;
};


export type MutationDeleteManySubscriberArgs = {
  where?: InputMaybe<SubscriberWhereInput>;
};


export type MutationDeleteManyTagArgs = {
  where?: InputMaybe<TagWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteManyUserPermissionsArgs = {
  where?: InputMaybe<UserPermissionsWhereInput>;
};


export type MutationDeleteManyUserSocilaLinksArgs = {
  where?: InputMaybe<UserSocilaLinksWhereInput>;
};


export type MutationDeleteManyUserTokensArgs = {
  where?: InputMaybe<UserTokensWhereInput>;
};


export type MutationDeleteManyWebNotificationArgs = {
  where?: InputMaybe<WebNotificationWhereInput>;
};


export type MutationDeleteOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type MutationDeleteOneCategoryPageAdArgs = {
  where: CategoryPageAdWhereUniqueInput;
};


export type MutationDeleteOneCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationDeleteOneCommentReactionArgs = {
  where: CommentReactionWhereUniqueInput;
};


export type MutationDeleteOneHomePageAdArgs = {
  where: HomePageAdWhereUniqueInput;
};


export type MutationDeleteOneLikesByUserArgs = {
  where: LikesByUserWhereUniqueInput;
};


export type MutationDeleteOneMediaArgs = {
  where: MediaWhereUniqueInput;
};


export type MutationDeleteOneMediaCategoryArgs = {
  where: MediaCategoryWhereUniqueInput;
};


export type MutationDeleteOneNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type MutationDeleteOnePostArgs = {
  where: PostWhereUniqueInput;
};


export type MutationDeleteOnePostPageAdArgs = {
  where: PostPageAdWhereUniqueInput;
};


export type MutationDeleteOneRssArgs = {
  where: RssWhereUniqueInput;
};


export type MutationDeleteOneSiteinfoArgs = {
  where: SiteinfoWhereUniqueInput;
};


export type MutationDeleteOneSubCategoryArgs = {
  where: SubCategoryWhereUniqueInput;
};


export type MutationDeleteOneSubCategoryPageAdArgs = {
  where: SubCategoryPageAdWhereUniqueInput;
};


export type MutationDeleteOneSubscriberArgs = {
  where: SubscriberWhereUniqueInput;
};


export type MutationDeleteOneTagArgs = {
  where: TagWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteOneUserPermissionsArgs = {
  where: UserPermissionsWhereUniqueInput;
};


export type MutationDeleteOneUserSocilaLinksArgs = {
  where: UserSocilaLinksWhereUniqueInput;
};


export type MutationDeleteOneUserTokensArgs = {
  where: UserTokensWhereUniqueInput;
};


export type MutationDeleteOneWebNotificationArgs = {
  where: WebNotificationWhereUniqueInput;
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationLikeOnCommentArgs = {
  id: Scalars['String']['input'];
};


export type MutationLikeOnPostArgs = {
  id: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationPasswordResetTokenVerifyArgs = {
  token: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  input: CreateOneUserArgsCustom;
};


export type MutationRemoveLikeFromCommentArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveLikeFromPostArgs = {
  id: Scalars['String']['input'];
};


export type MutationResetPassByVerficationLinkArgs = {
  input: ReesetPassByLinkInput;
};


export type MutationRevalidatePageArgs = {
  path: Scalars['String']['input'];
};


export type MutationUpdateManyCategoryArgs = {
  data: CategoryUpdateManyMutationInput;
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationUpdateManyCategoryPageAdArgs = {
  data: CategoryPageAdUpdateManyMutationInput;
  where?: InputMaybe<CategoryPageAdWhereInput>;
};


export type MutationUpdateManyCommentArgs = {
  data: CommentUpdateManyMutationInput;
  where?: InputMaybe<CommentWhereInput>;
};


export type MutationUpdateManyCommentReactionArgs = {
  data: CommentReactionUpdateManyMutationInput;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type MutationUpdateManyHomePageAdArgs = {
  data: HomePageAdUpdateManyMutationInput;
  where?: InputMaybe<HomePageAdWhereInput>;
};


export type MutationUpdateManyLikesByUserArgs = {
  data: LikesByUserUpdateManyMutationInput;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type MutationUpdateManyMediaArgs = {
  data: MediaUpdateManyMutationInput;
  where?: InputMaybe<MediaWhereInput>;
};


export type MutationUpdateManyMediaCategoryArgs = {
  data: MediaCategoryUpdateManyMutationInput;
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type MutationUpdateManyNotificationArgs = {
  data: NotificationUpdateManyMutationInput;
  where?: InputMaybe<NotificationWhereInput>;
};


export type MutationUpdateManyPostArgs = {
  data: PostUpdateManyMutationInput;
  where?: InputMaybe<PostWhereInput>;
};


export type MutationUpdateManyPostPageAdArgs = {
  data: PostPageAdUpdateManyMutationInput;
  where?: InputMaybe<PostPageAdWhereInput>;
};


export type MutationUpdateManyRssArgs = {
  data: RssUpdateManyMutationInput;
  where?: InputMaybe<RssWhereInput>;
};


export type MutationUpdateManySiteinfoArgs = {
  data: SiteinfoUpdateManyMutationInput;
  where?: InputMaybe<SiteinfoWhereInput>;
};


export type MutationUpdateManySubCategoryArgs = {
  data: SubCategoryUpdateManyMutationInput;
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type MutationUpdateManySubCategoryPageAdArgs = {
  data: SubCategoryPageAdUpdateManyMutationInput;
  where?: InputMaybe<SubCategoryPageAdWhereInput>;
};


export type MutationUpdateManySubscriberArgs = {
  data: SubscriberUpdateManyMutationInput;
  where?: InputMaybe<SubscriberWhereInput>;
};


export type MutationUpdateManyTagArgs = {
  data: TagUpdateManyMutationInput;
  where?: InputMaybe<TagWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateManyUserPermissionsArgs = {
  data: UserPermissionsUpdateManyMutationInput;
  where?: InputMaybe<UserPermissionsWhereInput>;
};


export type MutationUpdateManyUserSocilaLinksArgs = {
  data: UserSocilaLinksUpdateManyMutationInput;
  where?: InputMaybe<UserSocilaLinksWhereInput>;
};


export type MutationUpdateManyUserTokensArgs = {
  data: UserTokensUpdateManyMutationInput;
  where?: InputMaybe<UserTokensWhereInput>;
};


export type MutationUpdateManyWebNotificationArgs = {
  data: WebNotificationUpdateManyMutationInput;
  where?: InputMaybe<WebNotificationWhereInput>;
};


export type MutationUpdateOneCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpdateOneCategoryPageAdArgs = {
  data: CategoryPageAdUpdateInput;
  where: CategoryPageAdWhereUniqueInput;
};


export type MutationUpdateOneCommentArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateOneCommentReactionArgs = {
  data: CommentReactionUpdateInput;
  where: CommentReactionWhereUniqueInput;
};


export type MutationUpdateOneHomePageAdArgs = {
  data: HomePageAdUpdateInput;
  where: HomePageAdWhereUniqueInput;
};


export type MutationUpdateOneLikesByUserArgs = {
  data: LikesByUserUpdateInput;
  where: LikesByUserWhereUniqueInput;
};


export type MutationUpdateOneMediaArgs = {
  data: MediaUpdateInput;
  where: MediaWhereUniqueInput;
};


export type MutationUpdateOneMediaCategoryArgs = {
  data: MediaCategoryUpdateInput;
  where: MediaCategoryWhereUniqueInput;
};


export type MutationUpdateOneNotificationArgs = {
  data: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};


export type MutationUpdateOnePostArgs = {
  data: PostUpdateInput;
  where: PostWhereUniqueInput;
};


export type MutationUpdateOnePostPageAdArgs = {
  data: PostPageAdUpdateInput;
  where: PostPageAdWhereUniqueInput;
};


export type MutationUpdateOneRssArgs = {
  data: RssUpdateInput;
  where: RssWhereUniqueInput;
};


export type MutationUpdateOneSiteinfoArgs = {
  data: SiteinfoUpdateInput;
  where: SiteinfoWhereUniqueInput;
};


export type MutationUpdateOneSubCategoryArgs = {
  data: SubCategoryUpdateInput;
  where: SubCategoryWhereUniqueInput;
};


export type MutationUpdateOneSubCategoryPageAdArgs = {
  data: SubCategoryPageAdUpdateInput;
  where: SubCategoryPageAdWhereUniqueInput;
};


export type MutationUpdateOneSubscriberArgs = {
  data: SubscriberUpdateInput;
  where: SubscriberWhereUniqueInput;
};


export type MutationUpdateOneTagArgs = {
  data: TagUpdateInput;
  where: TagWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateOneUserPermissionsArgs = {
  data: UserPermissionsUpdateInput;
  where: UserPermissionsWhereUniqueInput;
};


export type MutationUpdateOneUserSocilaLinksArgs = {
  data: UserSocilaLinksUpdateInput;
  where: UserSocilaLinksWhereUniqueInput;
};


export type MutationUpdateOneUserTokensArgs = {
  data: UserTokensUpdateInput;
  where: UserTokensWhereUniqueInput;
};


export type MutationUpdateOneWebNotificationArgs = {
  data: WebNotificationUpdateInput;
  where: WebNotificationWhereUniqueInput;
};


export type MutationUpdateProfileArgs = {
  input: ProfileUpdateInput;
  security: CustomTypeForUserUpdate;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
  mediaCatIds?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};


export type MutationUpsertOneCategoryArgs = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpsertOneCategoryPageAdArgs = {
  create: CategoryPageAdCreateInput;
  update: CategoryPageAdUpdateInput;
  where: CategoryPageAdWhereUniqueInput;
};


export type MutationUpsertOneCommentArgs = {
  create: CommentCreateInput;
  update: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpsertOneCommentReactionArgs = {
  create: CommentReactionCreateInput;
  update: CommentReactionUpdateInput;
  where: CommentReactionWhereUniqueInput;
};


export type MutationUpsertOneHomePageAdArgs = {
  create: HomePageAdCreateInput;
  update: HomePageAdUpdateInput;
  where: HomePageAdWhereUniqueInput;
};


export type MutationUpsertOneLikesByUserArgs = {
  create: LikesByUserCreateInput;
  update: LikesByUserUpdateInput;
  where: LikesByUserWhereUniqueInput;
};


export type MutationUpsertOneMediaArgs = {
  create: MediaCreateInput;
  update: MediaUpdateInput;
  where: MediaWhereUniqueInput;
};


export type MutationUpsertOneMediaCategoryArgs = {
  create: MediaCategoryCreateInput;
  update: MediaCategoryUpdateInput;
  where: MediaCategoryWhereUniqueInput;
};


export type MutationUpsertOneNotificationArgs = {
  create: NotificationCreateInput;
  update: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};


export type MutationUpsertOnePostArgs = {
  create: PostCreateInput;
  update: PostUpdateInput;
  where: PostWhereUniqueInput;
};


export type MutationUpsertOnePostPageAdArgs = {
  create: PostPageAdCreateInput;
  update: PostPageAdUpdateInput;
  where: PostPageAdWhereUniqueInput;
};


export type MutationUpsertOneRssArgs = {
  create: RssCreateInput;
  update: RssUpdateInput;
  where: RssWhereUniqueInput;
};


export type MutationUpsertOneSiteinfoArgs = {
  create: SiteinfoCreateInput;
  update: SiteinfoUpdateInput;
  where: SiteinfoWhereUniqueInput;
};


export type MutationUpsertOneSubCategoryArgs = {
  create: SubCategoryCreateInput;
  update: SubCategoryUpdateInput;
  where: SubCategoryWhereUniqueInput;
};


export type MutationUpsertOneSubCategoryPageAdArgs = {
  create: SubCategoryPageAdCreateInput;
  update: SubCategoryPageAdUpdateInput;
  where: SubCategoryPageAdWhereUniqueInput;
};


export type MutationUpsertOneSubscriberArgs = {
  create: SubscriberCreateInput;
  update: SubscriberUpdateInput;
  where: SubscriberWhereUniqueInput;
};


export type MutationUpsertOneTagArgs = {
  create: TagCreateInput;
  update: TagUpdateInput;
  where: TagWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneUserPermissionsArgs = {
  create: UserPermissionsCreateInput;
  update: UserPermissionsUpdateInput;
  where: UserPermissionsWhereUniqueInput;
};


export type MutationUpsertOneUserSocilaLinksArgs = {
  create: UserSocilaLinksCreateInput;
  update: UserSocilaLinksUpdateInput;
  where: UserSocilaLinksWhereUniqueInput;
};


export type MutationUpsertOneUserTokensArgs = {
  create: UserTokensCreateInput;
  update: UserTokensUpdateInput;
  where: UserTokensWhereUniqueInput;
};


export type MutationUpsertOneWebNotificationArgs = {
  create: WebNotificationCreateInput;
  update: WebNotificationUpdateInput;
  where: WebNotificationWhereUniqueInput;
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String']['input'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumCategorySectionTypeFilter = {
  equals?: InputMaybe<CategorySectionType>;
  in?: InputMaybe<Array<CategorySectionType>>;
  not?: InputMaybe<NestedEnumCategorySectionTypeFilter>;
  notIn?: InputMaybe<Array<CategorySectionType>>;
};

export type NestedEnumCategorySectionTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCategorySectionTypeFilter>;
  _min?: InputMaybe<NestedEnumCategorySectionTypeFilter>;
  equals?: InputMaybe<CategorySectionType>;
  in?: InputMaybe<Array<CategorySectionType>>;
  not?: InputMaybe<NestedEnumCategorySectionTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CategorySectionType>>;
};

export type NestedEnumNotificationStatusFilter = {
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type NestedEnumNotificationStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationStatusFilter>;
  _min?: InputMaybe<NestedEnumNotificationStatusFilter>;
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumNotificationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNotificationTypeFilter>;
  _min?: InputMaybe<NestedEnumNotificationTypeFilter>;
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumPostStatusFilter = {
  equals?: InputMaybe<PostStatus>;
  in?: InputMaybe<Array<PostStatus>>;
  not?: InputMaybe<NestedEnumPostStatusFilter>;
  notIn?: InputMaybe<Array<PostStatus>>;
};

export type NestedEnumPostStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPostStatusFilter>;
  _min?: InputMaybe<NestedEnumPostStatusFilter>;
  equals?: InputMaybe<PostStatus>;
  in?: InputMaybe<Array<PostStatus>>;
  not?: InputMaybe<NestedEnumPostStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PostStatus>>;
};

export type NestedEnumUserAccountStatusFilter = {
  equals?: InputMaybe<UserAccountStatus>;
  in?: InputMaybe<Array<UserAccountStatus>>;
  not?: InputMaybe<NestedEnumUserAccountStatusFilter>;
  notIn?: InputMaybe<Array<UserAccountStatus>>;
};

export type NestedEnumUserAccountStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserAccountStatusFilter>;
  _min?: InputMaybe<NestedEnumUserAccountStatusFilter>;
  equals?: InputMaybe<UserAccountStatus>;
  in?: InputMaybe<Array<UserAccountStatus>>;
  not?: InputMaybe<NestedEnumUserAccountStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserAccountStatus>>;
};

export type NestedEnumUserRoleFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type NestedEnumUserRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserRoleFilter>;
  _min?: InputMaybe<NestedEnumUserRoleFilter>;
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdFor?: Maybe<User>;
  createdForId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: NotificationStatus;
  text: Scalars['String']['output'];
  type: NotificationType;
  url: Scalars['String']['output'];
};

export type NotificationCountAggregate = {
  __typename?: 'NotificationCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdById: Scalars['Int']['output'];
  createdForId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  text: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type NotificationCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrder>;
  createdForId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type NotificationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedNotificationsInput>;
  createdFor?: InputMaybe<UserCreateNestedOneWithoutMyNotificationsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NotificationStatus>;
  text: Scalars['String']['input'];
  type: NotificationType;
  url: Scalars['String']['input'];
};

export type NotificationCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdForId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NotificationStatus>;
  text: Scalars['String']['input'];
  type: NotificationType;
  url: Scalars['String']['input'];
};

export type NotificationCreateManyCreatedByInputEnvelope = {
  data: Array<NotificationCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateManyCreatedForInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NotificationStatus>;
  text: Scalars['String']['input'];
  type: NotificationType;
  url: Scalars['String']['input'];
};

export type NotificationCreateManyCreatedForInputEnvelope = {
  data: Array<NotificationCreateManyCreatedForInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  createdForId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NotificationStatus>;
  text: Scalars['String']['input'];
  type: NotificationType;
  url: Scalars['String']['input'];
};

export type NotificationCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<NotificationCreateManyCreatedByInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutCreatedForInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutCreatedForInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutCreatedForInput>>;
  createMany?: InputMaybe<NotificationCreateManyCreatedForInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutCreatedByInput = {
  create: NotificationCreateWithoutCreatedByInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutCreatedForInput = {
  create: NotificationCreateWithoutCreatedForInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdFor?: InputMaybe<UserCreateNestedOneWithoutMyNotificationsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NotificationStatus>;
  text: Scalars['String']['input'];
  type: NotificationType;
  url: Scalars['String']['input'];
};

export type NotificationCreateWithoutCreatedForInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedNotificationsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NotificationStatus>;
  text: Scalars['String']['input'];
  type: NotificationType;
  url: Scalars['String']['input'];
};

export type NotificationGroupBy = {
  __typename?: 'NotificationGroupBy';
  _count?: Maybe<NotificationCountAggregate>;
  _max?: Maybe<NotificationMaxAggregate>;
  _min?: Maybe<NotificationMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['String']['output']>;
  createdForId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: NotificationStatus;
  text: Scalars['String']['output'];
  type: NotificationType;
  url: Scalars['String']['output'];
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationMaxAggregate = {
  __typename?: 'NotificationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdForId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<NotificationStatus>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NotificationType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type NotificationMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrder>;
  createdForId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type NotificationMinAggregate = {
  __typename?: 'NotificationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdForId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<NotificationStatus>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NotificationType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type NotificationMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrder>;
  createdForId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum NotificationOrderByRelevanceFieldEnum {
  CreatedById = 'createdById',
  CreatedForId = 'createdForId',
  Id = 'id',
  Text = 'text',
  Url = 'url'
}

export type NotificationOrderByRelevanceInput = {
  fields: Array<NotificationOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type NotificationOrderByWithAggregationInput = {
  _count?: InputMaybe<NotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<NotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<NotificationMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrderInput>;
  createdForId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type NotificationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<NotificationOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdById?: InputMaybe<SortOrderInput>;
  createdFor?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdForId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum NotificationScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  CreatedForId = 'createdForId',
  Id = 'id',
  Status = 'status',
  Text = 'text',
  Type = 'type',
  Url = 'url'
}

export type NotificationScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  createdForId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumNotificationStatusFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type NotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdById?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdForId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumNotificationStatusWithAggregatesFilter>;
  text?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumNotificationTypeWithAggregatesFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
};

export enum NotificationStatus {
  Read = 'read',
  Unread = 'unread'
}

export enum NotificationType {
  CommentReaction = 'commentReaction',
  PostComment = 'postComment',
  PostReaction = 'postReaction',
  ReplyComment = 'replyComment'
}

export type NotificationUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedNotificationsNestedInput>;
  createdFor?: InputMaybe<UserUpdateOneWithoutMyNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithWhereWithoutCreatedByInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutCreatedForInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<NotificationCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type NotificationUpdateManyWithoutCreatedForNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutCreatedForInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutCreatedForInput>>;
  createMany?: InputMaybe<NotificationCreateManyCreatedForInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutCreatedForInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutCreatedForInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutCreatedForInput>>;
};

export type NotificationUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: NotificationUpdateWithoutCreatedByInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutCreatedForInput = {
  data: NotificationUpdateWithoutCreatedForInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdFor?: InputMaybe<UserUpdateOneWithoutMyNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpdateWithoutCreatedForInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: NotificationCreateWithoutCreatedByInput;
  update: NotificationUpdateWithoutCreatedByInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutCreatedForInput = {
  create: NotificationCreateWithoutCreatedForInput;
  update: NotificationUpdateWithoutCreatedForInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  createdFor?: InputMaybe<UserRelationFilter>;
  createdForId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumNotificationStatusFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Post = {
  __typename?: 'Post';
  _count?: Maybe<PostCount>;
  category?: Maybe<Category>;
  categoryId: Scalars['String']['output'];
  comments: Array<Comment>;
  contributorPost: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  customAd?: Maybe<PostPageAd>;
  description: Scalars['String']['output'];
  fromRss: Scalars['Boolean']['output'];
  haveVideo: Scalars['Boolean']['output'];
  hideAdsOnThisArtical: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Media>;
  imageId?: Maybe<Scalars['String']['output']>;
  includeOnRss: Scalars['Boolean']['output'];
  isBreaking?: Maybe<Scalars['Boolean']['output']>;
  isFetaured: Scalars['Boolean']['output'];
  isFetauredTop: Scalars['Boolean']['output'];
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isPopular: Scalars['Boolean']['output'];
  isRight: Scalars['Boolean']['output'];
  isSheduledForLater: Scalars['Boolean']['output'];
  isTopRight: Scalars['Boolean']['output'];
  isTrending: Scalars['Boolean']['output'];
  keyWords?: Maybe<Scalars['String']['output']>;
  likesByUser: Array<LikesByUser>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  pageDescription?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  postText: Scalars['String']['output'];
  postitle: Scalars['String']['output'];
  shedulePostTime?: Maybe<Scalars['DateTime']['output']>;
  sheduleTimezone?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  socialShare?: Maybe<Scalars['Boolean']['output']>;
  status: PostStatus;
  subHeading?: Maybe<Scalars['String']['output']>;
  subcategory?: Maybe<SubCategory>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
  tag: Array<Tag>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  webNotification: Scalars['Boolean']['output'];
};


export type PostCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type PostLikesByUserArgs = {
  cursor?: InputMaybe<LikesByUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikesByUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type PostTagArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};

export type PostCount = {
  __typename?: 'PostCount';
  comments: Scalars['Int']['output'];
  likesByUser: Scalars['Int']['output'];
  tag: Scalars['Int']['output'];
};


export type PostCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type PostCountLikesByUserArgs = {
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type PostCountTagArgs = {
  where?: InputMaybe<TagWhereInput>;
};

export type PostCountAggregate = {
  __typename?: 'PostCountAggregate';
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  contributorPost: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  fromRss: Scalars['Int']['output'];
  haveVideo: Scalars['Int']['output'];
  hideAdsOnThisArtical: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageId: Scalars['Int']['output'];
  includeOnRss: Scalars['Int']['output'];
  isBreaking: Scalars['Int']['output'];
  isFetaured: Scalars['Int']['output'];
  isFetauredTop: Scalars['Int']['output'];
  isLive: Scalars['Int']['output'];
  isPopular: Scalars['Int']['output'];
  isRight: Scalars['Int']['output'];
  isSheduledForLater: Scalars['Int']['output'];
  isTopRight: Scalars['Int']['output'];
  isTrending: Scalars['Int']['output'];
  keyWords: Scalars['Int']['output'];
  noIndex: Scalars['Int']['output'];
  pageDescription: Scalars['Int']['output'];
  pageTitle: Scalars['Int']['output'];
  postText: Scalars['Int']['output'];
  postitle: Scalars['Int']['output'];
  shedulePostTime: Scalars['Int']['output'];
  sheduleTimezone: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  socialShare: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  subHeading: Scalars['Int']['output'];
  subcategoryId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  video: Scalars['Int']['output'];
  webNotification: Scalars['Int']['output'];
};

export type PostCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contributorPost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  haveVideo?: InputMaybe<SortOrder>;
  hideAdsOnThisArtical?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  includeOnRss?: InputMaybe<SortOrder>;
  isBreaking?: InputMaybe<SortOrder>;
  isFetaured?: InputMaybe<SortOrder>;
  isFetauredTop?: InputMaybe<SortOrder>;
  isLive?: InputMaybe<SortOrder>;
  isPopular?: InputMaybe<SortOrder>;
  isRight?: InputMaybe<SortOrder>;
  isSheduledForLater?: InputMaybe<SortOrder>;
  isTopRight?: InputMaybe<SortOrder>;
  isTrending?: InputMaybe<SortOrder>;
  keyWords?: InputMaybe<SortOrder>;
  noIndex?: InputMaybe<SortOrder>;
  pageDescription?: InputMaybe<SortOrder>;
  pageTitle?: InputMaybe<SortOrder>;
  postText?: InputMaybe<SortOrder>;
  postitle?: InputMaybe<SortOrder>;
  shedulePostTime?: InputMaybe<SortOrder>;
  sheduleTimezone?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  socialShare?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subHeading?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
  webNotification?: InputMaybe<SortOrder>;
};

export type PostCreateInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManyCategoryInput = {
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManyCategoryInputEnvelope = {
  data: Array<PostCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManyImageInput = {
  categoryId: Scalars['String']['input'];
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManyImageInputEnvelope = {
  data: Array<PostCreateManyImageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManyInput = {
  categoryId: Scalars['String']['input'];
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManySubcategoryInput = {
  categoryId: Scalars['String']['input'];
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManySubcategoryInputEnvelope = {
  data: Array<PostCreateManySubcategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManyUserInput = {
  categoryId: Scalars['String']['input'];
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateManyUserInputEnvelope = {
  data: Array<PostCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<PostCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<PostCreateManyCategoryInputEnvelope>;
};

export type PostCreateNestedManyWithoutImageInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutImageInput>>;
  create?: InputMaybe<Array<PostCreateWithoutImageInput>>;
  createMany?: InputMaybe<PostCreateManyImageInputEnvelope>;
};

export type PostCreateNestedManyWithoutSubcategoryInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutSubcategoryInput>>;
  create?: InputMaybe<Array<PostCreateWithoutSubcategoryInput>>;
  createMany?: InputMaybe<PostCreateManySubcategoryInputEnvelope>;
};

export type PostCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<PostCreateWithoutTagInput>>;
};

export type PostCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PostCreateWithoutUserInput>>;
  createMany?: InputMaybe<PostCreateManyUserInputEnvelope>;
};

export type PostCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<PostCreateWithoutCommentsInput>;
};

export type PostCreateNestedOneWithoutCustomAdInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutCustomAdInput>;
  create?: InputMaybe<PostCreateWithoutCustomAdInput>;
};

export type PostCreateNestedOneWithoutLikesByUserInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutLikesByUserInput>;
  create?: InputMaybe<PostCreateWithoutLikesByUserInput>;
};

export type PostCreateOrConnectWithoutCategoryInput = {
  create: PostCreateWithoutCategoryInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutCommentsInput = {
  create: PostCreateWithoutCommentsInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutCustomAdInput = {
  create: PostCreateWithoutCustomAdInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutImageInput = {
  create: PostCreateWithoutImageInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutLikesByUserInput = {
  create: PostCreateWithoutLikesByUserInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutSubcategoryInput = {
  create: PostCreateWithoutSubcategoryInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutTagInput = {
  create: PostCreateWithoutTagInput;
  where: PostWhereUniqueInput;
};

export type PostCreateOrConnectWithoutUserInput = {
  create: PostCreateWithoutUserInput;
  where: PostWhereUniqueInput;
};

export type PostCreateWithoutCategoryInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateWithoutCommentsInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateWithoutCustomAdInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateWithoutImageInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateWithoutLikesByUserInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateWithoutSubcategoryInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateWithoutTagInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPostInput>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateWithoutUserInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutPostInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutPostInput>;
  contributorPost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<PostPageAdCreateNestedOneWithoutPostInput>;
  description: Scalars['String']['input'];
  fromRss?: InputMaybe<Scalars['Boolean']['input']>;
  haveVideo?: InputMaybe<Scalars['Boolean']['input']>;
  hideAdsOnThisArtical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<MediaCreateNestedOneWithoutPostsAsImageInput>;
  includeOnRss?: InputMaybe<Scalars['Boolean']['input']>;
  isBreaking?: InputMaybe<Scalars['Boolean']['input']>;
  isFetaured?: InputMaybe<Scalars['Boolean']['input']>;
  isFetauredTop?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isRight?: InputMaybe<Scalars['Boolean']['input']>;
  isSheduledForLater?: InputMaybe<Scalars['Boolean']['input']>;
  isTopRight?: InputMaybe<Scalars['Boolean']['input']>;
  isTrending?: InputMaybe<Scalars['Boolean']['input']>;
  keyWords?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutPostInput>;
  noIndex?: InputMaybe<Scalars['Boolean']['input']>;
  pageDescription?: InputMaybe<Scalars['String']['input']>;
  pageTitle?: InputMaybe<Scalars['String']['input']>;
  postText: Scalars['String']['input'];
  postitle: Scalars['String']['input'];
  shedulePostTime?: InputMaybe<Scalars['DateTime']['input']>;
  sheduleTimezone?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialShare?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PostStatus>;
  subHeading?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutPostInput>;
  tag?: InputMaybe<TagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostGroupBy = {
  __typename?: 'PostGroupBy';
  _count?: Maybe<PostCountAggregate>;
  _max?: Maybe<PostMaxAggregate>;
  _min?: Maybe<PostMinAggregate>;
  categoryId: Scalars['String']['output'];
  contributorPost: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  fromRss: Scalars['Boolean']['output'];
  haveVideo: Scalars['Boolean']['output'];
  hideAdsOnThisArtical: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  includeOnRss: Scalars['Boolean']['output'];
  isBreaking?: Maybe<Scalars['Boolean']['output']>;
  isFetaured: Scalars['Boolean']['output'];
  isFetauredTop: Scalars['Boolean']['output'];
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isPopular: Scalars['Boolean']['output'];
  isRight: Scalars['Boolean']['output'];
  isSheduledForLater: Scalars['Boolean']['output'];
  isTopRight: Scalars['Boolean']['output'];
  isTrending: Scalars['Boolean']['output'];
  keyWords?: Maybe<Scalars['String']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  pageDescription?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  postText: Scalars['String']['output'];
  postitle: Scalars['String']['output'];
  shedulePostTime?: Maybe<Scalars['DateTime']['output']>;
  sheduleTimezone?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  socialShare?: Maybe<Scalars['Boolean']['output']>;
  status: PostStatus;
  subHeading?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  webNotification: Scalars['Boolean']['output'];
};

export type PostListRelationFilter = {
  every?: InputMaybe<PostWhereInput>;
  none?: InputMaybe<PostWhereInput>;
  some?: InputMaybe<PostWhereInput>;
};

export type PostMaxAggregate = {
  __typename?: 'PostMaxAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  contributorPost?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fromRss?: Maybe<Scalars['Boolean']['output']>;
  haveVideo?: Maybe<Scalars['Boolean']['output']>;
  hideAdsOnThisArtical?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  includeOnRss?: Maybe<Scalars['Boolean']['output']>;
  isBreaking?: Maybe<Scalars['Boolean']['output']>;
  isFetaured?: Maybe<Scalars['Boolean']['output']>;
  isFetauredTop?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isPopular?: Maybe<Scalars['Boolean']['output']>;
  isRight?: Maybe<Scalars['Boolean']['output']>;
  isSheduledForLater?: Maybe<Scalars['Boolean']['output']>;
  isTopRight?: Maybe<Scalars['Boolean']['output']>;
  isTrending?: Maybe<Scalars['Boolean']['output']>;
  keyWords?: Maybe<Scalars['String']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  pageDescription?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  postText?: Maybe<Scalars['String']['output']>;
  postitle?: Maybe<Scalars['String']['output']>;
  shedulePostTime?: Maybe<Scalars['DateTime']['output']>;
  sheduleTimezone?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  socialShare?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<PostStatus>;
  subHeading?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  webNotification?: Maybe<Scalars['Boolean']['output']>;
};

export type PostMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contributorPost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  haveVideo?: InputMaybe<SortOrder>;
  hideAdsOnThisArtical?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  includeOnRss?: InputMaybe<SortOrder>;
  isBreaking?: InputMaybe<SortOrder>;
  isFetaured?: InputMaybe<SortOrder>;
  isFetauredTop?: InputMaybe<SortOrder>;
  isLive?: InputMaybe<SortOrder>;
  isPopular?: InputMaybe<SortOrder>;
  isRight?: InputMaybe<SortOrder>;
  isSheduledForLater?: InputMaybe<SortOrder>;
  isTopRight?: InputMaybe<SortOrder>;
  isTrending?: InputMaybe<SortOrder>;
  keyWords?: InputMaybe<SortOrder>;
  noIndex?: InputMaybe<SortOrder>;
  pageDescription?: InputMaybe<SortOrder>;
  pageTitle?: InputMaybe<SortOrder>;
  postText?: InputMaybe<SortOrder>;
  postitle?: InputMaybe<SortOrder>;
  shedulePostTime?: InputMaybe<SortOrder>;
  sheduleTimezone?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  socialShare?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subHeading?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
  webNotification?: InputMaybe<SortOrder>;
};

export type PostMinAggregate = {
  __typename?: 'PostMinAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  contributorPost?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fromRss?: Maybe<Scalars['Boolean']['output']>;
  haveVideo?: Maybe<Scalars['Boolean']['output']>;
  hideAdsOnThisArtical?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  includeOnRss?: Maybe<Scalars['Boolean']['output']>;
  isBreaking?: Maybe<Scalars['Boolean']['output']>;
  isFetaured?: Maybe<Scalars['Boolean']['output']>;
  isFetauredTop?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isPopular?: Maybe<Scalars['Boolean']['output']>;
  isRight?: Maybe<Scalars['Boolean']['output']>;
  isSheduledForLater?: Maybe<Scalars['Boolean']['output']>;
  isTopRight?: Maybe<Scalars['Boolean']['output']>;
  isTrending?: Maybe<Scalars['Boolean']['output']>;
  keyWords?: Maybe<Scalars['String']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  pageDescription?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  postText?: Maybe<Scalars['String']['output']>;
  postitle?: Maybe<Scalars['String']['output']>;
  shedulePostTime?: Maybe<Scalars['DateTime']['output']>;
  sheduleTimezone?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  socialShare?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<PostStatus>;
  subHeading?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  webNotification?: Maybe<Scalars['Boolean']['output']>;
};

export type PostMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contributorPost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  haveVideo?: InputMaybe<SortOrder>;
  hideAdsOnThisArtical?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  includeOnRss?: InputMaybe<SortOrder>;
  isBreaking?: InputMaybe<SortOrder>;
  isFetaured?: InputMaybe<SortOrder>;
  isFetauredTop?: InputMaybe<SortOrder>;
  isLive?: InputMaybe<SortOrder>;
  isPopular?: InputMaybe<SortOrder>;
  isRight?: InputMaybe<SortOrder>;
  isSheduledForLater?: InputMaybe<SortOrder>;
  isTopRight?: InputMaybe<SortOrder>;
  isTrending?: InputMaybe<SortOrder>;
  keyWords?: InputMaybe<SortOrder>;
  noIndex?: InputMaybe<SortOrder>;
  pageDescription?: InputMaybe<SortOrder>;
  pageTitle?: InputMaybe<SortOrder>;
  postText?: InputMaybe<SortOrder>;
  postitle?: InputMaybe<SortOrder>;
  shedulePostTime?: InputMaybe<SortOrder>;
  sheduleTimezone?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  socialShare?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subHeading?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrder>;
  webNotification?: InputMaybe<SortOrder>;
};

export type PostOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum PostOrderByRelevanceFieldEnum {
  CategoryId = 'categoryId',
  Description = 'description',
  Id = 'id',
  ImageId = 'imageId',
  KeyWords = 'keyWords',
  PageDescription = 'pageDescription',
  PageTitle = 'pageTitle',
  PostText = 'postText',
  Postitle = 'postitle',
  SheduleTimezone = 'sheduleTimezone',
  Slug = 'slug',
  SubHeading = 'subHeading',
  SubcategoryId = 'subcategoryId',
  UserId = 'userId',
  Video = 'video'
}

export type PostOrderByRelevanceInput = {
  fields: Array<PostOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type PostOrderByWithAggregationInput = {
  _count?: InputMaybe<PostCountOrderByAggregateInput>;
  _max?: InputMaybe<PostMaxOrderByAggregateInput>;
  _min?: InputMaybe<PostMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  contributorPost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  haveVideo?: InputMaybe<SortOrder>;
  hideAdsOnThisArtical?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrderInput>;
  includeOnRss?: InputMaybe<SortOrder>;
  isBreaking?: InputMaybe<SortOrderInput>;
  isFetaured?: InputMaybe<SortOrder>;
  isFetauredTop?: InputMaybe<SortOrder>;
  isLive?: InputMaybe<SortOrderInput>;
  isPopular?: InputMaybe<SortOrder>;
  isRight?: InputMaybe<SortOrder>;
  isSheduledForLater?: InputMaybe<SortOrder>;
  isTopRight?: InputMaybe<SortOrder>;
  isTrending?: InputMaybe<SortOrder>;
  keyWords?: InputMaybe<SortOrderInput>;
  noIndex?: InputMaybe<SortOrderInput>;
  pageDescription?: InputMaybe<SortOrderInput>;
  pageTitle?: InputMaybe<SortOrderInput>;
  postText?: InputMaybe<SortOrder>;
  postitle?: InputMaybe<SortOrder>;
  shedulePostTime?: InputMaybe<SortOrderInput>;
  sheduleTimezone?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  socialShare?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  subHeading?: InputMaybe<SortOrderInput>;
  subcategoryId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
  video?: InputMaybe<SortOrderInput>;
  webNotification?: InputMaybe<SortOrder>;
};

export type PostOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PostOrderByRelevanceInput>;
  category?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  categoryId?: InputMaybe<SortOrder>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  contributorPost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customAd?: InputMaybe<PostPageAdOrderByWithRelationAndSearchRelevanceInput>;
  description?: InputMaybe<SortOrder>;
  fromRss?: InputMaybe<SortOrder>;
  haveVideo?: InputMaybe<SortOrder>;
  hideAdsOnThisArtical?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  imageId?: InputMaybe<SortOrderInput>;
  includeOnRss?: InputMaybe<SortOrder>;
  isBreaking?: InputMaybe<SortOrderInput>;
  isFetaured?: InputMaybe<SortOrder>;
  isFetauredTop?: InputMaybe<SortOrder>;
  isLive?: InputMaybe<SortOrderInput>;
  isPopular?: InputMaybe<SortOrder>;
  isRight?: InputMaybe<SortOrder>;
  isSheduledForLater?: InputMaybe<SortOrder>;
  isTopRight?: InputMaybe<SortOrder>;
  isTrending?: InputMaybe<SortOrder>;
  keyWords?: InputMaybe<SortOrderInput>;
  likesByUser?: InputMaybe<LikesByUserOrderByRelationAggregateInput>;
  noIndex?: InputMaybe<SortOrderInput>;
  pageDescription?: InputMaybe<SortOrderInput>;
  pageTitle?: InputMaybe<SortOrderInput>;
  postText?: InputMaybe<SortOrder>;
  postitle?: InputMaybe<SortOrder>;
  shedulePostTime?: InputMaybe<SortOrderInput>;
  sheduleTimezone?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  socialShare?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  subHeading?: InputMaybe<SortOrderInput>;
  subcategory?: InputMaybe<SubCategoryOrderByWithRelationAndSearchRelevanceInput>;
  subcategoryId?: InputMaybe<SortOrderInput>;
  tag?: InputMaybe<TagOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrderInput>;
  video?: InputMaybe<SortOrderInput>;
  webNotification?: InputMaybe<SortOrder>;
};

export type PostPageAd = {
  __typename?: 'PostPageAd';
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  post?: Maybe<Post>;
  postId: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
};

export type PostPageAdCountAggregate = {
  __typename?: 'PostPageAdCountAggregate';
  _all: Scalars['Int']['output'];
  customHtml: Scalars['Int']['output'];
  html: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['Int']['output'];
  postId: Scalars['Int']['output'];
  redirectUrl: Scalars['Int']['output'];
};

export type PostPageAdCountOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type PostPageAdCreateInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedOneWithoutCustomAdInput>;
  redirectUrl: Scalars['String']['input'];
};

export type PostPageAdCreateManyInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  postId: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type PostPageAdCreateNestedOneWithoutPostInput = {
  connect?: InputMaybe<PostPageAdWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPageAdCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<PostPageAdCreateWithoutPostInput>;
};

export type PostPageAdCreateOrConnectWithoutPostInput = {
  create: PostPageAdCreateWithoutPostInput;
  where: PostPageAdWhereUniqueInput;
};

export type PostPageAdCreateWithoutPostInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type PostPageAdGroupBy = {
  __typename?: 'PostPageAdGroupBy';
  _count?: Maybe<PostPageAdCountAggregate>;
  _max?: Maybe<PostPageAdMaxAggregate>;
  _min?: Maybe<PostPageAdMinAggregate>;
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  postId: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
};

export type PostPageAdMaxAggregate = {
  __typename?: 'PostPageAdMaxAggregate';
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type PostPageAdMaxOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type PostPageAdMinAggregate = {
  __typename?: 'PostPageAdMinAggregate';
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type PostPageAdMinOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export enum PostPageAdOrderByRelevanceFieldEnum {
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  PostId = 'postId',
  RedirectUrl = 'redirectUrl'
}

export type PostPageAdOrderByRelevanceInput = {
  fields: Array<PostPageAdOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type PostPageAdOrderByWithAggregationInput = {
  _count?: InputMaybe<PostPageAdCountOrderByAggregateInput>;
  _max?: InputMaybe<PostPageAdMaxOrderByAggregateInput>;
  _min?: InputMaybe<PostPageAdMinOrderByAggregateInput>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type PostPageAdOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PostPageAdOrderByRelevanceInput>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByWithRelationAndSearchRelevanceInput>;
  postId?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
};

export type PostPageAdRelationFilter = {
  is?: InputMaybe<PostPageAdWhereInput>;
  isNot?: InputMaybe<PostPageAdWhereInput>;
};

export enum PostPageAdScalarFieldEnum {
  CustomHtml = 'customHtml',
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  PostId = 'postId',
  RedirectUrl = 'redirectUrl'
}

export type PostPageAdScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PostPageAdScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PostPageAdScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PostPageAdScalarWhereWithAggregatesInput>>;
  customHtml?: InputMaybe<BoolWithAggregatesFilter>;
  html?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  imageUrl?: InputMaybe<StringWithAggregatesFilter>;
  postId?: InputMaybe<StringWithAggregatesFilter>;
  redirectUrl?: InputMaybe<StringWithAggregatesFilter>;
};

export type PostPageAdUpdateInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateOneWithoutCustomAdNestedInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PostPageAdUpdateManyMutationInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PostPageAdUpdateOneWithoutPostNestedInput = {
  connect?: InputMaybe<PostPageAdWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostPageAdCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<PostPageAdCreateWithoutPostInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<PostPageAdUpdateWithoutPostInput>;
  upsert?: InputMaybe<PostPageAdUpsertWithoutPostInput>;
};

export type PostPageAdUpdateWithoutPostInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PostPageAdUpsertWithoutPostInput = {
  create: PostPageAdCreateWithoutPostInput;
  update: PostPageAdUpdateWithoutPostInput;
};

export type PostPageAdWhereInput = {
  AND?: InputMaybe<Array<PostPageAdWhereInput>>;
  NOT?: InputMaybe<Array<PostPageAdWhereInput>>;
  OR?: InputMaybe<Array<PostPageAdWhereInput>>;
  customHtml?: InputMaybe<BoolFilter>;
  html?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostRelationFilter>;
  postId?: InputMaybe<StringFilter>;
  redirectUrl?: InputMaybe<StringFilter>;
};

export type PostPageAdWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['String']['input']>;
};

export type PostRelationFilter = {
  is?: InputMaybe<PostWhereInput>;
  isNot?: InputMaybe<PostWhereInput>;
};

export enum PostScalarFieldEnum {
  CategoryId = 'categoryId',
  ContributorPost = 'contributorPost',
  CreatedAt = 'createdAt',
  Description = 'description',
  FromRss = 'fromRss',
  HaveVideo = 'haveVideo',
  HideAdsOnThisArtical = 'hideAdsOnThisArtical',
  Id = 'id',
  ImageId = 'imageId',
  IncludeOnRss = 'includeOnRss',
  IsBreaking = 'isBreaking',
  IsFetaured = 'isFetaured',
  IsFetauredTop = 'isFetauredTop',
  IsLive = 'isLive',
  IsPopular = 'isPopular',
  IsRight = 'isRight',
  IsSheduledForLater = 'isSheduledForLater',
  IsTopRight = 'isTopRight',
  IsTrending = 'isTrending',
  KeyWords = 'keyWords',
  NoIndex = 'noIndex',
  PageDescription = 'pageDescription',
  PageTitle = 'pageTitle',
  PostText = 'postText',
  Postitle = 'postitle',
  ShedulePostTime = 'shedulePostTime',
  SheduleTimezone = 'sheduleTimezone',
  Slug = 'slug',
  SocialShare = 'socialShare',
  Status = 'status',
  SubHeading = 'subHeading',
  SubcategoryId = 'subcategoryId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  Video = 'video',
  WebNotification = 'webNotification'
}

export type PostScalarWhereInput = {
  AND?: InputMaybe<Array<PostScalarWhereInput>>;
  NOT?: InputMaybe<Array<PostScalarWhereInput>>;
  OR?: InputMaybe<Array<PostScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  contributorPost?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  fromRss?: InputMaybe<BoolFilter>;
  haveVideo?: InputMaybe<BoolFilter>;
  hideAdsOnThisArtical?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  imageId?: InputMaybe<StringNullableFilter>;
  includeOnRss?: InputMaybe<BoolFilter>;
  isBreaking?: InputMaybe<BoolNullableFilter>;
  isFetaured?: InputMaybe<BoolFilter>;
  isFetauredTop?: InputMaybe<BoolFilter>;
  isLive?: InputMaybe<BoolNullableFilter>;
  isPopular?: InputMaybe<BoolFilter>;
  isRight?: InputMaybe<BoolFilter>;
  isSheduledForLater?: InputMaybe<BoolFilter>;
  isTopRight?: InputMaybe<BoolFilter>;
  isTrending?: InputMaybe<BoolFilter>;
  keyWords?: InputMaybe<StringNullableFilter>;
  noIndex?: InputMaybe<BoolNullableFilter>;
  pageDescription?: InputMaybe<StringNullableFilter>;
  pageTitle?: InputMaybe<StringNullableFilter>;
  postText?: InputMaybe<StringFilter>;
  postitle?: InputMaybe<StringFilter>;
  shedulePostTime?: InputMaybe<DateTimeNullableFilter>;
  sheduleTimezone?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  socialShare?: InputMaybe<BoolNullableFilter>;
  status?: InputMaybe<EnumPostStatusFilter>;
  subHeading?: InputMaybe<StringNullableFilter>;
  subcategoryId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  video?: InputMaybe<StringNullableFilter>;
  webNotification?: InputMaybe<BoolFilter>;
};

export type PostScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PostScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PostScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PostScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  contributorPost?: InputMaybe<BoolWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  fromRss?: InputMaybe<BoolWithAggregatesFilter>;
  haveVideo?: InputMaybe<BoolWithAggregatesFilter>;
  hideAdsOnThisArtical?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  imageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  includeOnRss?: InputMaybe<BoolWithAggregatesFilter>;
  isBreaking?: InputMaybe<BoolNullableWithAggregatesFilter>;
  isFetaured?: InputMaybe<BoolWithAggregatesFilter>;
  isFetauredTop?: InputMaybe<BoolWithAggregatesFilter>;
  isLive?: InputMaybe<BoolNullableWithAggregatesFilter>;
  isPopular?: InputMaybe<BoolWithAggregatesFilter>;
  isRight?: InputMaybe<BoolWithAggregatesFilter>;
  isSheduledForLater?: InputMaybe<BoolWithAggregatesFilter>;
  isTopRight?: InputMaybe<BoolWithAggregatesFilter>;
  isTrending?: InputMaybe<BoolWithAggregatesFilter>;
  keyWords?: InputMaybe<StringNullableWithAggregatesFilter>;
  noIndex?: InputMaybe<BoolNullableWithAggregatesFilter>;
  pageDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  pageTitle?: InputMaybe<StringNullableWithAggregatesFilter>;
  postText?: InputMaybe<StringWithAggregatesFilter>;
  postitle?: InputMaybe<StringWithAggregatesFilter>;
  shedulePostTime?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  sheduleTimezone?: InputMaybe<StringNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  socialShare?: InputMaybe<BoolNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumPostStatusWithAggregatesFilter>;
  subHeading?: InputMaybe<StringNullableWithAggregatesFilter>;
  subcategoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringNullableWithAggregatesFilter>;
  video?: InputMaybe<StringNullableWithAggregatesFilter>;
  webNotification?: InputMaybe<BoolWithAggregatesFilter>;
};

export enum PostStatus {
  Pending = 'pending',
  Private = 'private',
  Public = 'public'
}

export type PostUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateManyMutationInput = {
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateManyWithWhereWithoutCategoryInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutImageInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutSubcategoryInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutTagInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithWhereWithoutUserInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<PostCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<PostCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type PostUpdateManyWithoutImageNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutImageInput>>;
  create?: InputMaybe<Array<PostCreateWithoutImageInput>>;
  createMany?: InputMaybe<PostCreateManyImageInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutImageInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutImageInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutImageInput>>;
};

export type PostUpdateManyWithoutSubcategoryNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutSubcategoryInput>>;
  create?: InputMaybe<Array<PostCreateWithoutSubcategoryInput>>;
  createMany?: InputMaybe<PostCreateManySubcategoryInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutSubcategoryInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutSubcategoryInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutSubcategoryInput>>;
};

export type PostUpdateManyWithoutTagNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<PostCreateWithoutTagInput>>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutTagInput>>;
};

export type PostUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PostCreateWithoutUserInput>>;
  createMany?: InputMaybe<PostCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PostUpdateOneRequiredWithoutCommentsNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<PostCreateWithoutCommentsInput>;
  update?: InputMaybe<PostUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<PostUpsertWithoutCommentsInput>;
};

export type PostUpdateOneRequiredWithoutLikesByUserNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutLikesByUserInput>;
  create?: InputMaybe<PostCreateWithoutLikesByUserInput>;
  update?: InputMaybe<PostUpdateWithoutLikesByUserInput>;
  upsert?: InputMaybe<PostUpsertWithoutLikesByUserInput>;
};

export type PostUpdateOneWithoutCustomAdNestedInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostCreateOrConnectWithoutCustomAdInput>;
  create?: InputMaybe<PostCreateWithoutCustomAdInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<PostUpdateWithoutCustomAdInput>;
  upsert?: InputMaybe<PostUpsertWithoutCustomAdInput>;
};

export type PostUpdateWithWhereUniqueWithoutCategoryInput = {
  data: PostUpdateWithoutCategoryInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutImageInput = {
  data: PostUpdateWithoutImageInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutSubcategoryInput = {
  data: PostUpdateWithoutSubcategoryInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutTagInput = {
  data: PostUpdateWithoutTagInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithWhereUniqueWithoutUserInput = {
  data: PostUpdateWithoutUserInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateWithoutCategoryInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutCommentsInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutCustomAdInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutImageInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutLikesByUserInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutSubcategoryInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutTagInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPostNestedInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpdateWithoutUserInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutPostNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutPostNestedInput>;
  contributorPost?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<PostPageAdUpdateOneWithoutPostNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  fromRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  haveVideo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hideAdsOnThisArtical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<MediaUpdateOneWithoutPostsAsImageNestedInput>;
  includeOnRss?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBreaking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isFetaured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFetauredTop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isPopular?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSheduledForLater?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTopRight?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isTrending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyWords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutPostNestedInput>;
  noIndex?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  pageDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pageTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postText?: InputMaybe<StringFieldUpdateOperationsInput>;
  postitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  shedulePostTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sheduleTimezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  socialShare?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPostStatusFieldUpdateOperationsInput>;
  subHeading?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutPostNestedInput>;
  tag?: InputMaybe<TagUpdateManyWithoutPostsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  webNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PostUpsertWithWhereUniqueWithoutCategoryInput = {
  create: PostCreateWithoutCategoryInput;
  update: PostUpdateWithoutCategoryInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutImageInput = {
  create: PostCreateWithoutImageInput;
  update: PostUpdateWithoutImageInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutSubcategoryInput = {
  create: PostCreateWithoutSubcategoryInput;
  update: PostUpdateWithoutSubcategoryInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutTagInput = {
  create: PostCreateWithoutTagInput;
  update: PostUpdateWithoutTagInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutUserInput = {
  create: PostCreateWithoutUserInput;
  update: PostUpdateWithoutUserInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithoutCommentsInput = {
  create: PostCreateWithoutCommentsInput;
  update: PostUpdateWithoutCommentsInput;
};

export type PostUpsertWithoutCustomAdInput = {
  create: PostCreateWithoutCustomAdInput;
  update: PostUpdateWithoutCustomAdInput;
};

export type PostUpsertWithoutLikesByUserInput = {
  create: PostCreateWithoutLikesByUserInput;
  update: PostUpdateWithoutLikesByUserInput;
};

export type PostWhereInput = {
  AND?: InputMaybe<Array<PostWhereInput>>;
  NOT?: InputMaybe<Array<PostWhereInput>>;
  OR?: InputMaybe<Array<PostWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  contributorPost?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customAd?: InputMaybe<PostPageAdRelationFilter>;
  description?: InputMaybe<StringFilter>;
  fromRss?: InputMaybe<BoolFilter>;
  haveVideo?: InputMaybe<BoolFilter>;
  hideAdsOnThisArtical?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<MediaRelationFilter>;
  imageId?: InputMaybe<StringNullableFilter>;
  includeOnRss?: InputMaybe<BoolFilter>;
  isBreaking?: InputMaybe<BoolNullableFilter>;
  isFetaured?: InputMaybe<BoolFilter>;
  isFetauredTop?: InputMaybe<BoolFilter>;
  isLive?: InputMaybe<BoolNullableFilter>;
  isPopular?: InputMaybe<BoolFilter>;
  isRight?: InputMaybe<BoolFilter>;
  isSheduledForLater?: InputMaybe<BoolFilter>;
  isTopRight?: InputMaybe<BoolFilter>;
  isTrending?: InputMaybe<BoolFilter>;
  keyWords?: InputMaybe<StringNullableFilter>;
  likesByUser?: InputMaybe<LikesByUserListRelationFilter>;
  noIndex?: InputMaybe<BoolNullableFilter>;
  pageDescription?: InputMaybe<StringNullableFilter>;
  pageTitle?: InputMaybe<StringNullableFilter>;
  postText?: InputMaybe<StringFilter>;
  postitle?: InputMaybe<StringFilter>;
  shedulePostTime?: InputMaybe<DateTimeNullableFilter>;
  sheduleTimezone?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  socialShare?: InputMaybe<BoolNullableFilter>;
  status?: InputMaybe<EnumPostStatusFilter>;
  subHeading?: InputMaybe<StringNullableFilter>;
  subcategory?: InputMaybe<SubCategoryRelationFilter>;
  subcategoryId?: InputMaybe<StringNullableFilter>;
  tag?: InputMaybe<TagListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  video?: InputMaybe<StringNullableFilter>;
  webNotification?: InputMaybe<BoolFilter>;
};

export type PostWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateCategory: AggregateCategory;
  aggregateCategoryPageAd: AggregateCategoryPageAd;
  aggregateComment: AggregateComment;
  aggregateCommentReaction: AggregateCommentReaction;
  aggregateHomePageAd: AggregateHomePageAd;
  aggregateLikesByUser: AggregateLikesByUser;
  aggregateMedia: AggregateMedia;
  aggregateMediaCategory: AggregateMediaCategory;
  aggregateNotification: AggregateNotification;
  aggregatePost: AggregatePost;
  aggregatePostPageAd: AggregatePostPageAd;
  aggregateRss: AggregateRss;
  aggregateSiteinfo: AggregateSiteinfo;
  aggregateSubCategory: AggregateSubCategory;
  aggregateSubCategoryPageAd: AggregateSubCategoryPageAd;
  aggregateSubscriber: AggregateSubscriber;
  aggregateTag: AggregateTag;
  aggregateUser: AggregateUser;
  aggregateUserPermissions: AggregateUserPermissions;
  aggregateUserSocilaLinks: AggregateUserSocilaLinks;
  aggregateUserTokens: AggregateUserTokens;
  aggregateWebNotification: AggregateWebNotification;
  categories: Array<Category>;
  category?: Maybe<Category>;
  categoryPageAd?: Maybe<CategoryPageAd>;
  categoryPageAds: Array<CategoryPageAd>;
  comment?: Maybe<Comment>;
  commentReaction?: Maybe<CommentReaction>;
  commentReactions: Array<CommentReaction>;
  comments: Array<Comment>;
  findFirstCategory?: Maybe<Category>;
  findFirstCategoryOrThrow?: Maybe<Category>;
  findFirstCategoryPageAd?: Maybe<CategoryPageAd>;
  findFirstCategoryPageAdOrThrow?: Maybe<CategoryPageAd>;
  findFirstComment?: Maybe<Comment>;
  findFirstCommentOrThrow?: Maybe<Comment>;
  findFirstCommentReaction?: Maybe<CommentReaction>;
  findFirstCommentReactionOrThrow?: Maybe<CommentReaction>;
  findFirstHomePageAd?: Maybe<HomePageAd>;
  findFirstHomePageAdOrThrow?: Maybe<HomePageAd>;
  findFirstLikesByUser?: Maybe<LikesByUser>;
  findFirstLikesByUserOrThrow?: Maybe<LikesByUser>;
  findFirstMedia?: Maybe<Media>;
  findFirstMediaCategory?: Maybe<MediaCategory>;
  findFirstMediaCategoryOrThrow?: Maybe<MediaCategory>;
  findFirstMediaOrThrow?: Maybe<Media>;
  findFirstNotification?: Maybe<Notification>;
  findFirstNotificationOrThrow?: Maybe<Notification>;
  findFirstPost?: Maybe<Post>;
  findFirstPostOrThrow?: Maybe<Post>;
  findFirstPostPageAd?: Maybe<PostPageAd>;
  findFirstPostPageAdOrThrow?: Maybe<PostPageAd>;
  findFirstRss?: Maybe<Rss>;
  findFirstRssOrThrow?: Maybe<Rss>;
  findFirstSiteinfo?: Maybe<Siteinfo>;
  findFirstSiteinfoOrThrow?: Maybe<Siteinfo>;
  findFirstSubCategory?: Maybe<SubCategory>;
  findFirstSubCategoryOrThrow?: Maybe<SubCategory>;
  findFirstSubCategoryPageAd?: Maybe<SubCategoryPageAd>;
  findFirstSubCategoryPageAdOrThrow?: Maybe<SubCategoryPageAd>;
  findFirstSubscriber?: Maybe<Subscriber>;
  findFirstSubscriberOrThrow?: Maybe<Subscriber>;
  findFirstTag?: Maybe<Tag>;
  findFirstTagOrThrow?: Maybe<Tag>;
  findFirstUser?: Maybe<User>;
  findFirstUserOrThrow?: Maybe<User>;
  findFirstUserPermissions?: Maybe<UserPermissions>;
  findFirstUserPermissionsOrThrow?: Maybe<UserPermissions>;
  findFirstUserSocilaLinks?: Maybe<UserSocilaLinks>;
  findFirstUserSocilaLinksOrThrow?: Maybe<UserSocilaLinks>;
  findFirstUserTokens?: Maybe<UserTokens>;
  findFirstUserTokensOrThrow?: Maybe<UserTokens>;
  findFirstWebNotification?: Maybe<WebNotification>;
  findFirstWebNotificationOrThrow?: Maybe<WebNotification>;
  findManyMedia: Array<Media>;
  findManyUserPermissions: Array<UserPermissions>;
  findManyUserSocilaLinks: Array<UserSocilaLinks>;
  findManyUserTokens: Array<UserTokens>;
  findUniqueMedia?: Maybe<Media>;
  findUniqueMediaOrThrow?: Maybe<Media>;
  findUniqueUserPermissions?: Maybe<UserPermissions>;
  findUniqueUserPermissionsOrThrow?: Maybe<UserPermissions>;
  findUniqueUserSocilaLinks?: Maybe<UserSocilaLinks>;
  findUniqueUserSocilaLinksOrThrow?: Maybe<UserSocilaLinks>;
  findUniqueUserTokens?: Maybe<UserTokens>;
  findUniqueUserTokensOrThrow?: Maybe<UserTokens>;
  generateFacebookLongLivedToken?: Maybe<Scalars['String']['output']>;
  getCategory?: Maybe<Category>;
  getCategoryPageAd?: Maybe<CategoryPageAd>;
  getComment?: Maybe<Comment>;
  getCommentReaction?: Maybe<CommentReaction>;
  getHomePageAd?: Maybe<HomePageAd>;
  getLikesByUser?: Maybe<LikesByUser>;
  getMediaCategory?: Maybe<MediaCategory>;
  getNotification?: Maybe<Notification>;
  getPost?: Maybe<Post>;
  getPostPageAd?: Maybe<PostPageAd>;
  getRss?: Maybe<Rss>;
  getSiteinfo?: Maybe<Siteinfo>;
  getSubCategory?: Maybe<SubCategory>;
  getSubCategoryPageAd?: Maybe<SubCategoryPageAd>;
  getSubscriber?: Maybe<Subscriber>;
  getTag?: Maybe<Tag>;
  getUser?: Maybe<User>;
  getWebNotification?: Maybe<WebNotification>;
  groupByCategory: Array<CategoryGroupBy>;
  groupByCategoryPageAd: Array<CategoryPageAdGroupBy>;
  groupByComment: Array<CommentGroupBy>;
  groupByCommentReaction: Array<CommentReactionGroupBy>;
  groupByHomePageAd: Array<HomePageAdGroupBy>;
  groupByLikesByUser: Array<LikesByUserGroupBy>;
  groupByMedia: Array<MediaGroupBy>;
  groupByMediaCategory: Array<MediaCategoryGroupBy>;
  groupByNotification: Array<NotificationGroupBy>;
  groupByPost: Array<PostGroupBy>;
  groupByPostPageAd: Array<PostPageAdGroupBy>;
  groupByRss: Array<RssGroupBy>;
  groupBySiteinfo: Array<SiteinfoGroupBy>;
  groupBySubCategory: Array<SubCategoryGroupBy>;
  groupBySubCategoryPageAd: Array<SubCategoryPageAdGroupBy>;
  groupBySubscriber: Array<SubscriberGroupBy>;
  groupByTag: Array<TagGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByUserPermissions: Array<UserPermissionsGroupBy>;
  groupByUserSocilaLinks: Array<UserSocilaLinksGroupBy>;
  groupByUserTokens: Array<UserTokensGroupBy>;
  groupByWebNotification: Array<WebNotificationGroupBy>;
  healthCheck: Scalars['String']['output'];
  homePageAd?: Maybe<HomePageAd>;
  homePageAds: Array<HomePageAd>;
  likesByUser?: Maybe<LikesByUser>;
  likesByUsers: Array<LikesByUser>;
  me?: Maybe<UserForResponsce>;
  meAdmin?: Maybe<UserForResponsce>;
  mediaCategories: Array<MediaCategory>;
  mediaCategory?: Maybe<MediaCategory>;
  notification?: Maybe<Notification>;
  notificationUnread?: Maybe<Scalars['Int']['output']>;
  notifications: Array<Notification>;
  post?: Maybe<Post>;
  postPageAd?: Maybe<PostPageAd>;
  postPageAds: Array<PostPageAd>;
  posts: Array<Post>;
  reloadAllRss?: Maybe<DefaultResponsce>;
  rss?: Maybe<Rss>;
  rsses: Array<Rss>;
  siteinfo?: Maybe<Siteinfo>;
  siteinfos: Array<Siteinfo>;
  slugAvailable?: Maybe<Scalars['Boolean']['output']>;
  subCategories: Array<SubCategory>;
  subCategory?: Maybe<SubCategory>;
  subCategoryPageAd?: Maybe<SubCategoryPageAd>;
  subCategoryPageAds: Array<SubCategoryPageAd>;
  subscriber?: Maybe<Subscriber>;
  subscribers: Array<Subscriber>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  user?: Maybe<User>;
  userNotifications?: Maybe<Array<Notification>>;
  users: Array<User>;
  webNotification?: Maybe<WebNotification>;
  webNotifications: Array<WebNotification>;
};


export type QueryAggregateCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryAggregateCategoryPageAdArgs = {
  cursor?: InputMaybe<CategoryPageAdWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPageAdWhereInput>;
};


export type QueryAggregateCommentArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryAggregateCommentReactionArgs = {
  cursor?: InputMaybe<CommentReactionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type QueryAggregateHomePageAdArgs = {
  cursor?: InputMaybe<HomePageAdWhereUniqueInput>;
  orderBy?: InputMaybe<Array<HomePageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HomePageAdWhereInput>;
};


export type QueryAggregateLikesByUserArgs = {
  cursor?: InputMaybe<LikesByUserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type QueryAggregateMediaArgs = {
  cursor?: InputMaybe<MediaWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MediaOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaWhereInput>;
};


export type QueryAggregateMediaCategoryArgs = {
  cursor?: InputMaybe<MediaCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MediaCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type QueryAggregateNotificationArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryAggregatePostArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryAggregatePostPageAdArgs = {
  cursor?: InputMaybe<PostPageAdWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PostPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostPageAdWhereInput>;
};


export type QueryAggregateRssArgs = {
  cursor?: InputMaybe<RssWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RssOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RssWhereInput>;
};


export type QueryAggregateSiteinfoArgs = {
  cursor?: InputMaybe<SiteinfoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SiteinfoOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteinfoWhereInput>;
};


export type QueryAggregateSubCategoryArgs = {
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type QueryAggregateSubCategoryPageAdArgs = {
  cursor?: InputMaybe<SubCategoryPageAdWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SubCategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryPageAdWhereInput>;
};


export type QueryAggregateSubscriberArgs = {
  cursor?: InputMaybe<SubscriberWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SubscriberOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriberWhereInput>;
};


export type QueryAggregateTagArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateUserPermissionsArgs = {
  cursor?: InputMaybe<UserPermissionsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserPermissionsWhereInput>;
};


export type QueryAggregateUserSocilaLinksArgs = {
  cursor?: InputMaybe<UserSocilaLinksWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserSocilaLinksOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSocilaLinksWhereInput>;
};


export type QueryAggregateUserTokensArgs = {
  cursor?: InputMaybe<UserTokensWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserTokensOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTokensWhereInput>;
};


export type QueryAggregateWebNotificationArgs = {
  cursor?: InputMaybe<WebNotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WebNotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebNotificationWhereInput>;
};


export type QueryCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryCategoryPageAdArgs = {
  where: CategoryPageAdWhereUniqueInput;
};


export type QueryCategoryPageAdsArgs = {
  cursor?: InputMaybe<CategoryPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPageAdWhereInput>;
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentReactionArgs = {
  where: CommentReactionWhereUniqueInput;
};


export type QueryCommentReactionsArgs = {
  cursor?: InputMaybe<CommentReactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentReactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type QueryCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryFindFirstCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstCategoryOrThrowArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstCategoryPageAdArgs = {
  cursor?: InputMaybe<CategoryPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPageAdWhereInput>;
};


export type QueryFindFirstCategoryPageAdOrThrowArgs = {
  cursor?: InputMaybe<CategoryPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPageAdWhereInput>;
};


export type QueryFindFirstCommentArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryFindFirstCommentOrThrowArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryFindFirstCommentReactionArgs = {
  cursor?: InputMaybe<CommentReactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentReactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type QueryFindFirstCommentReactionOrThrowArgs = {
  cursor?: InputMaybe<CommentReactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentReactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type QueryFindFirstHomePageAdArgs = {
  cursor?: InputMaybe<HomePageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<HomePageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HomePageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HomePageAdWhereInput>;
};


export type QueryFindFirstHomePageAdOrThrowArgs = {
  cursor?: InputMaybe<HomePageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<HomePageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HomePageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HomePageAdWhereInput>;
};


export type QueryFindFirstLikesByUserArgs = {
  cursor?: InputMaybe<LikesByUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikesByUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type QueryFindFirstLikesByUserOrThrowArgs = {
  cursor?: InputMaybe<LikesByUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikesByUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type QueryFindFirstMediaArgs = {
  cursor?: InputMaybe<MediaWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaWhereInput>;
};


export type QueryFindFirstMediaCategoryArgs = {
  cursor?: InputMaybe<MediaCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type QueryFindFirstMediaCategoryOrThrowArgs = {
  cursor?: InputMaybe<MediaCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type QueryFindFirstMediaOrThrowArgs = {
  cursor?: InputMaybe<MediaWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaWhereInput>;
};


export type QueryFindFirstNotificationArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryFindFirstNotificationOrThrowArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryFindFirstPostArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryFindFirstPostOrThrowArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryFindFirstPostPageAdArgs = {
  cursor?: InputMaybe<PostPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostPageAdWhereInput>;
};


export type QueryFindFirstPostPageAdOrThrowArgs = {
  cursor?: InputMaybe<PostPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostPageAdWhereInput>;
};


export type QueryFindFirstRssArgs = {
  cursor?: InputMaybe<RssWhereUniqueInput>;
  distinct?: InputMaybe<Array<RssScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RssOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RssWhereInput>;
};


export type QueryFindFirstRssOrThrowArgs = {
  cursor?: InputMaybe<RssWhereUniqueInput>;
  distinct?: InputMaybe<Array<RssScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RssOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RssWhereInput>;
};


export type QueryFindFirstSiteinfoArgs = {
  cursor?: InputMaybe<SiteinfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SiteinfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SiteinfoOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteinfoWhereInput>;
};


export type QueryFindFirstSiteinfoOrThrowArgs = {
  cursor?: InputMaybe<SiteinfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SiteinfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SiteinfoOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteinfoWhereInput>;
};


export type QueryFindFirstSubCategoryArgs = {
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type QueryFindFirstSubCategoryOrThrowArgs = {
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type QueryFindFirstSubCategoryPageAdArgs = {
  cursor?: InputMaybe<SubCategoryPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryPageAdWhereInput>;
};


export type QueryFindFirstSubCategoryPageAdOrThrowArgs = {
  cursor?: InputMaybe<SubCategoryPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryPageAdWhereInput>;
};


export type QueryFindFirstSubscriberArgs = {
  cursor?: InputMaybe<SubscriberWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriberScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriberOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriberWhereInput>;
};


export type QueryFindFirstSubscriberOrThrowArgs = {
  cursor?: InputMaybe<SubscriberWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriberScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriberOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriberWhereInput>;
};


export type QueryFindFirstTagArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryFindFirstTagOrThrowArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserPermissionsArgs = {
  cursor?: InputMaybe<UserPermissionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPermissionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserPermissionsWhereInput>;
};


export type QueryFindFirstUserPermissionsOrThrowArgs = {
  cursor?: InputMaybe<UserPermissionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPermissionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserPermissionsWhereInput>;
};


export type QueryFindFirstUserSocilaLinksArgs = {
  cursor?: InputMaybe<UserSocilaLinksWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSocilaLinksScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSocilaLinksOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSocilaLinksWhereInput>;
};


export type QueryFindFirstUserSocilaLinksOrThrowArgs = {
  cursor?: InputMaybe<UserSocilaLinksWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSocilaLinksScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSocilaLinksOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSocilaLinksWhereInput>;
};


export type QueryFindFirstUserTokensArgs = {
  cursor?: InputMaybe<UserTokensWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTokensScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTokensOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTokensWhereInput>;
};


export type QueryFindFirstUserTokensOrThrowArgs = {
  cursor?: InputMaybe<UserTokensWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTokensScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTokensOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTokensWhereInput>;
};


export type QueryFindFirstWebNotificationArgs = {
  cursor?: InputMaybe<WebNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WebNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WebNotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebNotificationWhereInput>;
};


export type QueryFindFirstWebNotificationOrThrowArgs = {
  cursor?: InputMaybe<WebNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WebNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WebNotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebNotificationWhereInput>;
};


export type QueryFindManyMediaArgs = {
  cursor?: InputMaybe<MediaWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaWhereInput>;
};


export type QueryFindManyUserPermissionsArgs = {
  cursor?: InputMaybe<UserPermissionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPermissionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserPermissionsWhereInput>;
};


export type QueryFindManyUserSocilaLinksArgs = {
  cursor?: InputMaybe<UserSocilaLinksWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSocilaLinksScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSocilaLinksOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSocilaLinksWhereInput>;
};


export type QueryFindManyUserTokensArgs = {
  cursor?: InputMaybe<UserTokensWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTokensScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTokensOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTokensWhereInput>;
};


export type QueryFindUniqueMediaArgs = {
  where: MediaWhereUniqueInput;
};


export type QueryFindUniqueMediaOrThrowArgs = {
  where: MediaWhereUniqueInput;
};


export type QueryFindUniqueUserPermissionsArgs = {
  where: UserPermissionsWhereUniqueInput;
};


export type QueryFindUniqueUserPermissionsOrThrowArgs = {
  where: UserPermissionsWhereUniqueInput;
};


export type QueryFindUniqueUserSocilaLinksArgs = {
  where: UserSocilaLinksWhereUniqueInput;
};


export type QueryFindUniqueUserSocilaLinksOrThrowArgs = {
  where: UserSocilaLinksWhereUniqueInput;
};


export type QueryFindUniqueUserTokensArgs = {
  where: UserTokensWhereUniqueInput;
};


export type QueryFindUniqueUserTokensOrThrowArgs = {
  where: UserTokensWhereUniqueInput;
};


export type QueryGetCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryGetCategoryPageAdArgs = {
  where: CategoryPageAdWhereUniqueInput;
};


export type QueryGetCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryGetCommentReactionArgs = {
  where: CommentReactionWhereUniqueInput;
};


export type QueryGetHomePageAdArgs = {
  where: HomePageAdWhereUniqueInput;
};


export type QueryGetLikesByUserArgs = {
  where: LikesByUserWhereUniqueInput;
};


export type QueryGetMediaCategoryArgs = {
  where: MediaCategoryWhereUniqueInput;
};


export type QueryGetNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type QueryGetPostArgs = {
  where: PostWhereUniqueInput;
};


export type QueryGetPostPageAdArgs = {
  where: PostPageAdWhereUniqueInput;
};


export type QueryGetRssArgs = {
  where: RssWhereUniqueInput;
};


export type QueryGetSiteinfoArgs = {
  where: SiteinfoWhereUniqueInput;
};


export type QueryGetSubCategoryArgs = {
  where: SubCategoryWhereUniqueInput;
};


export type QueryGetSubCategoryPageAdArgs = {
  where: SubCategoryPageAdWhereUniqueInput;
};


export type QueryGetSubscriberArgs = {
  where: SubscriberWhereUniqueInput;
};


export type QueryGetTagArgs = {
  where: TagWhereUniqueInput;
};


export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetWebNotificationArgs = {
  where: WebNotificationWhereUniqueInput;
};


export type QueryGroupByCategoryArgs = {
  by: Array<CategoryScalarFieldEnum>;
  having?: InputMaybe<CategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryGroupByCategoryPageAdArgs = {
  by: Array<CategoryPageAdScalarFieldEnum>;
  having?: InputMaybe<CategoryPageAdScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CategoryPageAdOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryPageAdWhereInput>;
};


export type QueryGroupByCommentArgs = {
  by: Array<CommentScalarFieldEnum>;
  having?: InputMaybe<CommentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryGroupByCommentReactionArgs = {
  by: Array<CommentReactionScalarFieldEnum>;
  having?: InputMaybe<CommentReactionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type QueryGroupByHomePageAdArgs = {
  by: Array<HomePageAdScalarFieldEnum>;
  having?: InputMaybe<HomePageAdScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<HomePageAdOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HomePageAdWhereInput>;
};


export type QueryGroupByLikesByUserArgs = {
  by: Array<LikesByUserScalarFieldEnum>;
  having?: InputMaybe<LikesByUserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type QueryGroupByMediaArgs = {
  by: Array<MediaScalarFieldEnum>;
  having?: InputMaybe<MediaScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MediaOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaWhereInput>;
};


export type QueryGroupByMediaCategoryArgs = {
  by: Array<MediaCategoryScalarFieldEnum>;
  having?: InputMaybe<MediaCategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MediaCategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type QueryGroupByNotificationArgs = {
  by: Array<NotificationScalarFieldEnum>;
  having?: InputMaybe<NotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryGroupByPostArgs = {
  by: Array<PostScalarFieldEnum>;
  having?: InputMaybe<PostScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryGroupByPostPageAdArgs = {
  by: Array<PostPageAdScalarFieldEnum>;
  having?: InputMaybe<PostPageAdScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PostPageAdOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostPageAdWhereInput>;
};


export type QueryGroupByRssArgs = {
  by: Array<RssScalarFieldEnum>;
  having?: InputMaybe<RssScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RssOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RssWhereInput>;
};


export type QueryGroupBySiteinfoArgs = {
  by: Array<SiteinfoScalarFieldEnum>;
  having?: InputMaybe<SiteinfoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SiteinfoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteinfoWhereInput>;
};


export type QueryGroupBySubCategoryArgs = {
  by: Array<SubCategoryScalarFieldEnum>;
  having?: InputMaybe<SubCategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type QueryGroupBySubCategoryPageAdArgs = {
  by: Array<SubCategoryPageAdScalarFieldEnum>;
  having?: InputMaybe<SubCategoryPageAdScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SubCategoryPageAdOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryPageAdWhereInput>;
};


export type QueryGroupBySubscriberArgs = {
  by: Array<SubscriberScalarFieldEnum>;
  having?: InputMaybe<SubscriberScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SubscriberOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriberWhereInput>;
};


export type QueryGroupByTagArgs = {
  by: Array<TagScalarFieldEnum>;
  having?: InputMaybe<TagScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGroupByUserPermissionsArgs = {
  by: Array<UserPermissionsScalarFieldEnum>;
  having?: InputMaybe<UserPermissionsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserPermissionsWhereInput>;
};


export type QueryGroupByUserSocilaLinksArgs = {
  by: Array<UserSocilaLinksScalarFieldEnum>;
  having?: InputMaybe<UserSocilaLinksScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserSocilaLinksOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSocilaLinksWhereInput>;
};


export type QueryGroupByUserTokensArgs = {
  by: Array<UserTokensScalarFieldEnum>;
  having?: InputMaybe<UserTokensScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserTokensOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTokensWhereInput>;
};


export type QueryGroupByWebNotificationArgs = {
  by: Array<WebNotificationScalarFieldEnum>;
  having?: InputMaybe<WebNotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<WebNotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebNotificationWhereInput>;
};


export type QueryHomePageAdArgs = {
  where: HomePageAdWhereUniqueInput;
};


export type QueryHomePageAdsArgs = {
  cursor?: InputMaybe<HomePageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<HomePageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HomePageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HomePageAdWhereInput>;
};


export type QueryLikesByUserArgs = {
  where: LikesByUserWhereUniqueInput;
};


export type QueryLikesByUsersArgs = {
  cursor?: InputMaybe<LikesByUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikesByUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type QueryMediaCategoriesArgs = {
  cursor?: InputMaybe<MediaCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<MediaCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MediaCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaCategoryWhereInput>;
};


export type QueryMediaCategoryArgs = {
  where: MediaCategoryWhereUniqueInput;
};


export type QueryNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type QueryNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryPostArgs = {
  where: PostWhereUniqueInput;
};


export type QueryPostPageAdArgs = {
  where: PostPageAdWhereUniqueInput;
};


export type QueryPostPageAdsArgs = {
  cursor?: InputMaybe<PostPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostPageAdWhereInput>;
};


export type QueryPostsArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type QueryRssArgs = {
  where: RssWhereUniqueInput;
};


export type QueryRssesArgs = {
  cursor?: InputMaybe<RssWhereUniqueInput>;
  distinct?: InputMaybe<Array<RssScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RssOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RssWhereInput>;
};


export type QuerySiteinfoArgs = {
  where: SiteinfoWhereUniqueInput;
};


export type QuerySiteinfosArgs = {
  cursor?: InputMaybe<SiteinfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SiteinfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SiteinfoOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteinfoWhereInput>;
};


export type QuerySlugAvailableArgs = {
  slug: Scalars['String']['input'];
};


export type QuerySubCategoriesArgs = {
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryWhereInput>;
};


export type QuerySubCategoryArgs = {
  where: SubCategoryWhereUniqueInput;
};


export type QuerySubCategoryPageAdArgs = {
  where: SubCategoryPageAdWhereUniqueInput;
};


export type QuerySubCategoryPageAdsArgs = {
  cursor?: InputMaybe<SubCategoryPageAdWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryPageAdScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryPageAdOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryPageAdWhereInput>;
};


export type QuerySubscriberArgs = {
  where: SubscriberWhereUniqueInput;
};


export type QuerySubscribersArgs = {
  cursor?: InputMaybe<SubscriberWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriberScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriberOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriberWhereInput>;
};


export type QueryTagArgs = {
  where: TagWhereUniqueInput;
};


export type QueryTagsArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryWebNotificationArgs = {
  where: WebNotificationWhereUniqueInput;
};


export type QueryWebNotificationsArgs = {
  cursor?: InputMaybe<WebNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WebNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WebNotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebNotificationWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type ReesetPassByLinkInput = {
  confirmPass: Scalars['String']['input'];
  newPass: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RefreshTokenResponsce = {
  __typename?: 'RefreshTokenResponsce';
  accessToken?: Maybe<Scalars['String']['output']>;
  expiresIn?: Maybe<Scalars['Float']['output']>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Rss = {
  __typename?: 'Rss';
  category: Category;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  subcategory?: Maybe<SubCategory>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type RssCountAggregate = {
  __typename?: 'RssCountAggregate';
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  subcategoryId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type RssCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type RssCreateInput = {
  category: CategoryCreateNestedOneWithoutRssesInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutRssesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type RssCreateManyCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type RssCreateManyCategoryInputEnvelope = {
  data: Array<RssCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RssCreateManyInput = {
  categoryId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type RssCreateManySubcategoryInput = {
  categoryId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type RssCreateManySubcategoryInputEnvelope = {
  data: Array<RssCreateManySubcategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RssCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<RssWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RssCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<RssCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<RssCreateManyCategoryInputEnvelope>;
};

export type RssCreateNestedManyWithoutSubcategoryInput = {
  connect?: InputMaybe<Array<RssWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RssCreateOrConnectWithoutSubcategoryInput>>;
  create?: InputMaybe<Array<RssCreateWithoutSubcategoryInput>>;
  createMany?: InputMaybe<RssCreateManySubcategoryInputEnvelope>;
};

export type RssCreateOrConnectWithoutCategoryInput = {
  create: RssCreateWithoutCategoryInput;
  where: RssWhereUniqueInput;
};

export type RssCreateOrConnectWithoutSubcategoryInput = {
  create: RssCreateWithoutSubcategoryInput;
  where: RssWhereUniqueInput;
};

export type RssCreateWithoutCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutRssesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type RssCreateWithoutSubcategoryInput = {
  category: CategoryCreateNestedOneWithoutRssesInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type RssGroupBy = {
  __typename?: 'RssGroupBy';
  _count?: Maybe<RssCountAggregate>;
  _max?: Maybe<RssMaxAggregate>;
  _min?: Maybe<RssMinAggregate>;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  subcategoryId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type RssListRelationFilter = {
  every?: InputMaybe<RssWhereInput>;
  none?: InputMaybe<RssWhereInput>;
  some?: InputMaybe<RssWhereInput>;
};

export type RssMaxAggregate = {
  __typename?: 'RssMaxAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RssMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type RssMinAggregate = {
  __typename?: 'RssMinAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RssMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type RssOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum RssOrderByRelevanceFieldEnum {
  CategoryId = 'categoryId',
  Id = 'id',
  SubcategoryId = 'subcategoryId',
  Url = 'url'
}

export type RssOrderByRelevanceInput = {
  fields: Array<RssOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type RssOrderByWithAggregationInput = {
  _count?: InputMaybe<RssCountOrderByAggregateInput>;
  _max?: InputMaybe<RssMaxOrderByAggregateInput>;
  _min?: InputMaybe<RssMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type RssOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RssOrderByRelevanceInput>;
  category?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subcategory?: InputMaybe<SubCategoryOrderByWithRelationAndSearchRelevanceInput>;
  subcategoryId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum RssScalarFieldEnum {
  CategoryId = 'categoryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  SubcategoryId = 'subcategoryId',
  UpdatedAt = 'updatedAt',
  Url = 'url'
}

export type RssScalarWhereInput = {
  AND?: InputMaybe<Array<RssScalarWhereInput>>;
  NOT?: InputMaybe<Array<RssScalarWhereInput>>;
  OR?: InputMaybe<Array<RssScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  subcategoryId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type RssScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RssScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RssScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RssScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  subcategoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
};

export type RssUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutRssesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutRssesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RssUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RssUpdateManyWithWhereWithoutCategoryInput = {
  data: RssUpdateManyMutationInput;
  where: RssScalarWhereInput;
};

export type RssUpdateManyWithWhereWithoutSubcategoryInput = {
  data: RssUpdateManyMutationInput;
  where: RssScalarWhereInput;
};

export type RssUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<RssWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RssCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<RssCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<RssCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<RssWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RssScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RssWhereUniqueInput>>;
  set?: InputMaybe<Array<RssWhereUniqueInput>>;
  update?: InputMaybe<Array<RssUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<RssUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<RssUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type RssUpdateManyWithoutSubcategoryNestedInput = {
  connect?: InputMaybe<Array<RssWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RssCreateOrConnectWithoutSubcategoryInput>>;
  create?: InputMaybe<Array<RssCreateWithoutSubcategoryInput>>;
  createMany?: InputMaybe<RssCreateManySubcategoryInputEnvelope>;
  delete?: InputMaybe<Array<RssWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RssScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RssWhereUniqueInput>>;
  set?: InputMaybe<Array<RssWhereUniqueInput>>;
  update?: InputMaybe<Array<RssUpdateWithWhereUniqueWithoutSubcategoryInput>>;
  updateMany?: InputMaybe<Array<RssUpdateManyWithWhereWithoutSubcategoryInput>>;
  upsert?: InputMaybe<Array<RssUpsertWithWhereUniqueWithoutSubcategoryInput>>;
};

export type RssUpdateWithWhereUniqueWithoutCategoryInput = {
  data: RssUpdateWithoutCategoryInput;
  where: RssWhereUniqueInput;
};

export type RssUpdateWithWhereUniqueWithoutSubcategoryInput = {
  data: RssUpdateWithoutSubcategoryInput;
  where: RssWhereUniqueInput;
};

export type RssUpdateWithoutCategoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutRssesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RssUpdateWithoutSubcategoryInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutRssesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RssUpsertWithWhereUniqueWithoutCategoryInput = {
  create: RssCreateWithoutCategoryInput;
  update: RssUpdateWithoutCategoryInput;
  where: RssWhereUniqueInput;
};

export type RssUpsertWithWhereUniqueWithoutSubcategoryInput = {
  create: RssCreateWithoutSubcategoryInput;
  update: RssUpdateWithoutSubcategoryInput;
  where: RssWhereUniqueInput;
};

export type RssWhereInput = {
  AND?: InputMaybe<Array<RssWhereInput>>;
  NOT?: InputMaybe<Array<RssWhereInput>>;
  OR?: InputMaybe<Array<RssWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  subcategory?: InputMaybe<SubCategoryRelationFilter>;
  subcategoryId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type RssWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Siteinfo = {
  __typename?: 'Siteinfo';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  tags: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SiteinfoCountAggregate = {
  __typename?: 'SiteinfoCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  tags: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type SiteinfoCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SiteinfoCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  tags: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteinfoCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  tags: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteinfoGroupBy = {
  __typename?: 'SiteinfoGroupBy';
  _count?: Maybe<SiteinfoCountAggregate>;
  _max?: Maybe<SiteinfoMaxAggregate>;
  _min?: Maybe<SiteinfoMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  tags: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SiteinfoMaxAggregate = {
  __typename?: 'SiteinfoMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SiteinfoMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SiteinfoMinAggregate = {
  __typename?: 'SiteinfoMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SiteinfoMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SiteinfoOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  Tags = 'tags',
  Title = 'title'
}

export type SiteinfoOrderByRelevanceInput = {
  fields: Array<SiteinfoOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type SiteinfoOrderByWithAggregationInput = {
  _count?: InputMaybe<SiteinfoCountOrderByAggregateInput>;
  _max?: InputMaybe<SiteinfoMaxOrderByAggregateInput>;
  _min?: InputMaybe<SiteinfoMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SiteinfoOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<SiteinfoOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SiteinfoScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Tags = 'tags',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type SiteinfoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SiteinfoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SiteinfoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SiteinfoScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  tags?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SiteinfoUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteinfoUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteinfoWhereInput = {
  AND?: InputMaybe<Array<SiteinfoWhereInput>>;
  NOT?: InputMaybe<Array<SiteinfoWhereInput>>;
  OR?: InputMaybe<Array<SiteinfoWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SiteinfoWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubCategory = {
  __typename?: 'SubCategory';
  _count?: Maybe<SubCategoryCount>;
  category: Category;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customAd?: Maybe<SubCategoryPageAd>;
  id: Scalars['String']['output'];
  post: Array<Post>;
  rsses: Array<Rss>;
  slug: Scalars['String']['output'];
  subCategoryName: Scalars['String']['output'];
  subCategoryPageKeyWords: Scalars['String']['output'];
  subCategoryPageTitle: Scalars['String']['output'];
  subCategoryText: Scalars['String']['output'];
  subscribers: Array<Subscriber>;
  updatedAt: Scalars['DateTime']['output'];
};


export type SubCategoryPostArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type SubCategoryRssesArgs = {
  cursor?: InputMaybe<RssWhereUniqueInput>;
  distinct?: InputMaybe<Array<RssScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RssOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RssWhereInput>;
};


export type SubCategorySubscribersArgs = {
  cursor?: InputMaybe<SubscriberWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriberScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriberOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriberWhereInput>;
};

export type SubCategoryCount = {
  __typename?: 'SubCategoryCount';
  post: Scalars['Int']['output'];
  rsses: Scalars['Int']['output'];
  subscribers: Scalars['Int']['output'];
};


export type SubCategoryCountPostArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type SubCategoryCountRssesArgs = {
  where?: InputMaybe<RssWhereInput>;
};


export type SubCategoryCountSubscribersArgs = {
  where?: InputMaybe<SubscriberWhereInput>;
};

export type SubCategoryCountAggregate = {
  __typename?: 'SubCategoryCountAggregate';
  _all: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  subCategoryName: Scalars['Int']['output'];
  subCategoryPageKeyWords: Scalars['Int']['output'];
  subCategoryPageTitle: Scalars['Int']['output'];
  subCategoryText: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type SubCategoryCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  subCategoryName?: InputMaybe<SortOrder>;
  subCategoryPageKeyWords?: InputMaybe<SortOrder>;
  subCategoryPageTitle?: InputMaybe<SortOrder>;
  subCategoryText?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubCategoryCreateInput = {
  category: CategoryCreateNestedOneWithoutSubcategoryInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<SubCategoryPageAdCreateNestedOneWithoutSubcategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutSubcategoryInput>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutSubcategoryInput>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutSubcategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryCreateManyCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryCreateManyCategoryInputEnvelope = {
  data: Array<SubCategoryCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubCategoryCreateManyInput = {
  categoryId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SubCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SubCategoryCreateManyCategoryInputEnvelope>;
};

export type SubCategoryCreateNestedManyWithoutSubscribersInput = {
  connect?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubCategoryCreateOrConnectWithoutSubscribersInput>>;
  create?: InputMaybe<Array<SubCategoryCreateWithoutSubscribersInput>>;
};

export type SubCategoryCreateNestedOneWithoutCustomAdInput = {
  connect?: InputMaybe<SubCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryCreateOrConnectWithoutCustomAdInput>;
  create?: InputMaybe<SubCategoryCreateWithoutCustomAdInput>;
};

export type SubCategoryCreateNestedOneWithoutPostInput = {
  connect?: InputMaybe<SubCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<SubCategoryCreateWithoutPostInput>;
};

export type SubCategoryCreateNestedOneWithoutRssesInput = {
  connect?: InputMaybe<SubCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryCreateOrConnectWithoutRssesInput>;
  create?: InputMaybe<SubCategoryCreateWithoutRssesInput>;
};

export type SubCategoryCreateOrConnectWithoutCategoryInput = {
  create: SubCategoryCreateWithoutCategoryInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryCreateOrConnectWithoutCustomAdInput = {
  create: SubCategoryCreateWithoutCustomAdInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryCreateOrConnectWithoutPostInput = {
  create: SubCategoryCreateWithoutPostInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryCreateOrConnectWithoutRssesInput = {
  create: SubCategoryCreateWithoutRssesInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryCreateOrConnectWithoutSubscribersInput = {
  create: SubCategoryCreateWithoutSubscribersInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryCreateWithoutCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<SubCategoryPageAdCreateNestedOneWithoutSubcategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutSubcategoryInput>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutSubcategoryInput>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutSubcategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryCreateWithoutCustomAdInput = {
  category: CategoryCreateNestedOneWithoutSubcategoryInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutSubcategoryInput>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutSubcategoryInput>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutSubcategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryCreateWithoutPostInput = {
  category: CategoryCreateNestedOneWithoutSubcategoryInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<SubCategoryPageAdCreateNestedOneWithoutSubcategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutSubcategoryInput>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutSubcategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryCreateWithoutRssesInput = {
  category: CategoryCreateNestedOneWithoutSubcategoryInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<SubCategoryPageAdCreateNestedOneWithoutSubcategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutSubcategoryInput>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  subscribers?: InputMaybe<SubscriberCreateNestedManyWithoutSubcategoriesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryCreateWithoutSubscribersInput = {
  category: CategoryCreateNestedOneWithoutSubcategoryInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customAd?: InputMaybe<SubCategoryPageAdCreateNestedOneWithoutSubcategoryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutSubcategoryInput>;
  rsses?: InputMaybe<RssCreateNestedManyWithoutSubcategoryInput>;
  slug: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  subCategoryPageKeyWords: Scalars['String']['input'];
  subCategoryPageTitle: Scalars['String']['input'];
  subCategoryText: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubCategoryGroupBy = {
  __typename?: 'SubCategoryGroupBy';
  _count?: Maybe<SubCategoryCountAggregate>;
  _max?: Maybe<SubCategoryMaxAggregate>;
  _min?: Maybe<SubCategoryMinAggregate>;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  subCategoryName: Scalars['String']['output'];
  subCategoryPageKeyWords: Scalars['String']['output'];
  subCategoryPageTitle: Scalars['String']['output'];
  subCategoryText: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubCategoryListRelationFilter = {
  every?: InputMaybe<SubCategoryWhereInput>;
  none?: InputMaybe<SubCategoryWhereInput>;
  some?: InputMaybe<SubCategoryWhereInput>;
};

export type SubCategoryMaxAggregate = {
  __typename?: 'SubCategoryMaxAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  subCategoryName?: Maybe<Scalars['String']['output']>;
  subCategoryPageKeyWords?: Maybe<Scalars['String']['output']>;
  subCategoryPageTitle?: Maybe<Scalars['String']['output']>;
  subCategoryText?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubCategoryMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  subCategoryName?: InputMaybe<SortOrder>;
  subCategoryPageKeyWords?: InputMaybe<SortOrder>;
  subCategoryPageTitle?: InputMaybe<SortOrder>;
  subCategoryText?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubCategoryMinAggregate = {
  __typename?: 'SubCategoryMinAggregate';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  subCategoryName?: Maybe<Scalars['String']['output']>;
  subCategoryPageKeyWords?: Maybe<Scalars['String']['output']>;
  subCategoryPageTitle?: Maybe<Scalars['String']['output']>;
  subCategoryText?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubCategoryMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  subCategoryName?: InputMaybe<SortOrder>;
  subCategoryPageKeyWords?: InputMaybe<SortOrder>;
  subCategoryPageTitle?: InputMaybe<SortOrder>;
  subCategoryText?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubCategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum SubCategoryOrderByRelevanceFieldEnum {
  CategoryId = 'categoryId',
  Id = 'id',
  Slug = 'slug',
  SubCategoryName = 'subCategoryName',
  SubCategoryPageKeyWords = 'subCategoryPageKeyWords',
  SubCategoryPageTitle = 'subCategoryPageTitle',
  SubCategoryText = 'subCategoryText'
}

export type SubCategoryOrderByRelevanceInput = {
  fields: Array<SubCategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type SubCategoryOrderByWithAggregationInput = {
  _count?: InputMaybe<SubCategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<SubCategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<SubCategoryMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  subCategoryName?: InputMaybe<SortOrder>;
  subCategoryPageKeyWords?: InputMaybe<SortOrder>;
  subCategoryPageTitle?: InputMaybe<SortOrder>;
  subCategoryText?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubCategoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<SubCategoryOrderByRelevanceInput>;
  category?: InputMaybe<CategoryOrderByWithRelationAndSearchRelevanceInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customAd?: InputMaybe<SubCategoryPageAdOrderByWithRelationAndSearchRelevanceInput>;
  id?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByRelationAggregateInput>;
  rsses?: InputMaybe<RssOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  subCategoryName?: InputMaybe<SortOrder>;
  subCategoryPageKeyWords?: InputMaybe<SortOrder>;
  subCategoryPageTitle?: InputMaybe<SortOrder>;
  subCategoryText?: InputMaybe<SortOrder>;
  subscribers?: InputMaybe<SubscriberOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubCategoryPageAd = {
  __typename?: 'SubCategoryPageAd';
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  subcategory?: Maybe<SubCategory>;
  subcategoryId: Scalars['String']['output'];
};

export type SubCategoryPageAdCountAggregate = {
  __typename?: 'SubCategoryPageAdCountAggregate';
  _all: Scalars['Int']['output'];
  customHtml: Scalars['Int']['output'];
  html: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['Int']['output'];
  redirectUrl: Scalars['Int']['output'];
  subcategoryId: Scalars['Int']['output'];
};

export type SubCategoryPageAdCountOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
};

export type SubCategoryPageAdCreateInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
  subcategory?: InputMaybe<SubCategoryCreateNestedOneWithoutCustomAdInput>;
};

export type SubCategoryPageAdCreateManyInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
  subcategoryId: Scalars['String']['input'];
};

export type SubCategoryPageAdCreateNestedOneWithoutSubcategoryInput = {
  connect?: InputMaybe<SubCategoryPageAdWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryPageAdCreateOrConnectWithoutSubcategoryInput>;
  create?: InputMaybe<SubCategoryPageAdCreateWithoutSubcategoryInput>;
};

export type SubCategoryPageAdCreateOrConnectWithoutSubcategoryInput = {
  create: SubCategoryPageAdCreateWithoutSubcategoryInput;
  where: SubCategoryPageAdWhereUniqueInput;
};

export type SubCategoryPageAdCreateWithoutSubcategoryInput = {
  customHtml: Scalars['Boolean']['input'];
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type SubCategoryPageAdGroupBy = {
  __typename?: 'SubCategoryPageAdGroupBy';
  _count?: Maybe<SubCategoryPageAdCountAggregate>;
  _max?: Maybe<SubCategoryPageAdMaxAggregate>;
  _min?: Maybe<SubCategoryPageAdMinAggregate>;
  customHtml: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  subcategoryId: Scalars['String']['output'];
};

export type SubCategoryPageAdMaxAggregate = {
  __typename?: 'SubCategoryPageAdMaxAggregate';
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
};

export type SubCategoryPageAdMaxOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
};

export type SubCategoryPageAdMinAggregate = {
  __typename?: 'SubCategoryPageAdMinAggregate';
  customHtml?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  subcategoryId?: Maybe<Scalars['String']['output']>;
};

export type SubCategoryPageAdMinOrderByAggregateInput = {
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
};

export enum SubCategoryPageAdOrderByRelevanceFieldEnum {
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  RedirectUrl = 'redirectUrl',
  SubcategoryId = 'subcategoryId'
}

export type SubCategoryPageAdOrderByRelevanceInput = {
  fields: Array<SubCategoryPageAdOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type SubCategoryPageAdOrderByWithAggregationInput = {
  _count?: InputMaybe<SubCategoryPageAdCountOrderByAggregateInput>;
  _max?: InputMaybe<SubCategoryPageAdMaxOrderByAggregateInput>;
  _min?: InputMaybe<SubCategoryPageAdMinOrderByAggregateInput>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
  subcategoryId?: InputMaybe<SortOrder>;
};

export type SubCategoryPageAdOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<SubCategoryPageAdOrderByRelevanceInput>;
  customHtml?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  redirectUrl?: InputMaybe<SortOrder>;
  subcategory?: InputMaybe<SubCategoryOrderByWithRelationAndSearchRelevanceInput>;
  subcategoryId?: InputMaybe<SortOrder>;
};

export type SubCategoryPageAdRelationFilter = {
  is?: InputMaybe<SubCategoryPageAdWhereInput>;
  isNot?: InputMaybe<SubCategoryPageAdWhereInput>;
};

export enum SubCategoryPageAdScalarFieldEnum {
  CustomHtml = 'customHtml',
  Html = 'html',
  Id = 'id',
  ImageUrl = 'imageUrl',
  RedirectUrl = 'redirectUrl',
  SubcategoryId = 'subcategoryId'
}

export type SubCategoryPageAdScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SubCategoryPageAdScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SubCategoryPageAdScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SubCategoryPageAdScalarWhereWithAggregatesInput>>;
  customHtml?: InputMaybe<BoolWithAggregatesFilter>;
  html?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  imageUrl?: InputMaybe<StringWithAggregatesFilter>;
  redirectUrl?: InputMaybe<StringWithAggregatesFilter>;
  subcategoryId?: InputMaybe<StringWithAggregatesFilter>;
};

export type SubCategoryPageAdUpdateInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategory?: InputMaybe<SubCategoryUpdateOneWithoutCustomAdNestedInput>;
};

export type SubCategoryPageAdUpdateManyMutationInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubCategoryPageAdUpdateOneWithoutSubcategoryNestedInput = {
  connect?: InputMaybe<SubCategoryPageAdWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryPageAdCreateOrConnectWithoutSubcategoryInput>;
  create?: InputMaybe<SubCategoryPageAdCreateWithoutSubcategoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SubCategoryPageAdUpdateWithoutSubcategoryInput>;
  upsert?: InputMaybe<SubCategoryPageAdUpsertWithoutSubcategoryInput>;
};

export type SubCategoryPageAdUpdateWithoutSubcategoryInput = {
  customHtml?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubCategoryPageAdUpsertWithoutSubcategoryInput = {
  create: SubCategoryPageAdCreateWithoutSubcategoryInput;
  update: SubCategoryPageAdUpdateWithoutSubcategoryInput;
};

export type SubCategoryPageAdWhereInput = {
  AND?: InputMaybe<Array<SubCategoryPageAdWhereInput>>;
  NOT?: InputMaybe<Array<SubCategoryPageAdWhereInput>>;
  OR?: InputMaybe<Array<SubCategoryPageAdWhereInput>>;
  customHtml?: InputMaybe<BoolFilter>;
  html?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  redirectUrl?: InputMaybe<StringFilter>;
  subcategory?: InputMaybe<SubCategoryRelationFilter>;
  subcategoryId?: InputMaybe<StringFilter>;
};

export type SubCategoryPageAdWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
};

export type SubCategoryRelationFilter = {
  is?: InputMaybe<SubCategoryWhereInput>;
  isNot?: InputMaybe<SubCategoryWhereInput>;
};

export enum SubCategoryScalarFieldEnum {
  CategoryId = 'categoryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Slug = 'slug',
  SubCategoryName = 'subCategoryName',
  SubCategoryPageKeyWords = 'subCategoryPageKeyWords',
  SubCategoryPageTitle = 'subCategoryPageTitle',
  SubCategoryText = 'subCategoryText',
  UpdatedAt = 'updatedAt'
}

export type SubCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<SubCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<SubCategoryScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  subCategoryName?: InputMaybe<StringFilter>;
  subCategoryPageKeyWords?: InputMaybe<StringFilter>;
  subCategoryPageTitle?: InputMaybe<StringFilter>;
  subCategoryText?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubCategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SubCategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SubCategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SubCategoryScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  subCategoryName?: InputMaybe<StringWithAggregatesFilter>;
  subCategoryPageKeyWords?: InputMaybe<StringWithAggregatesFilter>;
  subCategoryPageTitle?: InputMaybe<StringWithAggregatesFilter>;
  subCategoryText?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SubCategoryUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutSubcategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<SubCategoryPageAdUpdateOneWithoutSubcategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutSubcategoryNestedInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutSubcategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutSubcategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubCategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubCategoryUpdateManyWithWhereWithoutCategoryInput = {
  data: SubCategoryUpdateManyMutationInput;
  where: SubCategoryScalarWhereInput;
};

export type SubCategoryUpdateManyWithWhereWithoutSubscribersInput = {
  data: SubCategoryUpdateManyMutationInput;
  where: SubCategoryScalarWhereInput;
};

export type SubCategoryUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SubCategoryCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SubCategoryCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<SubCategoryUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<SubCategoryUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<SubCategoryUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type SubCategoryUpdateManyWithoutSubscribersNestedInput = {
  connect?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubCategoryCreateOrConnectWithoutSubscribersInput>>;
  create?: InputMaybe<Array<SubCategoryCreateWithoutSubscribersInput>>;
  delete?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<SubCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<SubCategoryUpdateWithWhereUniqueWithoutSubscribersInput>>;
  updateMany?: InputMaybe<Array<SubCategoryUpdateManyWithWhereWithoutSubscribersInput>>;
  upsert?: InputMaybe<Array<SubCategoryUpsertWithWhereUniqueWithoutSubscribersInput>>;
};

export type SubCategoryUpdateOneWithoutCustomAdNestedInput = {
  connect?: InputMaybe<SubCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryCreateOrConnectWithoutCustomAdInput>;
  create?: InputMaybe<SubCategoryCreateWithoutCustomAdInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SubCategoryUpdateWithoutCustomAdInput>;
  upsert?: InputMaybe<SubCategoryUpsertWithoutCustomAdInput>;
};

export type SubCategoryUpdateOneWithoutPostNestedInput = {
  connect?: InputMaybe<SubCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<SubCategoryCreateWithoutPostInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SubCategoryUpdateWithoutPostInput>;
  upsert?: InputMaybe<SubCategoryUpsertWithoutPostInput>;
};

export type SubCategoryUpdateOneWithoutRssesNestedInput = {
  connect?: InputMaybe<SubCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubCategoryCreateOrConnectWithoutRssesInput>;
  create?: InputMaybe<SubCategoryCreateWithoutRssesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SubCategoryUpdateWithoutRssesInput>;
  upsert?: InputMaybe<SubCategoryUpsertWithoutRssesInput>;
};

export type SubCategoryUpdateWithWhereUniqueWithoutCategoryInput = {
  data: SubCategoryUpdateWithoutCategoryInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryUpdateWithWhereUniqueWithoutSubscribersInput = {
  data: SubCategoryUpdateWithoutSubscribersInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryUpdateWithoutCategoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<SubCategoryPageAdUpdateOneWithoutSubcategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutSubcategoryNestedInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutSubcategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutSubcategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubCategoryUpdateWithoutCustomAdInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutSubcategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutSubcategoryNestedInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutSubcategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutSubcategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubCategoryUpdateWithoutPostInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutSubcategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<SubCategoryPageAdUpdateOneWithoutSubcategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutSubcategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutSubcategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubCategoryUpdateWithoutRssesInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutSubcategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<SubCategoryPageAdUpdateOneWithoutSubcategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutSubcategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscribers?: InputMaybe<SubscriberUpdateManyWithoutSubcategoriesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubCategoryUpdateWithoutSubscribersInput = {
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutSubcategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customAd?: InputMaybe<SubCategoryPageAdUpdateOneWithoutSubcategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutSubcategoryNestedInput>;
  rsses?: InputMaybe<RssUpdateManyWithoutSubcategoryNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageKeyWords?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryPageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  subCategoryText?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubCategoryUpsertWithWhereUniqueWithoutCategoryInput = {
  create: SubCategoryCreateWithoutCategoryInput;
  update: SubCategoryUpdateWithoutCategoryInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryUpsertWithWhereUniqueWithoutSubscribersInput = {
  create: SubCategoryCreateWithoutSubscribersInput;
  update: SubCategoryUpdateWithoutSubscribersInput;
  where: SubCategoryWhereUniqueInput;
};

export type SubCategoryUpsertWithoutCustomAdInput = {
  create: SubCategoryCreateWithoutCustomAdInput;
  update: SubCategoryUpdateWithoutCustomAdInput;
};

export type SubCategoryUpsertWithoutPostInput = {
  create: SubCategoryCreateWithoutPostInput;
  update: SubCategoryUpdateWithoutPostInput;
};

export type SubCategoryUpsertWithoutRssesInput = {
  create: SubCategoryCreateWithoutRssesInput;
  update: SubCategoryUpdateWithoutRssesInput;
};

export type SubCategoryWhereInput = {
  AND?: InputMaybe<Array<SubCategoryWhereInput>>;
  NOT?: InputMaybe<Array<SubCategoryWhereInput>>;
  OR?: InputMaybe<Array<SubCategoryWhereInput>>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customAd?: InputMaybe<SubCategoryPageAdRelationFilter>;
  id?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostListRelationFilter>;
  rsses?: InputMaybe<RssListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  subCategoryName?: InputMaybe<StringFilter>;
  subCategoryPageKeyWords?: InputMaybe<StringFilter>;
  subCategoryPageTitle?: InputMaybe<StringFilter>;
  subCategoryText?: InputMaybe<StringFilter>;
  subscribers?: InputMaybe<SubscriberListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  _count?: Maybe<SubscriberCount>;
  categories: Array<Category>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  subcategories: Array<SubCategory>;
  updatedAt: Scalars['DateTime']['output'];
};


export type SubscriberCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type SubscriberSubcategoriesArgs = {
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubCategoryWhereInput>;
};

export type SubscriberCount = {
  __typename?: 'SubscriberCount';
  categories: Scalars['Int']['output'];
  subcategories: Scalars['Int']['output'];
};


export type SubscriberCountCategoriesArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type SubscriberCountSubcategoriesArgs = {
  where?: InputMaybe<SubCategoryWhereInput>;
};

export type SubscriberCountAggregate = {
  __typename?: 'SubscriberCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type SubscriberCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriberCreateInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutSubscribersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  subcategories?: InputMaybe<SubCategoryCreateNestedManyWithoutSubscribersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriberCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriberCreateNestedManyWithoutCategoriesInput = {
  connect?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriberCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<SubscriberCreateWithoutCategoriesInput>>;
};

export type SubscriberCreateNestedManyWithoutSubcategoriesInput = {
  connect?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriberCreateOrConnectWithoutSubcategoriesInput>>;
  create?: InputMaybe<Array<SubscriberCreateWithoutSubcategoriesInput>>;
};

export type SubscriberCreateOrConnectWithoutCategoriesInput = {
  create: SubscriberCreateWithoutCategoriesInput;
  where: SubscriberWhereUniqueInput;
};

export type SubscriberCreateOrConnectWithoutSubcategoriesInput = {
  create: SubscriberCreateWithoutSubcategoriesInput;
  where: SubscriberWhereUniqueInput;
};

export type SubscriberCreateWithoutCategoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  subcategories?: InputMaybe<SubCategoryCreateNestedManyWithoutSubscribersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriberCreateWithoutSubcategoriesInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutSubscribersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriberGroupBy = {
  __typename?: 'SubscriberGroupBy';
  _count?: Maybe<SubscriberCountAggregate>;
  _max?: Maybe<SubscriberMaxAggregate>;
  _min?: Maybe<SubscriberMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriberListRelationFilter = {
  every?: InputMaybe<SubscriberWhereInput>;
  none?: InputMaybe<SubscriberWhereInput>;
  some?: InputMaybe<SubscriberWhereInput>;
};

export type SubscriberMaxAggregate = {
  __typename?: 'SubscriberMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriberMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriberMinAggregate = {
  __typename?: 'SubscriberMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriberMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriberOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum SubscriberOrderByRelevanceFieldEnum {
  Email = 'email',
  Id = 'id'
}

export type SubscriberOrderByRelevanceInput = {
  fields: Array<SubscriberOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type SubscriberOrderByWithAggregationInput = {
  _count?: InputMaybe<SubscriberCountOrderByAggregateInput>;
  _max?: InputMaybe<SubscriberMaxOrderByAggregateInput>;
  _min?: InputMaybe<SubscriberMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriberOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<SubscriberOrderByRelevanceInput>;
  categories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subcategories?: InputMaybe<SubCategoryOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SubscriberScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type SubscriberScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriberScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriberScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriberScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriberScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SubscriberScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SubscriberScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SubscriberScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SubscriberUpdateInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutSubscribersNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategories?: InputMaybe<SubCategoryUpdateManyWithoutSubscribersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriberUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriberUpdateManyWithWhereWithoutCategoriesInput = {
  data: SubscriberUpdateManyMutationInput;
  where: SubscriberScalarWhereInput;
};

export type SubscriberUpdateManyWithWhereWithoutSubcategoriesInput = {
  data: SubscriberUpdateManyMutationInput;
  where: SubscriberScalarWhereInput;
};

export type SubscriberUpdateManyWithoutCategoriesNestedInput = {
  connect?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriberCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<SubscriberCreateWithoutCategoriesInput>>;
  delete?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriberScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  update?: InputMaybe<Array<SubscriberUpdateWithWhereUniqueWithoutCategoriesInput>>;
  updateMany?: InputMaybe<Array<SubscriberUpdateManyWithWhereWithoutCategoriesInput>>;
  upsert?: InputMaybe<Array<SubscriberUpsertWithWhereUniqueWithoutCategoriesInput>>;
};

export type SubscriberUpdateManyWithoutSubcategoriesNestedInput = {
  connect?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriberCreateOrConnectWithoutSubcategoriesInput>>;
  create?: InputMaybe<Array<SubscriberCreateWithoutSubcategoriesInput>>;
  delete?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriberScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriberWhereUniqueInput>>;
  update?: InputMaybe<Array<SubscriberUpdateWithWhereUniqueWithoutSubcategoriesInput>>;
  updateMany?: InputMaybe<Array<SubscriberUpdateManyWithWhereWithoutSubcategoriesInput>>;
  upsert?: InputMaybe<Array<SubscriberUpsertWithWhereUniqueWithoutSubcategoriesInput>>;
};

export type SubscriberUpdateWithWhereUniqueWithoutCategoriesInput = {
  data: SubscriberUpdateWithoutCategoriesInput;
  where: SubscriberWhereUniqueInput;
};

export type SubscriberUpdateWithWhereUniqueWithoutSubcategoriesInput = {
  data: SubscriberUpdateWithoutSubcategoriesInput;
  where: SubscriberWhereUniqueInput;
};

export type SubscriberUpdateWithoutCategoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subcategories?: InputMaybe<SubCategoryUpdateManyWithoutSubscribersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriberUpdateWithoutSubcategoriesInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutSubscribersNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriberUpsertWithWhereUniqueWithoutCategoriesInput = {
  create: SubscriberCreateWithoutCategoriesInput;
  update: SubscriberUpdateWithoutCategoriesInput;
  where: SubscriberWhereUniqueInput;
};

export type SubscriberUpsertWithWhereUniqueWithoutSubcategoriesInput = {
  create: SubscriberCreateWithoutSubcategoriesInput;
  update: SubscriberUpdateWithoutSubcategoriesInput;
  where: SubscriberWhereUniqueInput;
};

export type SubscriberWhereInput = {
  AND?: InputMaybe<Array<SubscriberWhereInput>>;
  NOT?: InputMaybe<Array<SubscriberWhereInput>>;
  OR?: InputMaybe<Array<SubscriberWhereInput>>;
  categories?: InputMaybe<CategoryListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  subcategories?: InputMaybe<SubCategoryListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriberWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newNotification: Notification;
  unreadNotificationCountUpdated: UnReadNotificationCount;
};

export type Tag = {
  __typename?: 'Tag';
  _count?: Maybe<TagCount>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  posts: Array<Post>;
  slug: Scalars['String']['output'];
};


export type TagPostsArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};

export type TagCount = {
  __typename?: 'TagCount';
  posts: Scalars['Int']['output'];
};


export type TagCountPostsArgs = {
  where?: InputMaybe<PostWhereInput>;
};

export type TagCountAggregate = {
  __typename?: 'TagCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
};

export type TagCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type TagCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  posts?: InputMaybe<PostCreateNestedManyWithoutTagInput>;
  slug: Scalars['String']['input'];
};

export type TagCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type TagCreateNestedManyWithoutPostsInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutPostsInput>>;
  create?: InputMaybe<Array<TagCreateWithoutPostsInput>>;
};

export type TagCreateOrConnectWithoutPostsInput = {
  create: TagCreateWithoutPostsInput;
  where: TagWhereUniqueInput;
};

export type TagCreateWithoutPostsInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type TagGroupBy = {
  __typename?: 'TagGroupBy';
  _count?: Maybe<TagCountAggregate>;
  _max?: Maybe<TagMaxAggregate>;
  _min?: Maybe<TagMinAggregate>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type TagListRelationFilter = {
  every?: InputMaybe<TagWhereInput>;
  none?: InputMaybe<TagWhereInput>;
  some?: InputMaybe<TagWhereInput>;
};

export type TagMaxAggregate = {
  __typename?: 'TagMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type TagMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type TagMinAggregate = {
  __typename?: 'TagMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type TagMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type TagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum TagOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Slug = 'slug'
}

export type TagOrderByRelevanceInput = {
  fields: Array<TagOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type TagOrderByWithAggregationInput = {
  _count?: InputMaybe<TagCountOrderByAggregateInput>;
  _max?: InputMaybe<TagMaxOrderByAggregateInput>;
  _min?: InputMaybe<TagMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type TagOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<TagOrderByRelevanceInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  posts?: InputMaybe<PostOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
};

export enum TagScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Slug = 'slug'
}

export type TagScalarWhereInput = {
  AND?: InputMaybe<Array<TagScalarWhereInput>>;
  NOT?: InputMaybe<Array<TagScalarWhereInput>>;
  OR?: InputMaybe<Array<TagScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type TagScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TagScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TagScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TagScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
};

export type TagUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<PostUpdateManyWithoutTagNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TagUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TagUpdateManyWithWhereWithoutPostsInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithoutPostsNestedInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutPostsInput>>;
  create?: InputMaybe<Array<TagCreateWithoutPostsInput>>;
  delete?: InputMaybe<Array<TagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
  set?: InputMaybe<Array<TagWhereUniqueInput>>;
  update?: InputMaybe<Array<TagUpdateWithWhereUniqueWithoutPostsInput>>;
  updateMany?: InputMaybe<Array<TagUpdateManyWithWhereWithoutPostsInput>>;
  upsert?: InputMaybe<Array<TagUpsertWithWhereUniqueWithoutPostsInput>>;
};

export type TagUpdateWithWhereUniqueWithoutPostsInput = {
  data: TagUpdateWithoutPostsInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithoutPostsInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TagUpsertWithWhereUniqueWithoutPostsInput = {
  create: TagCreateWithoutPostsInput;
  update: TagUpdateWithoutPostsInput;
  where: TagWhereUniqueInput;
};

export type TagWhereInput = {
  AND?: InputMaybe<Array<TagWhereInput>>;
  NOT?: InputMaybe<Array<TagWhereInput>>;
  OR?: InputMaybe<Array<TagWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  posts?: InputMaybe<PostListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type TagWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type TaxonomyAndMedia = {
  category: CategoryWhereUniqueInput;
  image: MediaWhereUniqueInput;
  subcategory?: InputMaybe<SubCategoryWhereUniqueInput>;
  tag?: InputMaybe<Array<TagWhereUniqueInput>>;
};

export type UnReadNotificationCount = {
  __typename?: 'UnReadNotificationCount';
  count: Scalars['Float']['output'];
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  address: Scalars['String']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  commentReactions: Array<CommentReaction>;
  comments: Array<Comment>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdNotifications: Array<Notification>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  likesByUser: Array<LikesByUser>;
  myNotifications: Array<Notification>;
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  post: Array<Post>;
  professionalName: Scalars['String']['output'];
  role: UserRole;
  socilaLinks?: Maybe<UserSocilaLinks>;
  status: UserAccountStatus;
  updatedAt: Scalars['DateTime']['output'];
  userTokens: Array<UserTokens>;
  username: Scalars['String']['output'];
};


export type UserCommentReactionsArgs = {
  cursor?: InputMaybe<CommentReactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentReactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type UserCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type UserCreatedNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserLikesByUserArgs = {
  cursor?: InputMaybe<LikesByUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikesByUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type UserMyNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserPostArgs = {
  cursor?: InputMaybe<PostWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostWhereInput>;
};


export type UserUserTokensArgs = {
  cursor?: InputMaybe<UserTokensWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserTokensScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserTokensOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserTokensWhereInput>;
};

export enum UserAccountStatus {
  Active = 'active',
  Pending = 'pending',
  VerifyEmail = 'verify_email'
}

export type UserCount = {
  __typename?: 'UserCount';
  commentReactions: Scalars['Int']['output'];
  comments: Scalars['Int']['output'];
  createdNotifications: Scalars['Int']['output'];
  likesByUser: Scalars['Int']['output'];
  myNotifications: Scalars['Int']['output'];
  post: Scalars['Int']['output'];
  userTokens: Scalars['Int']['output'];
};


export type UserCountCommentReactionsArgs = {
  where?: InputMaybe<CommentReactionWhereInput>;
};


export type UserCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type UserCountCreatedNotificationsArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserCountLikesByUserArgs = {
  where?: InputMaybe<LikesByUserWhereInput>;
};


export type UserCountMyNotificationsArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserCountPostArgs = {
  where?: InputMaybe<PostWhereInput>;
};


export type UserCountUserTokensArgs = {
  where?: InputMaybe<UserTokensWhereInput>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  avatar: Scalars['Int']['output'];
  bio: Scalars['Int']['output'];
  cover: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  password: Scalars['Int']['output'];
  professionalName: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  username: Scalars['Int']['output'];
};

export type UserCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  cover?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  professionalName?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateManyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateNestedOneWithoutCommentReactionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentReactionsInput>;
  create?: InputMaybe<UserCreateWithoutCommentReactionsInput>;
};

export type UserCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
};

export type UserCreateNestedOneWithoutCreatedNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedNotificationsInput>;
};

export type UserCreateNestedOneWithoutLikesByUserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLikesByUserInput>;
  create?: InputMaybe<UserCreateWithoutLikesByUserInput>;
};

export type UserCreateNestedOneWithoutMyNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMyNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutMyNotificationsInput>;
};

export type UserCreateNestedOneWithoutPostInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<UserCreateWithoutPostInput>;
};

export type UserCreateNestedOneWithoutSocilaLinksInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSocilaLinksInput>;
  create?: InputMaybe<UserCreateWithoutSocilaLinksInput>;
};

export type UserCreateNestedOneWithoutUserTokensInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTokensInput>;
  create?: InputMaybe<UserCreateWithoutUserTokensInput>;
};

export type UserCreateOrConnectWithoutCommentReactionsInput = {
  create: UserCreateWithoutCommentReactionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedNotificationsInput = {
  create: UserCreateWithoutCreatedNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLikesByUserInput = {
  create: UserCreateWithoutLikesByUserInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMyNotificationsInput = {
  create: UserCreateWithoutMyNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPostInput = {
  create: UserCreateWithoutPostInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSocilaLinksInput = {
  create: UserCreateWithoutSocilaLinksInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserTokensInput = {
  create: UserCreateWithoutUserTokensInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCommentReactionsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCommentsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCreatedNotificationsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutLikesByUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutMyNotificationsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutPostInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSocilaLinksInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTokens?: InputMaybe<UserTokensCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutUserTokensInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  commentReactions?: InputMaybe<CommentReactionCreateNestedManyWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  cover?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  likesByUser?: InputMaybe<LikesByUserCreateNestedManyWithoutUserInput>;
  myNotifications?: InputMaybe<NotificationCreateNestedManyWithoutCreatedForInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  post?: InputMaybe<PostCreateNestedManyWithoutUserInput>;
  professionalName: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  socilaLinks?: InputMaybe<UserSocilaLinksCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserAccountStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserForResponsce = {
  __typename?: 'UserForResponsce';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  address: Scalars['String']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  professionalName: Scalars['String']['output'];
  role: UserRole;
  status: UserAccountStatus;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  address?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  professionalName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UserRole>;
  status?: Maybe<UserAccountStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  cover?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  professionalName?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  address?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  professionalName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UserRole>;
  status?: Maybe<UserAccountStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  cover?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  professionalName?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  Address = 'address',
  Avatar = 'avatar',
  Bio = 'bio',
  Cover = 'cover',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  Password = 'password',
  ProfessionalName = 'professionalName',
  Username = 'username'
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrderInput>;
  bio?: InputMaybe<SortOrderInput>;
  cover?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  professionalName?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<UserOrderByRelevanceInput>;
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrderInput>;
  bio?: InputMaybe<SortOrderInput>;
  commentReactions?: InputMaybe<CommentReactionOrderByRelationAggregateInput>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  cover?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdNotifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  likesByUser?: InputMaybe<LikesByUserOrderByRelationAggregateInput>;
  myNotifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByRelationAggregateInput>;
  professionalName?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  socilaLinks?: InputMaybe<UserSocilaLinksOrderByWithRelationAndSearchRelevanceInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userTokens?: InputMaybe<UserTokensOrderByRelationAggregateInput>;
  username?: InputMaybe<SortOrder>;
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserPermissionsCountAggregate = {
  __typename?: 'UserPermissionsCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type UserPermissionsCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserPermissionsCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserPermissionsCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserPermissionsGroupBy = {
  __typename?: 'UserPermissionsGroupBy';
  _count?: Maybe<UserPermissionsCountAggregate>;
  _max?: Maybe<UserPermissionsMaxAggregate>;
  _min?: Maybe<UserPermissionsMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserPermissionsMaxAggregate = {
  __typename?: 'UserPermissionsMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserPermissionsMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserPermissionsMinAggregate = {
  __typename?: 'UserPermissionsMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserPermissionsMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum UserPermissionsOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type UserPermissionsOrderByRelevanceInput = {
  fields: Array<UserPermissionsOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type UserPermissionsOrderByWithAggregationInput = {
  _count?: InputMaybe<UserPermissionsCountOrderByAggregateInput>;
  _max?: InputMaybe<UserPermissionsMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserPermissionsMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserPermissionsOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<UserPermissionsOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum UserPermissionsScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type UserPermissionsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserPermissionsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserPermissionsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserPermissionsScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type UserPermissionsUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserPermissionsUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserPermissionsWhereInput = {
  AND?: InputMaybe<Array<UserPermissionsWhereInput>>;
  NOT?: InputMaybe<Array<UserPermissionsWhereInput>>;
  OR?: InputMaybe<Array<UserPermissionsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserPermissionsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserRole {
  Admin = 'admin',
  Author = 'author',
  Public = 'public',
  Superadmin = 'superadmin'
}

export enum UserScalarFieldEnum {
  Address = 'address',
  Avatar = 'avatar',
  Bio = 'bio',
  Cover = 'cover',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  Password = 'password',
  ProfessionalName = 'professionalName',
  Role = 'role',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringWithAggregatesFilter>;
  avatar?: InputMaybe<StringNullableWithAggregatesFilter>;
  bio?: InputMaybe<StringNullableWithAggregatesFilter>;
  cover?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  password?: InputMaybe<StringWithAggregatesFilter>;
  professionalName?: InputMaybe<StringWithAggregatesFilter>;
  role?: InputMaybe<EnumUserRoleWithAggregatesFilter>;
  status?: InputMaybe<EnumUserAccountStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  username?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserSocilaLinks = {
  __typename?: 'UserSocilaLinks';
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  linkedIn?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type UserSocilaLinksCountAggregate = {
  __typename?: 'UserSocilaLinksCountAggregate';
  _all: Scalars['Int']['output'];
  facebook: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  linkedIn: Scalars['Int']['output'];
  twitter: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserSocilaLinksCountOrderByAggregateInput = {
  facebook?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linkedIn?: InputMaybe<SortOrder>;
  twitter?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSocilaLinksCreateInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSocilaLinksInput>;
};

export type UserSocilaLinksCreateManyInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserSocilaLinksCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<UserSocilaLinksWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSocilaLinksCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<UserSocilaLinksCreateWithoutUserInput>;
};

export type UserSocilaLinksCreateOrConnectWithoutUserInput = {
  create: UserSocilaLinksCreateWithoutUserInput;
  where: UserSocilaLinksWhereUniqueInput;
};

export type UserSocilaLinksCreateWithoutUserInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export type UserSocilaLinksGroupBy = {
  __typename?: 'UserSocilaLinksGroupBy';
  _count?: Maybe<UserSocilaLinksCountAggregate>;
  _max?: Maybe<UserSocilaLinksMaxAggregate>;
  _min?: Maybe<UserSocilaLinksMinAggregate>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  linkedIn?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserSocilaLinksMaxAggregate = {
  __typename?: 'UserSocilaLinksMaxAggregate';
  facebook?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  linkedIn?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSocilaLinksMaxOrderByAggregateInput = {
  facebook?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linkedIn?: InputMaybe<SortOrder>;
  twitter?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSocilaLinksMinAggregate = {
  __typename?: 'UserSocilaLinksMinAggregate';
  facebook?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  linkedIn?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSocilaLinksMinOrderByAggregateInput = {
  facebook?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linkedIn?: InputMaybe<SortOrder>;
  twitter?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserSocilaLinksOrderByRelevanceFieldEnum {
  Facebook = 'facebook',
  Id = 'id',
  LinkedIn = 'linkedIn',
  Twitter = 'twitter',
  UserId = 'userId'
}

export type UserSocilaLinksOrderByRelevanceInput = {
  fields: Array<UserSocilaLinksOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type UserSocilaLinksOrderByWithAggregationInput = {
  _count?: InputMaybe<UserSocilaLinksCountOrderByAggregateInput>;
  _max?: InputMaybe<UserSocilaLinksMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserSocilaLinksMinOrderByAggregateInput>;
  facebook?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  linkedIn?: InputMaybe<SortOrderInput>;
  twitter?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSocilaLinksOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<UserSocilaLinksOrderByRelevanceInput>;
  facebook?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  linkedIn?: InputMaybe<SortOrderInput>;
  twitter?: InputMaybe<SortOrderInput>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSocilaLinksRelationFilter = {
  is?: InputMaybe<UserSocilaLinksWhereInput>;
  isNot?: InputMaybe<UserSocilaLinksWhereInput>;
};

export enum UserSocilaLinksScalarFieldEnum {
  Facebook = 'facebook',
  Id = 'id',
  LinkedIn = 'linkedIn',
  Twitter = 'twitter',
  UserId = 'userId'
}

export type UserSocilaLinksScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserSocilaLinksScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserSocilaLinksScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserSocilaLinksScalarWhereWithAggregatesInput>>;
  facebook?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  linkedIn?: InputMaybe<StringNullableWithAggregatesFilter>;
  twitter?: InputMaybe<StringNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserSocilaLinksUpdateInput = {
  facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkedIn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSocilaLinksNestedInput>;
};

export type UserSocilaLinksUpdateManyMutationInput = {
  facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkedIn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserSocilaLinksUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<UserSocilaLinksWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSocilaLinksCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<UserSocilaLinksCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserSocilaLinksUpdateWithoutUserInput>;
  upsert?: InputMaybe<UserSocilaLinksUpsertWithoutUserInput>;
};

export type UserSocilaLinksUpdateWithoutUserInput = {
  facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkedIn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserSocilaLinksUpsertWithoutUserInput = {
  create: UserSocilaLinksCreateWithoutUserInput;
  update: UserSocilaLinksUpdateWithoutUserInput;
};

export type UserSocilaLinksWhereInput = {
  AND?: InputMaybe<Array<UserSocilaLinksWhereInput>>;
  NOT?: InputMaybe<Array<UserSocilaLinksWhereInput>>;
  OR?: InputMaybe<Array<UserSocilaLinksWhereInput>>;
  facebook?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  linkedIn?: InputMaybe<StringNullableFilter>;
  twitter?: InputMaybe<StringNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserSocilaLinksWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserTokens = {
  __typename?: 'UserTokens';
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserTokensCountAggregate = {
  __typename?: 'UserTokensCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  token: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserTokensCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTokensCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  user: UserCreateNestedOneWithoutUserTokensInput;
};

export type UserTokensCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserTokensCreateManyUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type UserTokensCreateManyUserInputEnvelope = {
  data: Array<UserTokensCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserTokensCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserTokensWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserTokensCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserTokensCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTokensCreateManyUserInputEnvelope>;
};

export type UserTokensCreateOrConnectWithoutUserInput = {
  create: UserTokensCreateWithoutUserInput;
  where: UserTokensWhereUniqueInput;
};

export type UserTokensCreateWithoutUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type UserTokensGroupBy = {
  __typename?: 'UserTokensGroupBy';
  _count?: Maybe<UserTokensCountAggregate>;
  _max?: Maybe<UserTokensMaxAggregate>;
  _min?: Maybe<UserTokensMinAggregate>;
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserTokensListRelationFilter = {
  every?: InputMaybe<UserTokensWhereInput>;
  none?: InputMaybe<UserTokensWhereInput>;
  some?: InputMaybe<UserTokensWhereInput>;
};

export type UserTokensMaxAggregate = {
  __typename?: 'UserTokensMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTokensMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTokensMinAggregate = {
  __typename?: 'UserTokensMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserTokensMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTokensOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum UserTokensOrderByRelevanceFieldEnum {
  Id = 'id',
  Token = 'token',
  UserId = 'userId'
}

export type UserTokensOrderByRelevanceInput = {
  fields: Array<UserTokensOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type UserTokensOrderByWithAggregationInput = {
  _count?: InputMaybe<UserTokensCountOrderByAggregateInput>;
  _max?: InputMaybe<UserTokensMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserTokensMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserTokensOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<UserTokensOrderByRelevanceInput>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserTokensScalarFieldEnum {
  Id = 'id',
  Token = 'token',
  UserId = 'userId'
}

export type UserTokensScalarWhereInput = {
  AND?: InputMaybe<Array<UserTokensScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserTokensScalarWhereInput>>;
  OR?: InputMaybe<Array<UserTokensScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTokensScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserTokensScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserTokensScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserTokensScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  token?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserTokensUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserTokensNestedInput>;
};

export type UserTokensUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserTokensUpdateManyWithWhereWithoutUserInput = {
  data: UserTokensUpdateManyMutationInput;
  where: UserTokensScalarWhereInput;
};

export type UserTokensUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserTokensWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserTokensCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserTokensCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTokensCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserTokensWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTokensScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTokensWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTokensWhereUniqueInput>>;
  update?: InputMaybe<Array<UserTokensUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserTokensUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserTokensUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserTokensUpdateWithWhereUniqueWithoutUserInput = {
  data: UserTokensUpdateWithoutUserInput;
  where: UserTokensWhereUniqueInput;
};

export type UserTokensUpdateWithoutUserInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserTokensUpsertWithWhereUniqueWithoutUserInput = {
  create: UserTokensCreateWithoutUserInput;
  update: UserTokensUpdateWithoutUserInput;
  where: UserTokensWhereUniqueInput;
};

export type UserTokensWhereInput = {
  AND?: InputMaybe<Array<UserTokensWhereInput>>;
  NOT?: InputMaybe<Array<UserTokensWhereInput>>;
  OR?: InputMaybe<Array<UserTokensWhereInput>>;
  id?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTokensWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutCommentReactionsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentReactionsInput>;
  create?: InputMaybe<UserCreateWithoutCommentReactionsInput>;
  update?: InputMaybe<UserUpdateWithoutCommentReactionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentReactionsInput>;
};

export type UserUpdateOneRequiredWithoutCommentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
  update?: InputMaybe<UserUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentsInput>;
};

export type UserUpdateOneRequiredWithoutLikesByUserNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLikesByUserInput>;
  create?: InputMaybe<UserCreateWithoutLikesByUserInput>;
  update?: InputMaybe<UserUpdateWithoutLikesByUserInput>;
  upsert?: InputMaybe<UserUpsertWithoutLikesByUserInput>;
};

export type UserUpdateOneRequiredWithoutUserTokensNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTokensInput>;
  create?: InputMaybe<UserCreateWithoutUserTokensInput>;
  update?: InputMaybe<UserUpdateWithoutUserTokensInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserTokensInput>;
};

export type UserUpdateOneWithoutCreatedNotificationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedNotificationsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCreatedNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedNotificationsInput>;
};

export type UserUpdateOneWithoutMyNotificationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMyNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutMyNotificationsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutMyNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutMyNotificationsInput>;
};

export type UserUpdateOneWithoutPostNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPostInput>;
  create?: InputMaybe<UserCreateWithoutPostInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutPostInput>;
  upsert?: InputMaybe<UserUpsertWithoutPostInput>;
};

export type UserUpdateOneWithoutSocilaLinksNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSocilaLinksInput>;
  create?: InputMaybe<UserCreateWithoutSocilaLinksInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSocilaLinksInput>;
  upsert?: InputMaybe<UserUpsertWithoutSocilaLinksInput>;
};

export type UserUpdateWithoutCommentReactionsInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCommentsInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCreatedNotificationsInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLikesByUserInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutMyNotificationsInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPostInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSocilaLinksInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userTokens?: InputMaybe<UserTokensUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutUserTokensInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentReactions?: InputMaybe<CommentReactionUpdateManyWithoutUserNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  cover?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesByUser?: InputMaybe<LikesByUserUpdateManyWithoutUserNestedInput>;
  myNotifications?: InputMaybe<NotificationUpdateManyWithoutCreatedForNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutUserNestedInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socilaLinks?: InputMaybe<UserSocilaLinksUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserAccountStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutCommentReactionsInput = {
  create: UserCreateWithoutCommentReactionsInput;
  update: UserUpdateWithoutCommentReactionsInput;
};

export type UserUpsertWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  update: UserUpdateWithoutCommentsInput;
};

export type UserUpsertWithoutCreatedNotificationsInput = {
  create: UserCreateWithoutCreatedNotificationsInput;
  update: UserUpdateWithoutCreatedNotificationsInput;
};

export type UserUpsertWithoutLikesByUserInput = {
  create: UserCreateWithoutLikesByUserInput;
  update: UserUpdateWithoutLikesByUserInput;
};

export type UserUpsertWithoutMyNotificationsInput = {
  create: UserCreateWithoutMyNotificationsInput;
  update: UserUpdateWithoutMyNotificationsInput;
};

export type UserUpsertWithoutPostInput = {
  create: UserCreateWithoutPostInput;
  update: UserUpdateWithoutPostInput;
};

export type UserUpsertWithoutSocilaLinksInput = {
  create: UserCreateWithoutSocilaLinksInput;
  update: UserUpdateWithoutSocilaLinksInput;
};

export type UserUpsertWithoutUserTokensInput = {
  create: UserCreateWithoutUserTokensInput;
  update: UserUpdateWithoutUserTokensInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  address?: InputMaybe<StringFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  commentReactions?: InputMaybe<CommentReactionListRelationFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  cover?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdNotifications?: InputMaybe<NotificationListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  likesByUser?: InputMaybe<LikesByUserListRelationFilter>;
  myNotifications?: InputMaybe<NotificationListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostListRelationFilter>;
  professionalName?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumUserRoleFilter>;
  socilaLinks?: InputMaybe<UserSocilaLinksRelationFilter>;
  status?: InputMaybe<EnumUserAccountStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userTokens?: InputMaybe<UserTokensListRelationFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type WebNotification = {
  __typename?: 'WebNotification';
  createdAt: Scalars['DateTime']['output'];
  desc: Scalars['String']['output'];
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type WebNotificationCountAggregate = {
  __typename?: 'WebNotificationCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  desc: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  link: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
};

export type WebNotificationCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type WebNotificationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  desc: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type WebNotificationCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  desc: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type WebNotificationGroupBy = {
  __typename?: 'WebNotificationGroupBy';
  _count?: Maybe<WebNotificationCountAggregate>;
  _max?: Maybe<WebNotificationMaxAggregate>;
  _min?: Maybe<WebNotificationMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  desc: Scalars['String']['output'];
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type WebNotificationMaxAggregate = {
  __typename?: 'WebNotificationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type WebNotificationMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type WebNotificationMinAggregate = {
  __typename?: 'WebNotificationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type WebNotificationMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum WebNotificationOrderByRelevanceFieldEnum {
  Desc = 'desc',
  Id = 'id',
  Link = 'link',
  Title = 'title'
}

export type WebNotificationOrderByRelevanceInput = {
  fields: Array<WebNotificationOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type WebNotificationOrderByWithAggregationInput = {
  _count?: InputMaybe<WebNotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<WebNotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<WebNotificationMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type WebNotificationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<WebNotificationOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum WebNotificationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Desc = 'desc',
  Id = 'id',
  Link = 'link',
  Title = 'title'
}

export type WebNotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<WebNotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<WebNotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<WebNotificationScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  desc?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  link?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
};

export type WebNotificationUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  desc?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  link?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WebNotificationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  desc?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  link?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WebNotificationWhereInput = {
  AND?: InputMaybe<Array<WebNotificationWhereInput>>;
  NOT?: InputMaybe<Array<WebNotificationWhereInput>>;
  OR?: InputMaybe<Array<WebNotificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  desc?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  link?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type WebNotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomTypeForUserUpdate = {
  newPass?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  updatePass?: InputMaybe<Scalars['String']['input']>;
};

export type DefaultResponsce = {
  __typename?: 'defaultResponsce';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ProfileUpdateInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  professionalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type HomePageAdsQueryVariables = Exact<{
  homePageAdsWhere2?: InputMaybe<HomePageAdWhereInput>;
  orderBy?: InputMaybe<Array<HomePageAdOrderByWithRelationAndSearchRelevanceInput> | HomePageAdOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<HomePageAdWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<HomePageAdScalarFieldEnum> | HomePageAdScalarFieldEnum>;
}>;


export type HomePageAdsQuery = { __typename?: 'Query', homePageAds: Array<{ __typename?: 'HomePageAd', customHtml: boolean, html: string, id: string, imageUrl: string, redirectUrl: string }> };

export type SubCategoryPageAdQueryVariables = Exact<{
  where: SubCategoryPageAdWhereUniqueInput;
}>;


export type SubCategoryPageAdQuery = { __typename?: 'Query', subCategoryPageAd?: { __typename?: 'SubCategoryPageAd', customHtml: boolean, html: string, id: string, imageUrl: string, redirectUrl: string, subcategoryId: string } | null };

export type CategoryPageAdQueryVariables = Exact<{
  where: CategoryPageAdWhereUniqueInput;
}>;


export type CategoryPageAdQuery = { __typename?: 'Query', categoryPageAd?: { __typename?: 'CategoryPageAd', categoryId: string, customHtml: boolean, html: string, id: string, imageUrl: string, redirectUrl: string } | null };

export type PostPageAdQueryVariables = Exact<{
  where: PostPageAdWhereUniqueInput;
}>;


export type PostPageAdQuery = { __typename?: 'Query', postPageAd?: { __typename?: 'PostPageAd', customHtml: boolean, html: string, id: string, imageUrl: string, postId: string, redirectUrl: string } | null };

export type CategoriesForHomePageQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput> | CategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
}>;


export type CategoriesForHomePageQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', category: string, position: number, postSectionType: CategorySectionType, postSectionPosition: number, slug: string, id: string }> };

export type CategoryQueryVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;


export type CategoryQuery = { __typename?: 'Query', category?: { __typename?: 'Category', category: string, categoryPageKeyWords: string, categoryPageTitle: string, categoryText: string, createdAt: any, slug: string, id: string } | null };

export type CategoriesForMenuQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput> | CategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
}>;


export type CategoriesForMenuQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', addToMenu: boolean, category: string, id: string, position: number, slug: string, subcategory: Array<{ __typename?: 'SubCategory', id: string, subCategoryName: string, slug: string }> }> };

export type CategoryMetaQueryVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;


export type CategoryMetaQuery = { __typename?: 'Query', category?: { __typename?: 'Category', categoryPageKeyWords: string, categoryPageTitle: string, categoryText: string, slug: string, image?: { __typename?: 'Media', type: string, url: string, title?: string | null } | null } | null };

export type CategoriesForSelectQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput> | CategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
}>;


export type CategoriesForSelectQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, category: string }> };

export type CategoriesForFooterQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationAndSearchRelevanceInput> | CategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
  subcategoryWhere2?: InputMaybe<SubCategoryWhereInput>;
  subcategoryOrderBy2?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput> | SubCategoryOrderByWithRelationAndSearchRelevanceInput>;
  subcategoryCursor2?: InputMaybe<SubCategoryWhereUniqueInput>;
  subcategoryTake2?: InputMaybe<Scalars['Int']['input']>;
  subcategorySkip2?: InputMaybe<Scalars['Int']['input']>;
  subcategoryDistinct2?: InputMaybe<Array<SubCategoryScalarFieldEnum> | SubCategoryScalarFieldEnum>;
}>;


export type CategoriesForFooterQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', category: string, slug: string, subcategory: Array<{ __typename?: 'SubCategory', subCategoryName: string, slug: string }> }> };

export type CommentsQueryVariables = Exact<{
  where?: InputMaybe<CommentWhereInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput> | CommentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum> | CommentScalarFieldEnum>;
  commentReactionsWhere2?: InputMaybe<CommentReactionWhereInput>;
}>;


export type CommentsQuery = { __typename?: 'Query', comments: Array<{ __typename?: 'Comment', id: string, mainComment: boolean, text: string, _count?: { __typename?: 'CommentCount', commentReactions: number, repliedComments: number } | null, user: { __typename?: 'User', avatar?: string | null, name: string, id: string } }> };

export type CommentReactionCountQueryVariables = Exact<{
  where: CommentWhereUniqueInput;
}>;


export type CommentReactionCountQuery = { __typename?: 'Query', comment?: { __typename?: 'Comment', _count?: { __typename?: 'CommentCount', commentReactions: number } | null } | null };

export type AggregateCommentQueryVariables = Exact<{
  where?: InputMaybe<CommentWhereInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationAndSearchRelevanceInput> | CommentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateCommentQuery = { __typename?: 'Query', aggregateComment: { __typename?: 'AggregateComment', _count?: { __typename?: 'CommentCountAggregate', _all: number } | null } };

export type CreateOneCommentMutationVariables = Exact<{
  data: CommentCreateInput;
}>;


export type CreateOneCommentMutation = { __typename?: 'Mutation', createOneComment: { __typename?: 'Comment', id: string } };

export type UpdateOneCommentMutationVariables = Exact<{
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
}>;


export type UpdateOneCommentMutation = { __typename?: 'Mutation', updateOneComment?: { __typename?: 'Comment', id: string } | null };

export type DeleteOneCommentMutationVariables = Exact<{
  where: CommentWhereUniqueInput;
}>;


export type DeleteOneCommentMutation = { __typename?: 'Mutation', deleteOneComment?: { __typename?: 'Comment', id: string } | null };

export type LikeOnCommentMutationVariables = Exact<{
  likeOnCommentId: Scalars['String']['input'];
}>;


export type LikeOnCommentMutation = { __typename?: 'Mutation', likeOnComment?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type RemoveLikeFromCommentMutationVariables = Exact<{
  removeLikeFromCommentId: Scalars['String']['input'];
}>;


export type RemoveLikeFromCommentMutation = { __typename?: 'Mutation', removeLikeFromComment?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type LoggedInUserLikedThisCommentCheckQueryVariables = Exact<{
  where?: InputMaybe<CommentReactionWhereInput>;
  orderBy?: InputMaybe<Array<CommentReactionOrderByWithRelationAndSearchRelevanceInput> | CommentReactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<CommentReactionWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CommentReactionScalarFieldEnum> | CommentReactionScalarFieldEnum>;
}>;


export type LoggedInUserLikedThisCommentCheckQuery = { __typename?: 'Query', commentReactions: Array<{ __typename?: 'CommentReaction', id: string }> };

export type DeleteManyCommentMutationVariables = Exact<{
  where?: InputMaybe<CommentWhereInput>;
}>;


export type DeleteManyCommentMutation = { __typename?: 'Mutation', deleteManyComment: { __typename?: 'AffectedRowsOutput', count: number } };

export type FindManyMediaQueryVariables = Exact<{
  where?: InputMaybe<MediaWhereInput>;
  orderBy?: InputMaybe<Array<MediaOrderByWithRelationAndSearchRelevanceInput> | MediaOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<MediaWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<MediaScalarFieldEnum> | MediaScalarFieldEnum>;
}>;


export type FindManyMediaQuery = { __typename?: 'Query', findManyMedia: Array<{ __typename?: 'Media', filename: string, id: string, type: string, url: string }> };

export type UploadFileMutationVariables = Exact<{
  mediaCatIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  title: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile?: { __typename?: 'Media', url: string, type: string, title?: string | null, id: string, filename: string } | null };

export type UserNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationsQuery = { __typename?: 'Query', userNotifications?: Array<{ __typename?: 'Notification', createdAt: any, id: string, status: NotificationStatus, text: string, type: NotificationType, url: string, createdBy?: { __typename?: 'User', avatar?: string | null, name: string } | null }> | null };

export type NotificationUnreadQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationUnreadQuery = { __typename?: 'Query', notificationUnread?: number | null };

export type NewNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewNotificationSubscription = { __typename?: 'Subscription', newNotification: { __typename?: 'Notification', createdAt: any, id: string, status: NotificationStatus, text: string, type: NotificationType, url: string, createdBy?: { __typename?: 'User', avatar?: string | null, name: string } | null } };

export type UnreadNotificationCountUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UnreadNotificationCountUpdatedSubscription = { __typename?: 'Subscription', unreadNotificationCountUpdated: { __typename?: 'UnReadNotificationCount', count: number } };

export type PostsQueryVariables = Exact<{
  where?: InputMaybe<PostWhereInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput> | PostOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<PostWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum> | PostScalarFieldEnum>;
}>;


export type PostsQuery = { __typename?: 'Query', posts: Array<{ __typename?: 'Post', pageTitle?: string | null, postText: string, postitle: string, slug: string, socialShare?: boolean | null, video?: string | null, haveVideo: boolean, id: string, _count?: { __typename?: 'PostCount', likesByUser: number, comments: number } | null, image?: { __typename?: 'Media', filename: string, title?: string | null, type: string, url: string } | null, tag: Array<{ __typename?: 'Tag', name: string, slug: string }>, subcategory?: { __typename?: 'SubCategory', slug: string, subCategoryName: string } | null, category?: { __typename?: 'Category', category: string, slug: string, postSectionType: CategorySectionType } | null }> };

export type PostsForUrlQueryVariables = Exact<{
  where?: InputMaybe<PostWhereInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput> | PostOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<PostWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum> | PostScalarFieldEnum>;
}>;


export type PostsForUrlQuery = { __typename?: 'Query', posts: Array<{ __typename?: 'Post', slug: string, updatedAt: any, subcategory?: { __typename?: 'SubCategory', slug: string } | null, category?: { __typename?: 'Category', slug: string } | null }> };

export type PostsForCategorySectionViewQueryVariables = Exact<{
  where?: InputMaybe<PostWhereInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput> | PostOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<PostWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum> | PostScalarFieldEnum>;
}>;


export type PostsForCategorySectionViewQuery = { __typename?: 'Query', posts: Array<{ __typename?: 'Post', id: string, postText: string, updatedAt: any, createdAt: any, postitle: string, slug: string, video?: string | null, haveVideo: boolean, _count?: { __typename?: 'PostCount', likesByUser: number, comments: number } | null, image?: { __typename?: 'Media', title?: string | null, type: string, url: string } | null, subcategory?: { __typename?: 'SubCategory', slug: string, subCategoryName: string } | null, category?: { __typename?: 'Category', category: string, slug: string, postSectionType: CategorySectionType } | null, user?: { __typename?: 'User', id: string, professionalName: string, name: string, username: string } | null }> };

export type PostsForCategoryMenuViewQueryVariables = Exact<{
  where?: InputMaybe<PostWhereInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput> | PostOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<PostWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum> | PostScalarFieldEnum>;
}>;


export type PostsForCategoryMenuViewQuery = { __typename?: 'Query', posts: Array<{ __typename?: 'Post', postitle: string, slug: string, subcategory?: { __typename?: 'SubCategory', slug: string } | null, category?: { __typename?: 'Category', slug: string } | null, image?: { __typename?: 'Media', url: string } | null }> };

export type PostQueryVariables = Exact<{
  where: PostWhereUniqueInput;
}>;


export type PostQuery = { __typename?: 'Query', post?: { __typename?: 'Post', description: string, pageDescription?: string | null, createdAt: any, updatedAt: any, keyWords?: string | null, postText: string, hideAdsOnThisArtical: boolean, isLive?: boolean | null, noIndex?: boolean | null, postitle: string, haveVideo: boolean, id: string, slug: string, socialShare?: boolean | null, subHeading?: string | null, video?: string | null, webNotification: boolean, _count?: { __typename?: 'PostCount', likesByUser: number, comments: number } | null, category?: { __typename?: 'Category', id: string, category: string, slug: string, postSectionType: CategorySectionType } | null, image?: { __typename?: 'Media', type: string, url: string, title?: string | null } | null, subcategory?: { __typename?: 'SubCategory', id: string, subCategoryName: string, slug: string } | null, tag: Array<{ __typename?: 'Tag', id: string, name: string, slug: string }>, user?: { __typename?: 'User', id: string, avatar?: string | null, professionalName: string, name: string, bio?: string | null, username: string } | null } | null };

export type PostLikeCountQueryVariables = Exact<{
  where: PostWhereUniqueInput;
}>;


export type PostLikeCountQuery = { __typename?: 'Query', post?: { __typename?: 'Post', _count?: { __typename?: 'PostCount', likesByUser: number } | null } | null };

export type PostCommentCountQueryVariables = Exact<{
  where: PostWhereUniqueInput;
  commentsWhere2?: InputMaybe<CommentWhereInput>;
}>;


export type PostCommentCountQuery = { __typename?: 'Query', post?: { __typename?: 'Post', _count?: { __typename?: 'PostCount', comments: number } | null } | null };

export type PostMetaQueryVariables = Exact<{
  where: PostWhereUniqueInput;
}>;


export type PostMetaQuery = { __typename?: 'Query', post?: { __typename?: 'Post', slug: string, pageTitle?: string | null, pageDescription?: string | null, keyWords?: string | null, image?: { __typename?: 'Media', title?: string | null, type: string, url: string } | null } | null };

export type AggregatePostQueryVariables = Exact<{
  where?: InputMaybe<PostWhereInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput> | PostOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<PostWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregatePostQuery = { __typename?: 'Query', aggregatePost: { __typename?: 'AggregatePost', _count?: { __typename?: 'PostCountAggregate', id: number } | null } };

export type PostsForContributorQueryVariables = Exact<{
  where?: InputMaybe<PostWhereInput>;
  orderBy?: InputMaybe<Array<PostOrderByWithRelationAndSearchRelevanceInput> | PostOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<PostWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PostScalarFieldEnum> | PostScalarFieldEnum>;
}>;


export type PostsForContributorQuery = { __typename?: 'Query', posts: Array<{ __typename?: 'Post', id: string, postText: string, updatedAt: any, status: PostStatus, postitle: string, slug: string, video?: string | null, haveVideo: boolean, image?: { __typename?: 'Media', filename: string, title?: string | null, type: string, url: string } | null, tag: Array<{ __typename?: 'Tag', name: string, slug: string }>, subcategory?: { __typename?: 'SubCategory', slug: string, subCategoryName: string } | null, category?: { __typename?: 'Category', category: string, slug: string } | null }> };

export type CreatePostByContributorMutationVariables = Exact<{
  input: ContributorPostCreateInput;
  taxonomyAndMedia: TaxonomyAndMedia;
}>;


export type CreatePostByContributorMutation = { __typename?: 'Mutation', createPostByContributor?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type RemoveLikeFromPostMutationVariables = Exact<{
  removeLikeFromPostId: Scalars['String']['input'];
}>;


export type RemoveLikeFromPostMutation = { __typename?: 'Mutation', removeLikeFromPost?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type LikeOnPostMutationVariables = Exact<{
  likeOnPostId: Scalars['String']['input'];
}>;


export type LikeOnPostMutation = { __typename?: 'Mutation', likeOnPost?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type LikesByCurrentUserCheckQueryVariables = Exact<{
  where?: InputMaybe<LikesByUserWhereInput>;
}>;


export type LikesByCurrentUserCheckQuery = { __typename?: 'Query', likesByUsers: Array<{ __typename?: 'LikesByUser', id: string }> };

export type PostDataForUpdateQueryVariables = Exact<{
  where: PostWhereUniqueInput;
}>;


export type PostDataForUpdateQuery = { __typename?: 'Query', post?: { __typename?: 'Post', description: string, pageDescription?: string | null, pageTitle?: string | null, keyWords?: string | null, postText: string, postitle: string, id: string, slug: string, subHeading?: string | null, category?: { __typename?: 'Category', id: string, category: string, slug: string } | null, image?: { __typename?: 'Media', type: string, url: string, title?: string | null, id: string } | null, subcategory?: { __typename?: 'SubCategory', id: string, subCategoryName: string, slug: string } | null, tag: Array<{ __typename?: 'Tag', id: string, name: string, slug: string }> } | null };

export type UpdateOnePostMutationVariables = Exact<{
  data: PostUpdateInput;
  where: PostWhereUniqueInput;
}>;


export type UpdateOnePostMutation = { __typename?: 'Mutation', updateOnePost?: { __typename?: 'Post', id: string } | null };

export type DeleteOnePostMutationVariables = Exact<{
  where: PostWhereUniqueInput;
}>;


export type DeleteOnePostMutation = { __typename?: 'Mutation', deleteOnePost?: { __typename?: 'Post', id: string } | null };

export type LikesByUsersQueryVariables = Exact<{
  where?: InputMaybe<LikesByUserWhereInput>;
  orderBy?: InputMaybe<Array<LikesByUserOrderByWithRelationAndSearchRelevanceInput> | LikesByUserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<LikesByUserWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<LikesByUserScalarFieldEnum> | LikesByUserScalarFieldEnum>;
}>;


export type LikesByUsersQuery = { __typename?: 'Query', likesByUsers: Array<{ __typename?: 'LikesByUser', post: { __typename?: 'Post', id: string, postText: string, updatedAt: any, status: PostStatus, postitle: string, slug: string, video?: string | null, haveVideo: boolean, image?: { __typename?: 'Media', filename: string, title?: string | null, type: string, url: string } | null, tag: Array<{ __typename?: 'Tag', name: string, slug: string }>, subcategory?: { __typename?: 'SubCategory', slug: string, subCategoryName: string } | null, category?: { __typename?: 'Category', category: string, slug: string } | null } }> };

export type SiteinfosQueryVariables = Exact<{ [key: string]: never; }>;


export type SiteinfosQuery = { __typename?: 'Query', siteinfos: Array<{ __typename?: 'Siteinfo', tags: string, title: string, description: string, id: string }> };

export type SubCategoriesQueryVariables = Exact<{
  where?: InputMaybe<SubCategoryWhereInput>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput> | SubCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum> | SubCategoryScalarFieldEnum>;
}>;


export type SubCategoriesQuery = { __typename?: 'Query', subCategories: Array<{ __typename?: 'SubCategory', id: string, slug: string, subCategoryName: string, _count?: { __typename?: 'SubCategoryCount', post: number } | null }> };

export type SubCategoryQueryVariables = Exact<{
  where: SubCategoryWhereUniqueInput;
}>;


export type SubCategoryQuery = { __typename?: 'Query', subCategory?: { __typename?: 'SubCategory', id: string, slug: string, subCategoryName: string, subCategoryPageKeyWords: string, subCategoryPageTitle: string, subCategoryText: string, _count?: { __typename?: 'SubCategoryCount', post: number } | null, category: { __typename?: 'Category', category: string, slug: string } } | null };

export type SubCategoryMetaQueryVariables = Exact<{
  where: SubCategoryWhereUniqueInput;
}>;


export type SubCategoryMetaQuery = { __typename?: 'Query', subCategory?: { __typename?: 'SubCategory', slug: string, subCategoryPageKeyWords: string, subCategoryPageTitle: string, subCategoryText: string } | null };

export type SubCategoriesForSelectQueryVariables = Exact<{
  where?: InputMaybe<SubCategoryWhereInput>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput> | SubCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum> | SubCategoryScalarFieldEnum>;
}>;


export type SubCategoriesForSelectQuery = { __typename?: 'Query', subCategories: Array<{ __typename?: 'SubCategory', id: string, subCategoryName: string }> };

export type SubCategoriesForRssQueryVariables = Exact<{
  where?: InputMaybe<SubCategoryWhereInput>;
  orderBy?: InputMaybe<Array<SubCategoryOrderByWithRelationAndSearchRelevanceInput> | SubCategoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<SubCategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SubCategoryScalarFieldEnum> | SubCategoryScalarFieldEnum>;
}>;


export type SubCategoriesForRssQuery = { __typename?: 'Query', subCategories: Array<{ __typename?: 'SubCategory', subCategoryName: string, slug: string, category: { __typename?: 'Category', category: string, slug: string } }> };

export type UpsertOneSubscriberMutationVariables = Exact<{
  where: SubscriberWhereUniqueInput;
  create: SubscriberCreateInput;
  update: SubscriberUpdateInput;
}>;


export type UpsertOneSubscriberMutation = { __typename?: 'Mutation', upsertOneSubscriber: { __typename?: 'Subscriber', id: string } };

export type TagsQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput> | TagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<TagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum> | TagScalarFieldEnum>;
}>;


export type TagsQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', name: string, slug: string, _count?: { __typename?: 'TagCount', posts: number } | null }> };

export type TagQueryVariables = Exact<{
  where: TagWhereUniqueInput;
}>;


export type TagQuery = { __typename?: 'Query', tag?: { __typename?: 'Tag', id: string, name: string, slug: string, _count?: { __typename?: 'TagCount', posts: number } | null } | null };

export type AggregateTagQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput> | TagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<TagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateTagQuery = { __typename?: 'Query', aggregateTag: { __typename?: 'AggregateTag', _count?: { __typename?: 'TagCountAggregate', id: number } | null } };

export type TagsForUrlQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput> | TagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<TagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum> | TagScalarFieldEnum>;
}>;


export type TagsForUrlQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', slug: string }> };

export type TagsForSelectQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationAndSearchRelevanceInput> | TagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<TagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum> | TagScalarFieldEnum>;
}>;


export type TagsForSelectQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', id: string, name: string }> };

export type CreateOneTagMutationVariables = Exact<{
  data: TagCreateInput;
}>;


export type CreateOneTagMutation = { __typename?: 'Mutation', createOneTag: { __typename?: 'Tag', id: string, name: string } };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput> | UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: InputMaybe<UserWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum> | UserScalarFieldEnum>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', name: string, professionalName: string, role: UserRole }> };

export type UserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', bio?: string | null, avatar?: string | null, name: string, professionalName: string, socilaLinks?: { __typename?: 'UserSocilaLinks', facebook?: string | null, linkedIn?: string | null, twitter?: string | null } | null } | null };

export type RegisterMutationVariables = Exact<{
  input: CreateOneUserArgsCustom;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UserForResponsce', role?: string | null, id?: string | null, name?: string | null, email?: string | null, status: string } | null };

export type LoginMutationVariables = Exact<{
  password: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'LoginResponsce', success: boolean, message: string, isAuthenticated: boolean, accessToken?: string | null, expiresIn: number, refreshToken: string, user?: { __typename?: 'UserForResponsce', role?: string | null, id?: string | null, name?: string | null, email?: string | null, status: string } | null } | null };

export type UpdateProfileMutationVariables = Exact<{
  security: CustomTypeForUserUpdate;
  input: ProfileUpdateInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type ForgetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgetPasswordMutation = { __typename?: 'Mutation', forgetPassword?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type PasswordResetTokenVerifyMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type PasswordResetTokenVerifyMutation = { __typename?: 'Mutation', passwordResetTokenVerify?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type ResetPassByVerficationLinkMutationVariables = Exact<{
  input: ReesetPassByLinkInput;
}>;


export type ResetPassByVerficationLinkMutation = { __typename?: 'Mutation', resetPassByVerficationLink?: { __typename?: 'defaultResponsce', success: boolean, message: string } | null };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'RefreshTokenResponsce', expiresIn?: number | null, message: string, success: boolean, accessToken?: string | null } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };

export type UserDataForProfileUpdateQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type UserDataForProfileUpdateQuery = { __typename?: 'Query', user?: { __typename?: 'User', bio?: string | null, avatar?: string | null, email: string, name: string, professionalName: string, username: string, address: string, socilaLinks?: { __typename?: 'UserSocilaLinks', facebook?: string | null, linkedIn?: string | null, twitter?: string | null } | null } | null };

export type ContactUsMutationVariables = Exact<{
  input: ContactUsInput;
}>;


export type ContactUsMutation = { __typename?: 'Mutation', contactUs?: { __typename?: 'defaultResponsce', message: string, success: boolean } | null };


export const HomePageAdsDocument = gql`
    query HomePageAds($homePageAdsWhere2: HomePageAdWhereInput, $orderBy: [HomePageAdOrderByWithRelationAndSearchRelevanceInput!], $cursor: HomePageAdWhereUniqueInput, $take: Int, $skip: Int, $distinct: [HomePageAdScalarFieldEnum!]) {
  homePageAds(
    where: $homePageAdsWhere2
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    customHtml
    html
    id
    imageUrl
    redirectUrl
  }
}
    `;

/**
 * __useHomePageAdsQuery__
 *
 * To run a query within a React component, call `useHomePageAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageAdsQuery({
 *   variables: {
 *      homePageAdsWhere2: // value for 'homePageAdsWhere2'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useHomePageAdsQuery(baseOptions?: Apollo.QueryHookOptions<HomePageAdsQuery, HomePageAdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePageAdsQuery, HomePageAdsQueryVariables>(HomePageAdsDocument, options);
      }
export function useHomePageAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageAdsQuery, HomePageAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePageAdsQuery, HomePageAdsQueryVariables>(HomePageAdsDocument, options);
        }
export function useHomePageAdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HomePageAdsQuery, HomePageAdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomePageAdsQuery, HomePageAdsQueryVariables>(HomePageAdsDocument, options);
        }
export type HomePageAdsQueryHookResult = ReturnType<typeof useHomePageAdsQuery>;
export type HomePageAdsLazyQueryHookResult = ReturnType<typeof useHomePageAdsLazyQuery>;
export type HomePageAdsSuspenseQueryHookResult = ReturnType<typeof useHomePageAdsSuspenseQuery>;
export type HomePageAdsQueryResult = Apollo.QueryResult<HomePageAdsQuery, HomePageAdsQueryVariables>;
export const SubCategoryPageAdDocument = gql`
    query SubCategoryPageAd($where: SubCategoryPageAdWhereUniqueInput!) {
  subCategoryPageAd(where: $where) {
    customHtml
    html
    id
    imageUrl
    redirectUrl
    subcategoryId
  }
}
    `;

/**
 * __useSubCategoryPageAdQuery__
 *
 * To run a query within a React component, call `useSubCategoryPageAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCategoryPageAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCategoryPageAdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubCategoryPageAdQuery(baseOptions: Apollo.QueryHookOptions<SubCategoryPageAdQuery, SubCategoryPageAdQueryVariables> & ({ variables: SubCategoryPageAdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCategoryPageAdQuery, SubCategoryPageAdQueryVariables>(SubCategoryPageAdDocument, options);
      }
export function useSubCategoryPageAdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCategoryPageAdQuery, SubCategoryPageAdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCategoryPageAdQuery, SubCategoryPageAdQueryVariables>(SubCategoryPageAdDocument, options);
        }
export function useSubCategoryPageAdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubCategoryPageAdQuery, SubCategoryPageAdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubCategoryPageAdQuery, SubCategoryPageAdQueryVariables>(SubCategoryPageAdDocument, options);
        }
export type SubCategoryPageAdQueryHookResult = ReturnType<typeof useSubCategoryPageAdQuery>;
export type SubCategoryPageAdLazyQueryHookResult = ReturnType<typeof useSubCategoryPageAdLazyQuery>;
export type SubCategoryPageAdSuspenseQueryHookResult = ReturnType<typeof useSubCategoryPageAdSuspenseQuery>;
export type SubCategoryPageAdQueryResult = Apollo.QueryResult<SubCategoryPageAdQuery, SubCategoryPageAdQueryVariables>;
export const CategoryPageAdDocument = gql`
    query CategoryPageAd($where: CategoryPageAdWhereUniqueInput!) {
  categoryPageAd(where: $where) {
    categoryId
    customHtml
    html
    id
    imageUrl
    redirectUrl
  }
}
    `;

/**
 * __useCategoryPageAdQuery__
 *
 * To run a query within a React component, call `useCategoryPageAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryPageAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryPageAdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCategoryPageAdQuery(baseOptions: Apollo.QueryHookOptions<CategoryPageAdQuery, CategoryPageAdQueryVariables> & ({ variables: CategoryPageAdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryPageAdQuery, CategoryPageAdQueryVariables>(CategoryPageAdDocument, options);
      }
export function useCategoryPageAdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryPageAdQuery, CategoryPageAdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryPageAdQuery, CategoryPageAdQueryVariables>(CategoryPageAdDocument, options);
        }
export function useCategoryPageAdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoryPageAdQuery, CategoryPageAdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryPageAdQuery, CategoryPageAdQueryVariables>(CategoryPageAdDocument, options);
        }
export type CategoryPageAdQueryHookResult = ReturnType<typeof useCategoryPageAdQuery>;
export type CategoryPageAdLazyQueryHookResult = ReturnType<typeof useCategoryPageAdLazyQuery>;
export type CategoryPageAdSuspenseQueryHookResult = ReturnType<typeof useCategoryPageAdSuspenseQuery>;
export type CategoryPageAdQueryResult = Apollo.QueryResult<CategoryPageAdQuery, CategoryPageAdQueryVariables>;
export const PostPageAdDocument = gql`
    query PostPageAd($where: PostPageAdWhereUniqueInput!) {
  postPageAd(where: $where) {
    customHtml
    html
    id
    imageUrl
    postId
    redirectUrl
  }
}
    `;

/**
 * __usePostPageAdQuery__
 *
 * To run a query within a React component, call `usePostPageAdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostPageAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostPageAdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostPageAdQuery(baseOptions: Apollo.QueryHookOptions<PostPageAdQuery, PostPageAdQueryVariables> & ({ variables: PostPageAdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostPageAdQuery, PostPageAdQueryVariables>(PostPageAdDocument, options);
      }
export function usePostPageAdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostPageAdQuery, PostPageAdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostPageAdQuery, PostPageAdQueryVariables>(PostPageAdDocument, options);
        }
export function usePostPageAdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostPageAdQuery, PostPageAdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostPageAdQuery, PostPageAdQueryVariables>(PostPageAdDocument, options);
        }
export type PostPageAdQueryHookResult = ReturnType<typeof usePostPageAdQuery>;
export type PostPageAdLazyQueryHookResult = ReturnType<typeof usePostPageAdLazyQuery>;
export type PostPageAdSuspenseQueryHookResult = ReturnType<typeof usePostPageAdSuspenseQuery>;
export type PostPageAdQueryResult = Apollo.QueryResult<PostPageAdQuery, PostPageAdQueryVariables>;
export const CategoriesForHomePageDocument = gql`
    query CategoriesForHomePage($where: CategoryWhereInput, $orderBy: [CategoryOrderByWithRelationAndSearchRelevanceInput!], $cursor: CategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CategoryScalarFieldEnum!]) {
  categories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    category
    position
    postSectionType
    postSectionPosition
    slug
    id
  }
}
    `;

/**
 * __useCategoriesForHomePageQuery__
 *
 * To run a query within a React component, call `useCategoriesForHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForHomePageQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useCategoriesForHomePageQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesForHomePageQuery, CategoriesForHomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesForHomePageQuery, CategoriesForHomePageQueryVariables>(CategoriesForHomePageDocument, options);
      }
export function useCategoriesForHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesForHomePageQuery, CategoriesForHomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesForHomePageQuery, CategoriesForHomePageQueryVariables>(CategoriesForHomePageDocument, options);
        }
export function useCategoriesForHomePageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoriesForHomePageQuery, CategoriesForHomePageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoriesForHomePageQuery, CategoriesForHomePageQueryVariables>(CategoriesForHomePageDocument, options);
        }
export type CategoriesForHomePageQueryHookResult = ReturnType<typeof useCategoriesForHomePageQuery>;
export type CategoriesForHomePageLazyQueryHookResult = ReturnType<typeof useCategoriesForHomePageLazyQuery>;
export type CategoriesForHomePageSuspenseQueryHookResult = ReturnType<typeof useCategoriesForHomePageSuspenseQuery>;
export type CategoriesForHomePageQueryResult = Apollo.QueryResult<CategoriesForHomePageQuery, CategoriesForHomePageQueryVariables>;
export const CategoryDocument = gql`
    query Category($where: CategoryWhereUniqueInput!) {
  category(where: $where) {
    category
    categoryPageKeyWords
    categoryPageTitle
    categoryText
    createdAt
    slug
    id
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables> & ({ variables: CategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export function useCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategorySuspenseQueryHookResult = ReturnType<typeof useCategorySuspenseQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const CategoriesForMenuDocument = gql`
    query CategoriesForMenu($where: CategoryWhereInput, $orderBy: [CategoryOrderByWithRelationAndSearchRelevanceInput!], $cursor: CategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CategoryScalarFieldEnum!]) {
  categories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    addToMenu
    category
    id
    position
    subcategory {
      id
      subCategoryName
      slug
    }
    slug
  }
}
    `;

/**
 * __useCategoriesForMenuQuery__
 *
 * To run a query within a React component, call `useCategoriesForMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForMenuQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useCategoriesForMenuQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesForMenuQuery, CategoriesForMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesForMenuQuery, CategoriesForMenuQueryVariables>(CategoriesForMenuDocument, options);
      }
export function useCategoriesForMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesForMenuQuery, CategoriesForMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesForMenuQuery, CategoriesForMenuQueryVariables>(CategoriesForMenuDocument, options);
        }
export function useCategoriesForMenuSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoriesForMenuQuery, CategoriesForMenuQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoriesForMenuQuery, CategoriesForMenuQueryVariables>(CategoriesForMenuDocument, options);
        }
export type CategoriesForMenuQueryHookResult = ReturnType<typeof useCategoriesForMenuQuery>;
export type CategoriesForMenuLazyQueryHookResult = ReturnType<typeof useCategoriesForMenuLazyQuery>;
export type CategoriesForMenuSuspenseQueryHookResult = ReturnType<typeof useCategoriesForMenuSuspenseQuery>;
export type CategoriesForMenuQueryResult = Apollo.QueryResult<CategoriesForMenuQuery, CategoriesForMenuQueryVariables>;
export const CategoryMetaDocument = gql`
    query CategoryMeta($where: CategoryWhereUniqueInput!) {
  category(where: $where) {
    image {
      type
      url
      title
    }
    categoryPageKeyWords
    categoryPageTitle
    categoryText
    slug
  }
}
    `;

/**
 * __useCategoryMetaQuery__
 *
 * To run a query within a React component, call `useCategoryMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryMetaQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCategoryMetaQuery(baseOptions: Apollo.QueryHookOptions<CategoryMetaQuery, CategoryMetaQueryVariables> & ({ variables: CategoryMetaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryMetaQuery, CategoryMetaQueryVariables>(CategoryMetaDocument, options);
      }
export function useCategoryMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryMetaQuery, CategoryMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryMetaQuery, CategoryMetaQueryVariables>(CategoryMetaDocument, options);
        }
export function useCategoryMetaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoryMetaQuery, CategoryMetaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryMetaQuery, CategoryMetaQueryVariables>(CategoryMetaDocument, options);
        }
export type CategoryMetaQueryHookResult = ReturnType<typeof useCategoryMetaQuery>;
export type CategoryMetaLazyQueryHookResult = ReturnType<typeof useCategoryMetaLazyQuery>;
export type CategoryMetaSuspenseQueryHookResult = ReturnType<typeof useCategoryMetaSuspenseQuery>;
export type CategoryMetaQueryResult = Apollo.QueryResult<CategoryMetaQuery, CategoryMetaQueryVariables>;
export const CategoriesForSelectDocument = gql`
    query CategoriesForSelect($where: CategoryWhereInput, $orderBy: [CategoryOrderByWithRelationAndSearchRelevanceInput!], $cursor: CategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CategoryScalarFieldEnum!]) {
  categories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    category
  }
}
    `;

/**
 * __useCategoriesForSelectQuery__
 *
 * To run a query within a React component, call `useCategoriesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForSelectQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useCategoriesForSelectQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesForSelectQuery, CategoriesForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesForSelectQuery, CategoriesForSelectQueryVariables>(CategoriesForSelectDocument, options);
      }
export function useCategoriesForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesForSelectQuery, CategoriesForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesForSelectQuery, CategoriesForSelectQueryVariables>(CategoriesForSelectDocument, options);
        }
export function useCategoriesForSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoriesForSelectQuery, CategoriesForSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoriesForSelectQuery, CategoriesForSelectQueryVariables>(CategoriesForSelectDocument, options);
        }
export type CategoriesForSelectQueryHookResult = ReturnType<typeof useCategoriesForSelectQuery>;
export type CategoriesForSelectLazyQueryHookResult = ReturnType<typeof useCategoriesForSelectLazyQuery>;
export type CategoriesForSelectSuspenseQueryHookResult = ReturnType<typeof useCategoriesForSelectSuspenseQuery>;
export type CategoriesForSelectQueryResult = Apollo.QueryResult<CategoriesForSelectQuery, CategoriesForSelectQueryVariables>;
export const CategoriesForFooterDocument = gql`
    query CategoriesForFooter($where: CategoryWhereInput, $orderBy: [CategoryOrderByWithRelationAndSearchRelevanceInput!], $cursor: CategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CategoryScalarFieldEnum!], $subcategoryWhere2: SubCategoryWhereInput, $subcategoryOrderBy2: [SubCategoryOrderByWithRelationAndSearchRelevanceInput!], $subcategoryCursor2: SubCategoryWhereUniqueInput, $subcategoryTake2: Int, $subcategorySkip2: Int, $subcategoryDistinct2: [SubCategoryScalarFieldEnum!]) {
  categories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    category
    slug
    subcategory(
      where: $subcategoryWhere2
      orderBy: $subcategoryOrderBy2
      cursor: $subcategoryCursor2
      take: $subcategoryTake2
      skip: $subcategorySkip2
      distinct: $subcategoryDistinct2
    ) {
      subCategoryName
      slug
    }
  }
}
    `;

/**
 * __useCategoriesForFooterQuery__
 *
 * To run a query within a React component, call `useCategoriesForFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForFooterQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *      subcategoryWhere2: // value for 'subcategoryWhere2'
 *      subcategoryOrderBy2: // value for 'subcategoryOrderBy2'
 *      subcategoryCursor2: // value for 'subcategoryCursor2'
 *      subcategoryTake2: // value for 'subcategoryTake2'
 *      subcategorySkip2: // value for 'subcategorySkip2'
 *      subcategoryDistinct2: // value for 'subcategoryDistinct2'
 *   },
 * });
 */
export function useCategoriesForFooterQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesForFooterQuery, CategoriesForFooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesForFooterQuery, CategoriesForFooterQueryVariables>(CategoriesForFooterDocument, options);
      }
export function useCategoriesForFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesForFooterQuery, CategoriesForFooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesForFooterQuery, CategoriesForFooterQueryVariables>(CategoriesForFooterDocument, options);
        }
export function useCategoriesForFooterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoriesForFooterQuery, CategoriesForFooterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoriesForFooterQuery, CategoriesForFooterQueryVariables>(CategoriesForFooterDocument, options);
        }
export type CategoriesForFooterQueryHookResult = ReturnType<typeof useCategoriesForFooterQuery>;
export type CategoriesForFooterLazyQueryHookResult = ReturnType<typeof useCategoriesForFooterLazyQuery>;
export type CategoriesForFooterSuspenseQueryHookResult = ReturnType<typeof useCategoriesForFooterSuspenseQuery>;
export type CategoriesForFooterQueryResult = Apollo.QueryResult<CategoriesForFooterQuery, CategoriesForFooterQueryVariables>;
export const CommentsDocument = gql`
    query Comments($where: CommentWhereInput, $orderBy: [CommentOrderByWithRelationAndSearchRelevanceInput!], $cursor: CommentWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CommentScalarFieldEnum!], $commentReactionsWhere2: CommentReactionWhereInput) {
  comments(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    _count {
      commentReactions(where: $commentReactionsWhere2)
      repliedComments
    }
    id
    mainComment
    text
    user {
      avatar
      name
      id
    }
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *      commentReactionsWhere2: // value for 'commentReactionsWhere2'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions?: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export function useCommentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsSuspenseQueryHookResult = ReturnType<typeof useCommentsSuspenseQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const CommentReactionCountDocument = gql`
    query CommentReactionCount($where: CommentWhereUniqueInput!) {
  comment(where: $where) {
    _count {
      commentReactions
    }
  }
}
    `;

/**
 * __useCommentReactionCountQuery__
 *
 * To run a query within a React component, call `useCommentReactionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentReactionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentReactionCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCommentReactionCountQuery(baseOptions: Apollo.QueryHookOptions<CommentReactionCountQuery, CommentReactionCountQueryVariables> & ({ variables: CommentReactionCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentReactionCountQuery, CommentReactionCountQueryVariables>(CommentReactionCountDocument, options);
      }
export function useCommentReactionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentReactionCountQuery, CommentReactionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentReactionCountQuery, CommentReactionCountQueryVariables>(CommentReactionCountDocument, options);
        }
export function useCommentReactionCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CommentReactionCountQuery, CommentReactionCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentReactionCountQuery, CommentReactionCountQueryVariables>(CommentReactionCountDocument, options);
        }
export type CommentReactionCountQueryHookResult = ReturnType<typeof useCommentReactionCountQuery>;
export type CommentReactionCountLazyQueryHookResult = ReturnType<typeof useCommentReactionCountLazyQuery>;
export type CommentReactionCountSuspenseQueryHookResult = ReturnType<typeof useCommentReactionCountSuspenseQuery>;
export type CommentReactionCountQueryResult = Apollo.QueryResult<CommentReactionCountQuery, CommentReactionCountQueryVariables>;
export const AggregateCommentDocument = gql`
    query AggregateComment($where: CommentWhereInput, $orderBy: [CommentOrderByWithRelationAndSearchRelevanceInput!], $cursor: CommentWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateComment(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAggregateCommentQuery__
 *
 * To run a query within a React component, call `useAggregateCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCommentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateCommentQuery(baseOptions?: Apollo.QueryHookOptions<AggregateCommentQuery, AggregateCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateCommentQuery, AggregateCommentQueryVariables>(AggregateCommentDocument, options);
      }
export function useAggregateCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateCommentQuery, AggregateCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateCommentQuery, AggregateCommentQueryVariables>(AggregateCommentDocument, options);
        }
export function useAggregateCommentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AggregateCommentQuery, AggregateCommentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AggregateCommentQuery, AggregateCommentQueryVariables>(AggregateCommentDocument, options);
        }
export type AggregateCommentQueryHookResult = ReturnType<typeof useAggregateCommentQuery>;
export type AggregateCommentLazyQueryHookResult = ReturnType<typeof useAggregateCommentLazyQuery>;
export type AggregateCommentSuspenseQueryHookResult = ReturnType<typeof useAggregateCommentSuspenseQuery>;
export type AggregateCommentQueryResult = Apollo.QueryResult<AggregateCommentQuery, AggregateCommentQueryVariables>;
export const CreateOneCommentDocument = gql`
    mutation CreateOneComment($data: CommentCreateInput!) {
  createOneComment(data: $data) {
    id
  }
}
    `;
export type CreateOneCommentMutationFn = Apollo.MutationFunction<CreateOneCommentMutation, CreateOneCommentMutationVariables>;

/**
 * __useCreateOneCommentMutation__
 *
 * To run a mutation, you first call `useCreateOneCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneCommentMutation, { data, loading, error }] = useCreateOneCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneCommentMutation, CreateOneCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneCommentMutation, CreateOneCommentMutationVariables>(CreateOneCommentDocument, options);
      }
export type CreateOneCommentMutationHookResult = ReturnType<typeof useCreateOneCommentMutation>;
export type CreateOneCommentMutationResult = Apollo.MutationResult<CreateOneCommentMutation>;
export type CreateOneCommentMutationOptions = Apollo.BaseMutationOptions<CreateOneCommentMutation, CreateOneCommentMutationVariables>;
export const UpdateOneCommentDocument = gql`
    mutation UpdateOneComment($data: CommentUpdateInput!, $where: CommentWhereUniqueInput!) {
  updateOneComment(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneCommentMutationFn = Apollo.MutationFunction<UpdateOneCommentMutation, UpdateOneCommentMutationVariables>;

/**
 * __useUpdateOneCommentMutation__
 *
 * To run a mutation, you first call `useUpdateOneCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneCommentMutation, { data, loading, error }] = useUpdateOneCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneCommentMutation, UpdateOneCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneCommentMutation, UpdateOneCommentMutationVariables>(UpdateOneCommentDocument, options);
      }
export type UpdateOneCommentMutationHookResult = ReturnType<typeof useUpdateOneCommentMutation>;
export type UpdateOneCommentMutationResult = Apollo.MutationResult<UpdateOneCommentMutation>;
export type UpdateOneCommentMutationOptions = Apollo.BaseMutationOptions<UpdateOneCommentMutation, UpdateOneCommentMutationVariables>;
export const DeleteOneCommentDocument = gql`
    mutation DeleteOneComment($where: CommentWhereUniqueInput!) {
  deleteOneComment(where: $where) {
    id
  }
}
    `;
export type DeleteOneCommentMutationFn = Apollo.MutationFunction<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>;

/**
 * __useDeleteOneCommentMutation__
 *
 * To run a mutation, you first call `useDeleteOneCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneCommentMutation, { data, loading, error }] = useDeleteOneCommentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>(DeleteOneCommentDocument, options);
      }
export type DeleteOneCommentMutationHookResult = ReturnType<typeof useDeleteOneCommentMutation>;
export type DeleteOneCommentMutationResult = Apollo.MutationResult<DeleteOneCommentMutation>;
export type DeleteOneCommentMutationOptions = Apollo.BaseMutationOptions<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>;
export const LikeOnCommentDocument = gql`
    mutation LikeOnComment($likeOnCommentId: String!) {
  likeOnComment(id: $likeOnCommentId) {
    message
    success
  }
}
    `;
export type LikeOnCommentMutationFn = Apollo.MutationFunction<LikeOnCommentMutation, LikeOnCommentMutationVariables>;

/**
 * __useLikeOnCommentMutation__
 *
 * To run a mutation, you first call `useLikeOnCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeOnCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeOnCommentMutation, { data, loading, error }] = useLikeOnCommentMutation({
 *   variables: {
 *      likeOnCommentId: // value for 'likeOnCommentId'
 *   },
 * });
 */
export function useLikeOnCommentMutation(baseOptions?: Apollo.MutationHookOptions<LikeOnCommentMutation, LikeOnCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeOnCommentMutation, LikeOnCommentMutationVariables>(LikeOnCommentDocument, options);
      }
export type LikeOnCommentMutationHookResult = ReturnType<typeof useLikeOnCommentMutation>;
export type LikeOnCommentMutationResult = Apollo.MutationResult<LikeOnCommentMutation>;
export type LikeOnCommentMutationOptions = Apollo.BaseMutationOptions<LikeOnCommentMutation, LikeOnCommentMutationVariables>;
export const RemoveLikeFromCommentDocument = gql`
    mutation RemoveLikeFromComment($removeLikeFromCommentId: String!) {
  removeLikeFromComment(id: $removeLikeFromCommentId) {
    message
    success
  }
}
    `;
export type RemoveLikeFromCommentMutationFn = Apollo.MutationFunction<RemoveLikeFromCommentMutation, RemoveLikeFromCommentMutationVariables>;

/**
 * __useRemoveLikeFromCommentMutation__
 *
 * To run a mutation, you first call `useRemoveLikeFromCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLikeFromCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLikeFromCommentMutation, { data, loading, error }] = useRemoveLikeFromCommentMutation({
 *   variables: {
 *      removeLikeFromCommentId: // value for 'removeLikeFromCommentId'
 *   },
 * });
 */
export function useRemoveLikeFromCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLikeFromCommentMutation, RemoveLikeFromCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLikeFromCommentMutation, RemoveLikeFromCommentMutationVariables>(RemoveLikeFromCommentDocument, options);
      }
export type RemoveLikeFromCommentMutationHookResult = ReturnType<typeof useRemoveLikeFromCommentMutation>;
export type RemoveLikeFromCommentMutationResult = Apollo.MutationResult<RemoveLikeFromCommentMutation>;
export type RemoveLikeFromCommentMutationOptions = Apollo.BaseMutationOptions<RemoveLikeFromCommentMutation, RemoveLikeFromCommentMutationVariables>;
export const LoggedInUserLikedThisCommentCheckDocument = gql`
    query LoggedInUserLikedThisCommentCheck($where: CommentReactionWhereInput, $orderBy: [CommentReactionOrderByWithRelationAndSearchRelevanceInput!], $cursor: CommentReactionWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CommentReactionScalarFieldEnum!]) {
  commentReactions(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
  }
}
    `;

/**
 * __useLoggedInUserLikedThisCommentCheckQuery__
 *
 * To run a query within a React component, call `useLoggedInUserLikedThisCommentCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserLikedThisCommentCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserLikedThisCommentCheckQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useLoggedInUserLikedThisCommentCheckQuery(baseOptions?: Apollo.QueryHookOptions<LoggedInUserLikedThisCommentCheckQuery, LoggedInUserLikedThisCommentCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedInUserLikedThisCommentCheckQuery, LoggedInUserLikedThisCommentCheckQueryVariables>(LoggedInUserLikedThisCommentCheckDocument, options);
      }
export function useLoggedInUserLikedThisCommentCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedInUserLikedThisCommentCheckQuery, LoggedInUserLikedThisCommentCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedInUserLikedThisCommentCheckQuery, LoggedInUserLikedThisCommentCheckQueryVariables>(LoggedInUserLikedThisCommentCheckDocument, options);
        }
export function useLoggedInUserLikedThisCommentCheckSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LoggedInUserLikedThisCommentCheckQuery, LoggedInUserLikedThisCommentCheckQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoggedInUserLikedThisCommentCheckQuery, LoggedInUserLikedThisCommentCheckQueryVariables>(LoggedInUserLikedThisCommentCheckDocument, options);
        }
export type LoggedInUserLikedThisCommentCheckQueryHookResult = ReturnType<typeof useLoggedInUserLikedThisCommentCheckQuery>;
export type LoggedInUserLikedThisCommentCheckLazyQueryHookResult = ReturnType<typeof useLoggedInUserLikedThisCommentCheckLazyQuery>;
export type LoggedInUserLikedThisCommentCheckSuspenseQueryHookResult = ReturnType<typeof useLoggedInUserLikedThisCommentCheckSuspenseQuery>;
export type LoggedInUserLikedThisCommentCheckQueryResult = Apollo.QueryResult<LoggedInUserLikedThisCommentCheckQuery, LoggedInUserLikedThisCommentCheckQueryVariables>;
export const DeleteManyCommentDocument = gql`
    mutation DeleteManyComment($where: CommentWhereInput) {
  deleteManyComment(where: $where) {
    count
  }
}
    `;
export type DeleteManyCommentMutationFn = Apollo.MutationFunction<DeleteManyCommentMutation, DeleteManyCommentMutationVariables>;

/**
 * __useDeleteManyCommentMutation__
 *
 * To run a mutation, you first call `useDeleteManyCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCommentMutation, { data, loading, error }] = useDeleteManyCommentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyCommentMutation, DeleteManyCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyCommentMutation, DeleteManyCommentMutationVariables>(DeleteManyCommentDocument, options);
      }
export type DeleteManyCommentMutationHookResult = ReturnType<typeof useDeleteManyCommentMutation>;
export type DeleteManyCommentMutationResult = Apollo.MutationResult<DeleteManyCommentMutation>;
export type DeleteManyCommentMutationOptions = Apollo.BaseMutationOptions<DeleteManyCommentMutation, DeleteManyCommentMutationVariables>;
export const FindManyMediaDocument = gql`
    query FindManyMedia($where: MediaWhereInput, $orderBy: [MediaOrderByWithRelationAndSearchRelevanceInput!], $cursor: MediaWhereUniqueInput, $take: Int, $skip: Int, $distinct: [MediaScalarFieldEnum!]) {
  findManyMedia(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    filename
    id
    type
    url
  }
}
    `;

/**
 * __useFindManyMediaQuery__
 *
 * To run a query within a React component, call `useFindManyMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyMediaQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useFindManyMediaQuery(baseOptions?: Apollo.QueryHookOptions<FindManyMediaQuery, FindManyMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyMediaQuery, FindManyMediaQueryVariables>(FindManyMediaDocument, options);
      }
export function useFindManyMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyMediaQuery, FindManyMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyMediaQuery, FindManyMediaQueryVariables>(FindManyMediaDocument, options);
        }
export function useFindManyMediaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindManyMediaQuery, FindManyMediaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManyMediaQuery, FindManyMediaQueryVariables>(FindManyMediaDocument, options);
        }
export type FindManyMediaQueryHookResult = ReturnType<typeof useFindManyMediaQuery>;
export type FindManyMediaLazyQueryHookResult = ReturnType<typeof useFindManyMediaLazyQuery>;
export type FindManyMediaSuspenseQueryHookResult = ReturnType<typeof useFindManyMediaSuspenseQuery>;
export type FindManyMediaQueryResult = Apollo.QueryResult<FindManyMediaQuery, FindManyMediaQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($mediaCatIds: [String!]!, $title: String!, $file: Upload!) {
  uploadFile(mediaCatIds: $mediaCatIds, title: $title, file: $file) {
    url
    type
    title
    id
    filename
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      mediaCatIds: // value for 'mediaCatIds'
 *      title: // value for 'title'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UserNotificationsDocument = gql`
    query UserNotifications {
  userNotifications {
    createdAt
    createdBy {
      avatar
      name
    }
    id
    status
    text
    type
    url
  }
}
    `;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
      }
export function useUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export function useUserNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsSuspenseQueryHookResult = ReturnType<typeof useUserNotificationsSuspenseQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export const NotificationUnreadDocument = gql`
    query notificationUnread {
  notificationUnread
}
    `;

/**
 * __useNotificationUnreadQuery__
 *
 * To run a query within a React component, call `useNotificationUnreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationUnreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationUnreadQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationUnreadQuery(baseOptions?: Apollo.QueryHookOptions<NotificationUnreadQuery, NotificationUnreadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationUnreadQuery, NotificationUnreadQueryVariables>(NotificationUnreadDocument, options);
      }
export function useNotificationUnreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationUnreadQuery, NotificationUnreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationUnreadQuery, NotificationUnreadQueryVariables>(NotificationUnreadDocument, options);
        }
export function useNotificationUnreadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NotificationUnreadQuery, NotificationUnreadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationUnreadQuery, NotificationUnreadQueryVariables>(NotificationUnreadDocument, options);
        }
export type NotificationUnreadQueryHookResult = ReturnType<typeof useNotificationUnreadQuery>;
export type NotificationUnreadLazyQueryHookResult = ReturnType<typeof useNotificationUnreadLazyQuery>;
export type NotificationUnreadSuspenseQueryHookResult = ReturnType<typeof useNotificationUnreadSuspenseQuery>;
export type NotificationUnreadQueryResult = Apollo.QueryResult<NotificationUnreadQuery, NotificationUnreadQueryVariables>;
export const NewNotificationDocument = gql`
    subscription NewNotification {
  newNotification {
    createdAt
    createdBy {
      avatar
      name
    }
    id
    status
    text
    type
    url
  }
}
    `;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewNotificationSubscription, NewNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewNotificationSubscription, NewNotificationSubscriptionVariables>(NewNotificationDocument, options);
      }
export type NewNotificationSubscriptionHookResult = ReturnType<typeof useNewNotificationSubscription>;
export type NewNotificationSubscriptionResult = Apollo.SubscriptionResult<NewNotificationSubscription>;
export const UnreadNotificationCountUpdatedDocument = gql`
    subscription UnreadNotificationCountUpdated {
  unreadNotificationCountUpdated {
    count
  }
}
    `;

/**
 * __useUnreadNotificationCountUpdatedSubscription__
 *
 * To run a query within a React component, call `useUnreadNotificationCountUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationCountUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationCountUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUnreadNotificationCountUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UnreadNotificationCountUpdatedSubscription, UnreadNotificationCountUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UnreadNotificationCountUpdatedSubscription, UnreadNotificationCountUpdatedSubscriptionVariables>(UnreadNotificationCountUpdatedDocument, options);
      }
export type UnreadNotificationCountUpdatedSubscriptionHookResult = ReturnType<typeof useUnreadNotificationCountUpdatedSubscription>;
export type UnreadNotificationCountUpdatedSubscriptionResult = Apollo.SubscriptionResult<UnreadNotificationCountUpdatedSubscription>;
export const PostsDocument = gql`
    query Posts($where: PostWhereInput, $orderBy: [PostOrderByWithRelationAndSearchRelevanceInput!], $cursor: PostWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PostScalarFieldEnum!]) {
  posts(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    pageTitle
    _count {
      likesByUser
      comments
    }
    postText
    image {
      filename
      title
      type
      url
    }
    postitle
    slug
    socialShare
    video
    haveVideo
    id
    tag {
      name
      slug
    }
    subcategory {
      slug
      subCategoryName
    }
    category {
      category
      slug
      postSectionType
    }
  }
}
    `;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
      }
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
        }
export function usePostsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsSuspenseQueryHookResult = ReturnType<typeof usePostsSuspenseQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const PostsForUrlDocument = gql`
    query PostsForUrl($where: PostWhereInput, $orderBy: [PostOrderByWithRelationAndSearchRelevanceInput!], $cursor: PostWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PostScalarFieldEnum!]) {
  posts(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    slug
    updatedAt
    subcategory {
      slug
    }
    category {
      slug
    }
  }
}
    `;

/**
 * __usePostsForUrlQuery__
 *
 * To run a query within a React component, call `usePostsForUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsForUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsForUrlQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePostsForUrlQuery(baseOptions?: Apollo.QueryHookOptions<PostsForUrlQuery, PostsForUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsForUrlQuery, PostsForUrlQueryVariables>(PostsForUrlDocument, options);
      }
export function usePostsForUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsForUrlQuery, PostsForUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsForUrlQuery, PostsForUrlQueryVariables>(PostsForUrlDocument, options);
        }
export function usePostsForUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostsForUrlQuery, PostsForUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostsForUrlQuery, PostsForUrlQueryVariables>(PostsForUrlDocument, options);
        }
export type PostsForUrlQueryHookResult = ReturnType<typeof usePostsForUrlQuery>;
export type PostsForUrlLazyQueryHookResult = ReturnType<typeof usePostsForUrlLazyQuery>;
export type PostsForUrlSuspenseQueryHookResult = ReturnType<typeof usePostsForUrlSuspenseQuery>;
export type PostsForUrlQueryResult = Apollo.QueryResult<PostsForUrlQuery, PostsForUrlQueryVariables>;
export const PostsForCategorySectionViewDocument = gql`
    query PostsForCategorySectionView($where: PostWhereInput, $orderBy: [PostOrderByWithRelationAndSearchRelevanceInput!], $cursor: PostWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PostScalarFieldEnum!]) {
  posts(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    _count {
      likesByUser
      comments
    }
    id
    postText
    updatedAt
    createdAt
    image {
      title
      type
      url
    }
    postitle
    slug
    video
    haveVideo
    subcategory {
      slug
      subCategoryName
    }
    category {
      category
      slug
      postSectionType
    }
    user {
      id
      professionalName
      name
      username
    }
  }
}
    `;

/**
 * __usePostsForCategorySectionViewQuery__
 *
 * To run a query within a React component, call `usePostsForCategorySectionViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsForCategorySectionViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsForCategorySectionViewQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePostsForCategorySectionViewQuery(baseOptions?: Apollo.QueryHookOptions<PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables>(PostsForCategorySectionViewDocument, options);
      }
export function usePostsForCategorySectionViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables>(PostsForCategorySectionViewDocument, options);
        }
export function usePostsForCategorySectionViewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables>(PostsForCategorySectionViewDocument, options);
        }
export type PostsForCategorySectionViewQueryHookResult = ReturnType<typeof usePostsForCategorySectionViewQuery>;
export type PostsForCategorySectionViewLazyQueryHookResult = ReturnType<typeof usePostsForCategorySectionViewLazyQuery>;
export type PostsForCategorySectionViewSuspenseQueryHookResult = ReturnType<typeof usePostsForCategorySectionViewSuspenseQuery>;
export type PostsForCategorySectionViewQueryResult = Apollo.QueryResult<PostsForCategorySectionViewQuery, PostsForCategorySectionViewQueryVariables>;
export const PostsForCategoryMenuViewDocument = gql`
    query PostsForCategoryMenuView($where: PostWhereInput, $orderBy: [PostOrderByWithRelationAndSearchRelevanceInput!], $cursor: PostWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PostScalarFieldEnum!]) {
  posts(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    subcategory {
      slug
    }
    category {
      slug
    }
    image {
      url
    }
    postitle
    slug
  }
}
    `;

/**
 * __usePostsForCategoryMenuViewQuery__
 *
 * To run a query within a React component, call `usePostsForCategoryMenuViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsForCategoryMenuViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsForCategoryMenuViewQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePostsForCategoryMenuViewQuery(baseOptions?: Apollo.QueryHookOptions<PostsForCategoryMenuViewQuery, PostsForCategoryMenuViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsForCategoryMenuViewQuery, PostsForCategoryMenuViewQueryVariables>(PostsForCategoryMenuViewDocument, options);
      }
export function usePostsForCategoryMenuViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsForCategoryMenuViewQuery, PostsForCategoryMenuViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsForCategoryMenuViewQuery, PostsForCategoryMenuViewQueryVariables>(PostsForCategoryMenuViewDocument, options);
        }
export function usePostsForCategoryMenuViewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostsForCategoryMenuViewQuery, PostsForCategoryMenuViewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostsForCategoryMenuViewQuery, PostsForCategoryMenuViewQueryVariables>(PostsForCategoryMenuViewDocument, options);
        }
export type PostsForCategoryMenuViewQueryHookResult = ReturnType<typeof usePostsForCategoryMenuViewQuery>;
export type PostsForCategoryMenuViewLazyQueryHookResult = ReturnType<typeof usePostsForCategoryMenuViewLazyQuery>;
export type PostsForCategoryMenuViewSuspenseQueryHookResult = ReturnType<typeof usePostsForCategoryMenuViewSuspenseQuery>;
export type PostsForCategoryMenuViewQueryResult = Apollo.QueryResult<PostsForCategoryMenuViewQuery, PostsForCategoryMenuViewQueryVariables>;
export const PostDocument = gql`
    query Post($where: PostWhereUniqueInput!) {
  post(where: $where) {
    _count {
      likesByUser
      comments
    }
    category {
      id
      category
      slug
      postSectionType
    }
    description
    pageDescription
    createdAt
    updatedAt
    keyWords
    postText
    hideAdsOnThisArtical
    image {
      type
      url
      title
    }
    isLive
    noIndex
    postitle
    haveVideo
    id
    slug
    socialShare
    subHeading
    subcategory {
      id
      subCategoryName
      slug
    }
    tag {
      id
      name
      slug
    }
    updatedAt
    video
    webNotification
    user {
      id
      avatar
      professionalName
      name
      bio
      username
    }
  }
}
    `;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables> & ({ variables: PostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, options);
      }
export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, options);
        }
export function usePostSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostQuery, PostQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostQuery, PostQueryVariables>(PostDocument, options);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostSuspenseQueryHookResult = ReturnType<typeof usePostSuspenseQuery>;
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>;
export const PostLikeCountDocument = gql`
    query postLikeCount($where: PostWhereUniqueInput!) {
  post(where: $where) {
    _count {
      likesByUser
    }
  }
}
    `;

/**
 * __usePostLikeCountQuery__
 *
 * To run a query within a React component, call `usePostLikeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostLikeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostLikeCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostLikeCountQuery(baseOptions: Apollo.QueryHookOptions<PostLikeCountQuery, PostLikeCountQueryVariables> & ({ variables: PostLikeCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostLikeCountQuery, PostLikeCountQueryVariables>(PostLikeCountDocument, options);
      }
export function usePostLikeCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostLikeCountQuery, PostLikeCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostLikeCountQuery, PostLikeCountQueryVariables>(PostLikeCountDocument, options);
        }
export function usePostLikeCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostLikeCountQuery, PostLikeCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostLikeCountQuery, PostLikeCountQueryVariables>(PostLikeCountDocument, options);
        }
export type PostLikeCountQueryHookResult = ReturnType<typeof usePostLikeCountQuery>;
export type PostLikeCountLazyQueryHookResult = ReturnType<typeof usePostLikeCountLazyQuery>;
export type PostLikeCountSuspenseQueryHookResult = ReturnType<typeof usePostLikeCountSuspenseQuery>;
export type PostLikeCountQueryResult = Apollo.QueryResult<PostLikeCountQuery, PostLikeCountQueryVariables>;
export const PostCommentCountDocument = gql`
    query postCommentCount($where: PostWhereUniqueInput!, $commentsWhere2: CommentWhereInput) {
  post(where: $where) {
    _count {
      comments(where: $commentsWhere2)
    }
  }
}
    `;

/**
 * __usePostCommentCountQuery__
 *
 * To run a query within a React component, call `usePostCommentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCommentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCommentCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *      commentsWhere2: // value for 'commentsWhere2'
 *   },
 * });
 */
export function usePostCommentCountQuery(baseOptions: Apollo.QueryHookOptions<PostCommentCountQuery, PostCommentCountQueryVariables> & ({ variables: PostCommentCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostCommentCountQuery, PostCommentCountQueryVariables>(PostCommentCountDocument, options);
      }
export function usePostCommentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostCommentCountQuery, PostCommentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostCommentCountQuery, PostCommentCountQueryVariables>(PostCommentCountDocument, options);
        }
export function usePostCommentCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostCommentCountQuery, PostCommentCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostCommentCountQuery, PostCommentCountQueryVariables>(PostCommentCountDocument, options);
        }
export type PostCommentCountQueryHookResult = ReturnType<typeof usePostCommentCountQuery>;
export type PostCommentCountLazyQueryHookResult = ReturnType<typeof usePostCommentCountLazyQuery>;
export type PostCommentCountSuspenseQueryHookResult = ReturnType<typeof usePostCommentCountSuspenseQuery>;
export type PostCommentCountQueryResult = Apollo.QueryResult<PostCommentCountQuery, PostCommentCountQueryVariables>;
export const PostMetaDocument = gql`
    query PostMeta($where: PostWhereUniqueInput!) {
  post(where: $where) {
    slug
    pageTitle
    pageDescription
    keyWords
    image {
      title
      type
      url
    }
  }
}
    `;

/**
 * __usePostMetaQuery__
 *
 * To run a query within a React component, call `usePostMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostMetaQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostMetaQuery(baseOptions: Apollo.QueryHookOptions<PostMetaQuery, PostMetaQueryVariables> & ({ variables: PostMetaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostMetaQuery, PostMetaQueryVariables>(PostMetaDocument, options);
      }
export function usePostMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostMetaQuery, PostMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostMetaQuery, PostMetaQueryVariables>(PostMetaDocument, options);
        }
export function usePostMetaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostMetaQuery, PostMetaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostMetaQuery, PostMetaQueryVariables>(PostMetaDocument, options);
        }
export type PostMetaQueryHookResult = ReturnType<typeof usePostMetaQuery>;
export type PostMetaLazyQueryHookResult = ReturnType<typeof usePostMetaLazyQuery>;
export type PostMetaSuspenseQueryHookResult = ReturnType<typeof usePostMetaSuspenseQuery>;
export type PostMetaQueryResult = Apollo.QueryResult<PostMetaQuery, PostMetaQueryVariables>;
export const AggregatePostDocument = gql`
    query AggregatePost($where: PostWhereInput, $orderBy: [PostOrderByWithRelationAndSearchRelevanceInput!], $cursor: PostWhereUniqueInput, $take: Int, $skip: Int) {
  aggregatePost(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregatePostQuery__
 *
 * To run a query within a React component, call `useAggregatePostQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatePostQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregatePostQuery(baseOptions?: Apollo.QueryHookOptions<AggregatePostQuery, AggregatePostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregatePostQuery, AggregatePostQueryVariables>(AggregatePostDocument, options);
      }
export function useAggregatePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregatePostQuery, AggregatePostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregatePostQuery, AggregatePostQueryVariables>(AggregatePostDocument, options);
        }
export function useAggregatePostSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AggregatePostQuery, AggregatePostQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AggregatePostQuery, AggregatePostQueryVariables>(AggregatePostDocument, options);
        }
export type AggregatePostQueryHookResult = ReturnType<typeof useAggregatePostQuery>;
export type AggregatePostLazyQueryHookResult = ReturnType<typeof useAggregatePostLazyQuery>;
export type AggregatePostSuspenseQueryHookResult = ReturnType<typeof useAggregatePostSuspenseQuery>;
export type AggregatePostQueryResult = Apollo.QueryResult<AggregatePostQuery, AggregatePostQueryVariables>;
export const PostsForContributorDocument = gql`
    query PostsForContributor($where: PostWhereInput, $orderBy: [PostOrderByWithRelationAndSearchRelevanceInput!], $cursor: PostWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PostScalarFieldEnum!]) {
  posts(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    postText
    updatedAt
    status
    image {
      filename
      title
      type
      url
    }
    postitle
    slug
    video
    haveVideo
    tag {
      name
      slug
    }
    subcategory {
      slug
      subCategoryName
    }
    category {
      category
      slug
    }
  }
}
    `;

/**
 * __usePostsForContributorQuery__
 *
 * To run a query within a React component, call `usePostsForContributorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsForContributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsForContributorQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePostsForContributorQuery(baseOptions?: Apollo.QueryHookOptions<PostsForContributorQuery, PostsForContributorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsForContributorQuery, PostsForContributorQueryVariables>(PostsForContributorDocument, options);
      }
export function usePostsForContributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsForContributorQuery, PostsForContributorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsForContributorQuery, PostsForContributorQueryVariables>(PostsForContributorDocument, options);
        }
export function usePostsForContributorSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostsForContributorQuery, PostsForContributorQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostsForContributorQuery, PostsForContributorQueryVariables>(PostsForContributorDocument, options);
        }
export type PostsForContributorQueryHookResult = ReturnType<typeof usePostsForContributorQuery>;
export type PostsForContributorLazyQueryHookResult = ReturnType<typeof usePostsForContributorLazyQuery>;
export type PostsForContributorSuspenseQueryHookResult = ReturnType<typeof usePostsForContributorSuspenseQuery>;
export type PostsForContributorQueryResult = Apollo.QueryResult<PostsForContributorQuery, PostsForContributorQueryVariables>;
export const CreatePostByContributorDocument = gql`
    mutation CreatePostByContributor($input: ContributorPostCreateInput!, $taxonomyAndMedia: TaxonomyAndMedia!) {
  createPostByContributor(input: $input, taxonomyAndMedia: $taxonomyAndMedia) {
    message
    success
  }
}
    `;
export type CreatePostByContributorMutationFn = Apollo.MutationFunction<CreatePostByContributorMutation, CreatePostByContributorMutationVariables>;

/**
 * __useCreatePostByContributorMutation__
 *
 * To run a mutation, you first call `useCreatePostByContributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostByContributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostByContributorMutation, { data, loading, error }] = useCreatePostByContributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taxonomyAndMedia: // value for 'taxonomyAndMedia'
 *   },
 * });
 */
export function useCreatePostByContributorMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostByContributorMutation, CreatePostByContributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostByContributorMutation, CreatePostByContributorMutationVariables>(CreatePostByContributorDocument, options);
      }
export type CreatePostByContributorMutationHookResult = ReturnType<typeof useCreatePostByContributorMutation>;
export type CreatePostByContributorMutationResult = Apollo.MutationResult<CreatePostByContributorMutation>;
export type CreatePostByContributorMutationOptions = Apollo.BaseMutationOptions<CreatePostByContributorMutation, CreatePostByContributorMutationVariables>;
export const RemoveLikeFromPostDocument = gql`
    mutation RemoveLikeFromPost($removeLikeFromPostId: String!) {
  removeLikeFromPost(id: $removeLikeFromPostId) {
    message
    success
  }
}
    `;
export type RemoveLikeFromPostMutationFn = Apollo.MutationFunction<RemoveLikeFromPostMutation, RemoveLikeFromPostMutationVariables>;

/**
 * __useRemoveLikeFromPostMutation__
 *
 * To run a mutation, you first call `useRemoveLikeFromPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLikeFromPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLikeFromPostMutation, { data, loading, error }] = useRemoveLikeFromPostMutation({
 *   variables: {
 *      removeLikeFromPostId: // value for 'removeLikeFromPostId'
 *   },
 * });
 */
export function useRemoveLikeFromPostMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLikeFromPostMutation, RemoveLikeFromPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLikeFromPostMutation, RemoveLikeFromPostMutationVariables>(RemoveLikeFromPostDocument, options);
      }
export type RemoveLikeFromPostMutationHookResult = ReturnType<typeof useRemoveLikeFromPostMutation>;
export type RemoveLikeFromPostMutationResult = Apollo.MutationResult<RemoveLikeFromPostMutation>;
export type RemoveLikeFromPostMutationOptions = Apollo.BaseMutationOptions<RemoveLikeFromPostMutation, RemoveLikeFromPostMutationVariables>;
export const LikeOnPostDocument = gql`
    mutation LikeOnPost($likeOnPostId: String!) {
  likeOnPost(id: $likeOnPostId) {
    message
    success
  }
}
    `;
export type LikeOnPostMutationFn = Apollo.MutationFunction<LikeOnPostMutation, LikeOnPostMutationVariables>;

/**
 * __useLikeOnPostMutation__
 *
 * To run a mutation, you first call `useLikeOnPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeOnPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeOnPostMutation, { data, loading, error }] = useLikeOnPostMutation({
 *   variables: {
 *      likeOnPostId: // value for 'likeOnPostId'
 *   },
 * });
 */
export function useLikeOnPostMutation(baseOptions?: Apollo.MutationHookOptions<LikeOnPostMutation, LikeOnPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeOnPostMutation, LikeOnPostMutationVariables>(LikeOnPostDocument, options);
      }
export type LikeOnPostMutationHookResult = ReturnType<typeof useLikeOnPostMutation>;
export type LikeOnPostMutationResult = Apollo.MutationResult<LikeOnPostMutation>;
export type LikeOnPostMutationOptions = Apollo.BaseMutationOptions<LikeOnPostMutation, LikeOnPostMutationVariables>;
export const LikesByCurrentUserCheckDocument = gql`
    query LikesByCurrentUserCheck($where: LikesByUserWhereInput) {
  likesByUsers(where: $where) {
    id
  }
}
    `;

/**
 * __useLikesByCurrentUserCheckQuery__
 *
 * To run a query within a React component, call `useLikesByCurrentUserCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikesByCurrentUserCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikesByCurrentUserCheckQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLikesByCurrentUserCheckQuery(baseOptions?: Apollo.QueryHookOptions<LikesByCurrentUserCheckQuery, LikesByCurrentUserCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LikesByCurrentUserCheckQuery, LikesByCurrentUserCheckQueryVariables>(LikesByCurrentUserCheckDocument, options);
      }
export function useLikesByCurrentUserCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LikesByCurrentUserCheckQuery, LikesByCurrentUserCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LikesByCurrentUserCheckQuery, LikesByCurrentUserCheckQueryVariables>(LikesByCurrentUserCheckDocument, options);
        }
export function useLikesByCurrentUserCheckSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LikesByCurrentUserCheckQuery, LikesByCurrentUserCheckQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LikesByCurrentUserCheckQuery, LikesByCurrentUserCheckQueryVariables>(LikesByCurrentUserCheckDocument, options);
        }
export type LikesByCurrentUserCheckQueryHookResult = ReturnType<typeof useLikesByCurrentUserCheckQuery>;
export type LikesByCurrentUserCheckLazyQueryHookResult = ReturnType<typeof useLikesByCurrentUserCheckLazyQuery>;
export type LikesByCurrentUserCheckSuspenseQueryHookResult = ReturnType<typeof useLikesByCurrentUserCheckSuspenseQuery>;
export type LikesByCurrentUserCheckQueryResult = Apollo.QueryResult<LikesByCurrentUserCheckQuery, LikesByCurrentUserCheckQueryVariables>;
export const PostDataForUpdateDocument = gql`
    query PostDataForUpdate($where: PostWhereUniqueInput!) {
  post(where: $where) {
    category {
      id
      category
      slug
    }
    description
    pageDescription
    pageTitle
    keyWords
    postText
    image {
      type
      url
      title
      id
    }
    postitle
    id
    slug
    subHeading
    subcategory {
      id
      subCategoryName
      slug
    }
    tag {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __usePostDataForUpdateQuery__
 *
 * To run a query within a React component, call `usePostDataForUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostDataForUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostDataForUpdateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostDataForUpdateQuery(baseOptions: Apollo.QueryHookOptions<PostDataForUpdateQuery, PostDataForUpdateQueryVariables> & ({ variables: PostDataForUpdateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostDataForUpdateQuery, PostDataForUpdateQueryVariables>(PostDataForUpdateDocument, options);
      }
export function usePostDataForUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostDataForUpdateQuery, PostDataForUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostDataForUpdateQuery, PostDataForUpdateQueryVariables>(PostDataForUpdateDocument, options);
        }
export function usePostDataForUpdateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PostDataForUpdateQuery, PostDataForUpdateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostDataForUpdateQuery, PostDataForUpdateQueryVariables>(PostDataForUpdateDocument, options);
        }
export type PostDataForUpdateQueryHookResult = ReturnType<typeof usePostDataForUpdateQuery>;
export type PostDataForUpdateLazyQueryHookResult = ReturnType<typeof usePostDataForUpdateLazyQuery>;
export type PostDataForUpdateSuspenseQueryHookResult = ReturnType<typeof usePostDataForUpdateSuspenseQuery>;
export type PostDataForUpdateQueryResult = Apollo.QueryResult<PostDataForUpdateQuery, PostDataForUpdateQueryVariables>;
export const UpdateOnePostDocument = gql`
    mutation UpdateOnePost($data: PostUpdateInput!, $where: PostWhereUniqueInput!) {
  updateOnePost(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOnePostMutationFn = Apollo.MutationFunction<UpdateOnePostMutation, UpdateOnePostMutationVariables>;

/**
 * __useUpdateOnePostMutation__
 *
 * To run a mutation, you first call `useUpdateOnePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnePostMutation, { data, loading, error }] = useUpdateOnePostMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOnePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnePostMutation, UpdateOnePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnePostMutation, UpdateOnePostMutationVariables>(UpdateOnePostDocument, options);
      }
export type UpdateOnePostMutationHookResult = ReturnType<typeof useUpdateOnePostMutation>;
export type UpdateOnePostMutationResult = Apollo.MutationResult<UpdateOnePostMutation>;
export type UpdateOnePostMutationOptions = Apollo.BaseMutationOptions<UpdateOnePostMutation, UpdateOnePostMutationVariables>;
export const DeleteOnePostDocument = gql`
    mutation DeleteOnePost($where: PostWhereUniqueInput!) {
  deleteOnePost(where: $where) {
    id
  }
}
    `;
export type DeleteOnePostMutationFn = Apollo.MutationFunction<DeleteOnePostMutation, DeleteOnePostMutationVariables>;

/**
 * __useDeleteOnePostMutation__
 *
 * To run a mutation, you first call `useDeleteOnePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnePostMutation, { data, loading, error }] = useDeleteOnePostMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOnePostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnePostMutation, DeleteOnePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnePostMutation, DeleteOnePostMutationVariables>(DeleteOnePostDocument, options);
      }
export type DeleteOnePostMutationHookResult = ReturnType<typeof useDeleteOnePostMutation>;
export type DeleteOnePostMutationResult = Apollo.MutationResult<DeleteOnePostMutation>;
export type DeleteOnePostMutationOptions = Apollo.BaseMutationOptions<DeleteOnePostMutation, DeleteOnePostMutationVariables>;
export const LikesByUsersDocument = gql`
    query LikesByUsers($where: LikesByUserWhereInput, $orderBy: [LikesByUserOrderByWithRelationAndSearchRelevanceInput!], $cursor: LikesByUserWhereUniqueInput, $take: Int, $skip: Int, $distinct: [LikesByUserScalarFieldEnum!]) {
  likesByUsers(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    post {
      id
      postText
      updatedAt
      status
      image {
        filename
        title
        type
        url
      }
      postitle
      slug
      video
      haveVideo
      tag {
        name
        slug
      }
      subcategory {
        slug
        subCategoryName
      }
      category {
        category
        slug
      }
    }
  }
}
    `;

/**
 * __useLikesByUsersQuery__
 *
 * To run a query within a React component, call `useLikesByUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikesByUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikesByUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useLikesByUsersQuery(baseOptions?: Apollo.QueryHookOptions<LikesByUsersQuery, LikesByUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LikesByUsersQuery, LikesByUsersQueryVariables>(LikesByUsersDocument, options);
      }
export function useLikesByUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LikesByUsersQuery, LikesByUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LikesByUsersQuery, LikesByUsersQueryVariables>(LikesByUsersDocument, options);
        }
export function useLikesByUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LikesByUsersQuery, LikesByUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LikesByUsersQuery, LikesByUsersQueryVariables>(LikesByUsersDocument, options);
        }
export type LikesByUsersQueryHookResult = ReturnType<typeof useLikesByUsersQuery>;
export type LikesByUsersLazyQueryHookResult = ReturnType<typeof useLikesByUsersLazyQuery>;
export type LikesByUsersSuspenseQueryHookResult = ReturnType<typeof useLikesByUsersSuspenseQuery>;
export type LikesByUsersQueryResult = Apollo.QueryResult<LikesByUsersQuery, LikesByUsersQueryVariables>;
export const SiteinfosDocument = gql`
    query Siteinfos {
  siteinfos {
    tags
    title
    description
    id
  }
}
    `;

/**
 * __useSiteinfosQuery__
 *
 * To run a query within a React component, call `useSiteinfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteinfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteinfosQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteinfosQuery(baseOptions?: Apollo.QueryHookOptions<SiteinfosQuery, SiteinfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteinfosQuery, SiteinfosQueryVariables>(SiteinfosDocument, options);
      }
export function useSiteinfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteinfosQuery, SiteinfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteinfosQuery, SiteinfosQueryVariables>(SiteinfosDocument, options);
        }
export function useSiteinfosSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SiteinfosQuery, SiteinfosQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SiteinfosQuery, SiteinfosQueryVariables>(SiteinfosDocument, options);
        }
export type SiteinfosQueryHookResult = ReturnType<typeof useSiteinfosQuery>;
export type SiteinfosLazyQueryHookResult = ReturnType<typeof useSiteinfosLazyQuery>;
export type SiteinfosSuspenseQueryHookResult = ReturnType<typeof useSiteinfosSuspenseQuery>;
export type SiteinfosQueryResult = Apollo.QueryResult<SiteinfosQuery, SiteinfosQueryVariables>;
export const SubCategoriesDocument = gql`
    query SubCategories($where: SubCategoryWhereInput, $orderBy: [SubCategoryOrderByWithRelationAndSearchRelevanceInput!], $cursor: SubCategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SubCategoryScalarFieldEnum!]) {
  subCategories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    _count {
      post
    }
    id
    slug
    subCategoryName
  }
}
    `;

/**
 * __useSubCategoriesQuery__
 *
 * To run a query within a React component, call `useSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSubCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SubCategoriesQuery, SubCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCategoriesQuery, SubCategoriesQueryVariables>(SubCategoriesDocument, options);
      }
export function useSubCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCategoriesQuery, SubCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCategoriesQuery, SubCategoriesQueryVariables>(SubCategoriesDocument, options);
        }
export function useSubCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubCategoriesQuery, SubCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubCategoriesQuery, SubCategoriesQueryVariables>(SubCategoriesDocument, options);
        }
export type SubCategoriesQueryHookResult = ReturnType<typeof useSubCategoriesQuery>;
export type SubCategoriesLazyQueryHookResult = ReturnType<typeof useSubCategoriesLazyQuery>;
export type SubCategoriesSuspenseQueryHookResult = ReturnType<typeof useSubCategoriesSuspenseQuery>;
export type SubCategoriesQueryResult = Apollo.QueryResult<SubCategoriesQuery, SubCategoriesQueryVariables>;
export const SubCategoryDocument = gql`
    query SubCategory($where: SubCategoryWhereUniqueInput!) {
  subCategory(where: $where) {
    _count {
      post
    }
    id
    slug
    subCategoryName
    subCategoryPageKeyWords
    subCategoryPageTitle
    subCategoryText
    category {
      category
      slug
    }
  }
}
    `;

/**
 * __useSubCategoryQuery__
 *
 * To run a query within a React component, call `useSubCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubCategoryQuery(baseOptions: Apollo.QueryHookOptions<SubCategoryQuery, SubCategoryQueryVariables> & ({ variables: SubCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCategoryQuery, SubCategoryQueryVariables>(SubCategoryDocument, options);
      }
export function useSubCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCategoryQuery, SubCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCategoryQuery, SubCategoryQueryVariables>(SubCategoryDocument, options);
        }
export function useSubCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubCategoryQuery, SubCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubCategoryQuery, SubCategoryQueryVariables>(SubCategoryDocument, options);
        }
export type SubCategoryQueryHookResult = ReturnType<typeof useSubCategoryQuery>;
export type SubCategoryLazyQueryHookResult = ReturnType<typeof useSubCategoryLazyQuery>;
export type SubCategorySuspenseQueryHookResult = ReturnType<typeof useSubCategorySuspenseQuery>;
export type SubCategoryQueryResult = Apollo.QueryResult<SubCategoryQuery, SubCategoryQueryVariables>;
export const SubCategoryMetaDocument = gql`
    query SubCategoryMeta($where: SubCategoryWhereUniqueInput!) {
  subCategory(where: $where) {
    slug
    subCategoryPageKeyWords
    subCategoryPageTitle
    subCategoryText
  }
}
    `;

/**
 * __useSubCategoryMetaQuery__
 *
 * To run a query within a React component, call `useSubCategoryMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCategoryMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCategoryMetaQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubCategoryMetaQuery(baseOptions: Apollo.QueryHookOptions<SubCategoryMetaQuery, SubCategoryMetaQueryVariables> & ({ variables: SubCategoryMetaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCategoryMetaQuery, SubCategoryMetaQueryVariables>(SubCategoryMetaDocument, options);
      }
export function useSubCategoryMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCategoryMetaQuery, SubCategoryMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCategoryMetaQuery, SubCategoryMetaQueryVariables>(SubCategoryMetaDocument, options);
        }
export function useSubCategoryMetaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubCategoryMetaQuery, SubCategoryMetaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubCategoryMetaQuery, SubCategoryMetaQueryVariables>(SubCategoryMetaDocument, options);
        }
export type SubCategoryMetaQueryHookResult = ReturnType<typeof useSubCategoryMetaQuery>;
export type SubCategoryMetaLazyQueryHookResult = ReturnType<typeof useSubCategoryMetaLazyQuery>;
export type SubCategoryMetaSuspenseQueryHookResult = ReturnType<typeof useSubCategoryMetaSuspenseQuery>;
export type SubCategoryMetaQueryResult = Apollo.QueryResult<SubCategoryMetaQuery, SubCategoryMetaQueryVariables>;
export const SubCategoriesForSelectDocument = gql`
    query SubCategoriesForSelect($where: SubCategoryWhereInput, $orderBy: [SubCategoryOrderByWithRelationAndSearchRelevanceInput!], $cursor: SubCategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SubCategoryScalarFieldEnum!]) {
  subCategories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    subCategoryName
  }
}
    `;

/**
 * __useSubCategoriesForSelectQuery__
 *
 * To run a query within a React component, call `useSubCategoriesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCategoriesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCategoriesForSelectQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSubCategoriesForSelectQuery(baseOptions?: Apollo.QueryHookOptions<SubCategoriesForSelectQuery, SubCategoriesForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCategoriesForSelectQuery, SubCategoriesForSelectQueryVariables>(SubCategoriesForSelectDocument, options);
      }
export function useSubCategoriesForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCategoriesForSelectQuery, SubCategoriesForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCategoriesForSelectQuery, SubCategoriesForSelectQueryVariables>(SubCategoriesForSelectDocument, options);
        }
export function useSubCategoriesForSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubCategoriesForSelectQuery, SubCategoriesForSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubCategoriesForSelectQuery, SubCategoriesForSelectQueryVariables>(SubCategoriesForSelectDocument, options);
        }
export type SubCategoriesForSelectQueryHookResult = ReturnType<typeof useSubCategoriesForSelectQuery>;
export type SubCategoriesForSelectLazyQueryHookResult = ReturnType<typeof useSubCategoriesForSelectLazyQuery>;
export type SubCategoriesForSelectSuspenseQueryHookResult = ReturnType<typeof useSubCategoriesForSelectSuspenseQuery>;
export type SubCategoriesForSelectQueryResult = Apollo.QueryResult<SubCategoriesForSelectQuery, SubCategoriesForSelectQueryVariables>;
export const SubCategoriesForRssDocument = gql`
    query SubCategoriesForRss($where: SubCategoryWhereInput, $orderBy: [SubCategoryOrderByWithRelationAndSearchRelevanceInput!], $cursor: SubCategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SubCategoryScalarFieldEnum!]) {
  subCategories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    subCategoryName
    slug
    category {
      category
      slug
    }
  }
}
    `;

/**
 * __useSubCategoriesForRssQuery__
 *
 * To run a query within a React component, call `useSubCategoriesForRssQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCategoriesForRssQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCategoriesForRssQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSubCategoriesForRssQuery(baseOptions?: Apollo.QueryHookOptions<SubCategoriesForRssQuery, SubCategoriesForRssQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCategoriesForRssQuery, SubCategoriesForRssQueryVariables>(SubCategoriesForRssDocument, options);
      }
export function useSubCategoriesForRssLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCategoriesForRssQuery, SubCategoriesForRssQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCategoriesForRssQuery, SubCategoriesForRssQueryVariables>(SubCategoriesForRssDocument, options);
        }
export function useSubCategoriesForRssSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubCategoriesForRssQuery, SubCategoriesForRssQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubCategoriesForRssQuery, SubCategoriesForRssQueryVariables>(SubCategoriesForRssDocument, options);
        }
export type SubCategoriesForRssQueryHookResult = ReturnType<typeof useSubCategoriesForRssQuery>;
export type SubCategoriesForRssLazyQueryHookResult = ReturnType<typeof useSubCategoriesForRssLazyQuery>;
export type SubCategoriesForRssSuspenseQueryHookResult = ReturnType<typeof useSubCategoriesForRssSuspenseQuery>;
export type SubCategoriesForRssQueryResult = Apollo.QueryResult<SubCategoriesForRssQuery, SubCategoriesForRssQueryVariables>;
export const UpsertOneSubscriberDocument = gql`
    mutation UpsertOneSubscriber($where: SubscriberWhereUniqueInput!, $create: SubscriberCreateInput!, $update: SubscriberUpdateInput!) {
  upsertOneSubscriber(where: $where, create: $create, update: $update) {
    id
  }
}
    `;
export type UpsertOneSubscriberMutationFn = Apollo.MutationFunction<UpsertOneSubscriberMutation, UpsertOneSubscriberMutationVariables>;

/**
 * __useUpsertOneSubscriberMutation__
 *
 * To run a mutation, you first call `useUpsertOneSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOneSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOneSubscriberMutation, { data, loading, error }] = useUpsertOneSubscriberMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertOneSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOneSubscriberMutation, UpsertOneSubscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOneSubscriberMutation, UpsertOneSubscriberMutationVariables>(UpsertOneSubscriberDocument, options);
      }
export type UpsertOneSubscriberMutationHookResult = ReturnType<typeof useUpsertOneSubscriberMutation>;
export type UpsertOneSubscriberMutationResult = Apollo.MutationResult<UpsertOneSubscriberMutation>;
export type UpsertOneSubscriberMutationOptions = Apollo.BaseMutationOptions<UpsertOneSubscriberMutation, UpsertOneSubscriberMutationVariables>;
export const TagsDocument = gql`
    query Tags($where: TagWhereInput, $orderBy: [TagOrderByWithRelationAndSearchRelevanceInput!], $cursor: TagWhereUniqueInput, $take: Int, $skip: Int, $distinct: [TagScalarFieldEnum!]) {
  tags(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    _count {
      posts
    }
    name
    slug
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export function useTagsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<typeof useTagsSuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TagDocument = gql`
    query Tag($where: TagWhereUniqueInput!) {
  tag(where: $where) {
    _count {
      posts
    }
    id
    name
    slug
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables> & ({ variables: TagQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export function useTagSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagSuspenseQueryHookResult = ReturnType<typeof useTagSuspenseQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const AggregateTagDocument = gql`
    query AggregateTag($where: TagWhereInput, $orderBy: [TagOrderByWithRelationAndSearchRelevanceInput!], $cursor: TagWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateTag(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateTagQuery__
 *
 * To run a query within a React component, call `useAggregateTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateTagQuery(baseOptions?: Apollo.QueryHookOptions<AggregateTagQuery, AggregateTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateTagQuery, AggregateTagQueryVariables>(AggregateTagDocument, options);
      }
export function useAggregateTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateTagQuery, AggregateTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateTagQuery, AggregateTagQueryVariables>(AggregateTagDocument, options);
        }
export function useAggregateTagSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AggregateTagQuery, AggregateTagQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AggregateTagQuery, AggregateTagQueryVariables>(AggregateTagDocument, options);
        }
export type AggregateTagQueryHookResult = ReturnType<typeof useAggregateTagQuery>;
export type AggregateTagLazyQueryHookResult = ReturnType<typeof useAggregateTagLazyQuery>;
export type AggregateTagSuspenseQueryHookResult = ReturnType<typeof useAggregateTagSuspenseQuery>;
export type AggregateTagQueryResult = Apollo.QueryResult<AggregateTagQuery, AggregateTagQueryVariables>;
export const TagsForUrlDocument = gql`
    query TagsForUrl($where: TagWhereInput, $orderBy: [TagOrderByWithRelationAndSearchRelevanceInput!], $cursor: TagWhereUniqueInput, $take: Int, $skip: Int, $distinct: [TagScalarFieldEnum!]) {
  tags(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    slug
  }
}
    `;

/**
 * __useTagsForUrlQuery__
 *
 * To run a query within a React component, call `useTagsForUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsForUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsForUrlQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useTagsForUrlQuery(baseOptions?: Apollo.QueryHookOptions<TagsForUrlQuery, TagsForUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsForUrlQuery, TagsForUrlQueryVariables>(TagsForUrlDocument, options);
      }
export function useTagsForUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsForUrlQuery, TagsForUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsForUrlQuery, TagsForUrlQueryVariables>(TagsForUrlDocument, options);
        }
export function useTagsForUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TagsForUrlQuery, TagsForUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagsForUrlQuery, TagsForUrlQueryVariables>(TagsForUrlDocument, options);
        }
export type TagsForUrlQueryHookResult = ReturnType<typeof useTagsForUrlQuery>;
export type TagsForUrlLazyQueryHookResult = ReturnType<typeof useTagsForUrlLazyQuery>;
export type TagsForUrlSuspenseQueryHookResult = ReturnType<typeof useTagsForUrlSuspenseQuery>;
export type TagsForUrlQueryResult = Apollo.QueryResult<TagsForUrlQuery, TagsForUrlQueryVariables>;
export const TagsForSelectDocument = gql`
    query TagsForSelect($where: TagWhereInput, $orderBy: [TagOrderByWithRelationAndSearchRelevanceInput!], $cursor: TagWhereUniqueInput, $take: Int, $skip: Int, $distinct: [TagScalarFieldEnum!]) {
  tags(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
  }
}
    `;

/**
 * __useTagsForSelectQuery__
 *
 * To run a query within a React component, call `useTagsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsForSelectQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useTagsForSelectQuery(baseOptions?: Apollo.QueryHookOptions<TagsForSelectQuery, TagsForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsForSelectQuery, TagsForSelectQueryVariables>(TagsForSelectDocument, options);
      }
export function useTagsForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsForSelectQuery, TagsForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsForSelectQuery, TagsForSelectQueryVariables>(TagsForSelectDocument, options);
        }
export function useTagsForSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TagsForSelectQuery, TagsForSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagsForSelectQuery, TagsForSelectQueryVariables>(TagsForSelectDocument, options);
        }
export type TagsForSelectQueryHookResult = ReturnType<typeof useTagsForSelectQuery>;
export type TagsForSelectLazyQueryHookResult = ReturnType<typeof useTagsForSelectLazyQuery>;
export type TagsForSelectSuspenseQueryHookResult = ReturnType<typeof useTagsForSelectSuspenseQuery>;
export type TagsForSelectQueryResult = Apollo.QueryResult<TagsForSelectQuery, TagsForSelectQueryVariables>;
export const CreateOneTagDocument = gql`
    mutation CreateOneTag($data: TagCreateInput!) {
  createOneTag(data: $data) {
    id
    name
  }
}
    `;
export type CreateOneTagMutationFn = Apollo.MutationFunction<CreateOneTagMutation, CreateOneTagMutationVariables>;

/**
 * __useCreateOneTagMutation__
 *
 * To run a mutation, you first call `useCreateOneTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTagMutation, { data, loading, error }] = useCreateOneTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneTagMutation, CreateOneTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneTagMutation, CreateOneTagMutationVariables>(CreateOneTagDocument, options);
      }
export type CreateOneTagMutationHookResult = ReturnType<typeof useCreateOneTagMutation>;
export type CreateOneTagMutationResult = Apollo.MutationResult<CreateOneTagMutation>;
export type CreateOneTagMutationOptions = Apollo.BaseMutationOptions<CreateOneTagMutation, CreateOneTagMutationVariables>;
export const UsersDocument = gql`
    query Users($where: UserWhereInput, $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!], $cursor: UserWhereUniqueInput, $take: Int, $skip: Int, $distinct: [UserScalarFieldEnum!]) {
  users(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    name
    professionalName
    role
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($where: UserWhereUniqueInput!) {
  user(where: $where) {
    bio
    avatar
    socilaLinks {
      facebook
      linkedIn
      twitter
    }
    name
    professionalName
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($input: CreateOneUserArgsCustom!) {
  register(input: $input) {
    message
    success
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    role
    id
    name
    email
    status
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    mutation Login($password: String!, $email: String!) {
  login(password: $password, email: $email) {
    success
    message
    isAuthenticated
    accessToken
    expiresIn
    refreshToken
    user {
      role
      id
      name
      email
      status
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($security: customTypeForUserUpdate!, $input: profileUpdateInput!) {
  updateProfile(security: $security, input: $input) {
    message
    success
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      security: // value for 'security'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($token: String!) {
  verifyEmail(token: $token) {
    message
    success
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ForgetPasswordDocument = gql`
    mutation ForgetPassword($email: String!) {
  forgetPassword(email: $email) {
    message
    success
  }
}
    `;
export type ForgetPasswordMutationFn = Apollo.MutationFunction<ForgetPasswordMutation, ForgetPasswordMutationVariables>;

/**
 * __useForgetPasswordMutation__
 *
 * To run a mutation, you first call `useForgetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgetPasswordMutation, { data, loading, error }] = useForgetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgetPasswordMutation, ForgetPasswordMutationVariables>(ForgetPasswordDocument, options);
      }
export type ForgetPasswordMutationHookResult = ReturnType<typeof useForgetPasswordMutation>;
export type ForgetPasswordMutationResult = Apollo.MutationResult<ForgetPasswordMutation>;
export type ForgetPasswordMutationOptions = Apollo.BaseMutationOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>;
export const PasswordResetTokenVerifyDocument = gql`
    mutation PasswordResetTokenVerify($token: String!) {
  passwordResetTokenVerify(token: $token) {
    message
    success
  }
}
    `;
export type PasswordResetTokenVerifyMutationFn = Apollo.MutationFunction<PasswordResetTokenVerifyMutation, PasswordResetTokenVerifyMutationVariables>;

/**
 * __usePasswordResetTokenVerifyMutation__
 *
 * To run a mutation, you first call `usePasswordResetTokenVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetTokenVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetTokenVerifyMutation, { data, loading, error }] = usePasswordResetTokenVerifyMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePasswordResetTokenVerifyMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetTokenVerifyMutation, PasswordResetTokenVerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetTokenVerifyMutation, PasswordResetTokenVerifyMutationVariables>(PasswordResetTokenVerifyDocument, options);
      }
export type PasswordResetTokenVerifyMutationHookResult = ReturnType<typeof usePasswordResetTokenVerifyMutation>;
export type PasswordResetTokenVerifyMutationResult = Apollo.MutationResult<PasswordResetTokenVerifyMutation>;
export type PasswordResetTokenVerifyMutationOptions = Apollo.BaseMutationOptions<PasswordResetTokenVerifyMutation, PasswordResetTokenVerifyMutationVariables>;
export const ResetPassByVerficationLinkDocument = gql`
    mutation ResetPassByVerficationLink($input: ReesetPassByLinkInput!) {
  resetPassByVerficationLink(input: $input) {
    success
    message
  }
}
    `;
export type ResetPassByVerficationLinkMutationFn = Apollo.MutationFunction<ResetPassByVerficationLinkMutation, ResetPassByVerficationLinkMutationVariables>;

/**
 * __useResetPassByVerficationLinkMutation__
 *
 * To run a mutation, you first call `useResetPassByVerficationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPassByVerficationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPassByVerficationLinkMutation, { data, loading, error }] = useResetPassByVerficationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPassByVerficationLinkMutation(baseOptions?: Apollo.MutationHookOptions<ResetPassByVerficationLinkMutation, ResetPassByVerficationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPassByVerficationLinkMutation, ResetPassByVerficationLinkMutationVariables>(ResetPassByVerficationLinkDocument, options);
      }
export type ResetPassByVerficationLinkMutationHookResult = ReturnType<typeof useResetPassByVerficationLinkMutation>;
export type ResetPassByVerficationLinkMutationResult = Apollo.MutationResult<ResetPassByVerficationLinkMutation>;
export type ResetPassByVerficationLinkMutationOptions = Apollo.BaseMutationOptions<ResetPassByVerficationLinkMutation, ResetPassByVerficationLinkMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  refreshToken {
    expiresIn
    message
    success
    accessToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    message
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UserDataForProfileUpdateDocument = gql`
    query UserDataForProfileUpdate($where: UserWhereUniqueInput!) {
  user(where: $where) {
    bio
    avatar
    email
    name
    professionalName
    socilaLinks {
      facebook
      linkedIn
      twitter
    }
    username
    address
  }
}
    `;

/**
 * __useUserDataForProfileUpdateQuery__
 *
 * To run a query within a React component, call `useUserDataForProfileUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataForProfileUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataForProfileUpdateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserDataForProfileUpdateQuery(baseOptions: Apollo.QueryHookOptions<UserDataForProfileUpdateQuery, UserDataForProfileUpdateQueryVariables> & ({ variables: UserDataForProfileUpdateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDataForProfileUpdateQuery, UserDataForProfileUpdateQueryVariables>(UserDataForProfileUpdateDocument, options);
      }
export function useUserDataForProfileUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDataForProfileUpdateQuery, UserDataForProfileUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDataForProfileUpdateQuery, UserDataForProfileUpdateQueryVariables>(UserDataForProfileUpdateDocument, options);
        }
export function useUserDataForProfileUpdateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserDataForProfileUpdateQuery, UserDataForProfileUpdateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserDataForProfileUpdateQuery, UserDataForProfileUpdateQueryVariables>(UserDataForProfileUpdateDocument, options);
        }
export type UserDataForProfileUpdateQueryHookResult = ReturnType<typeof useUserDataForProfileUpdateQuery>;
export type UserDataForProfileUpdateLazyQueryHookResult = ReturnType<typeof useUserDataForProfileUpdateLazyQuery>;
export type UserDataForProfileUpdateSuspenseQueryHookResult = ReturnType<typeof useUserDataForProfileUpdateSuspenseQuery>;
export type UserDataForProfileUpdateQueryResult = Apollo.QueryResult<UserDataForProfileUpdateQuery, UserDataForProfileUpdateQueryVariables>;
export const ContactUsDocument = gql`
    mutation ContactUs($input: ContactUsInput!) {
  contactUs(input: $input) {
    message
    success
  }
}
    `;
export type ContactUsMutationFn = Apollo.MutationFunction<ContactUsMutation, ContactUsMutationVariables>;

/**
 * __useContactUsMutation__
 *
 * To run a mutation, you first call `useContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsMutation, { data, loading, error }] = useContactUsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactUsMutation(baseOptions?: Apollo.MutationHookOptions<ContactUsMutation, ContactUsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(ContactUsDocument, options);
      }
export type ContactUsMutationHookResult = ReturnType<typeof useContactUsMutation>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export type ContactUsMutationOptions = Apollo.BaseMutationOptions<ContactUsMutation, ContactUsMutationVariables>;